import { API } from "aws-amplify";

export const MigrateVotes = async (user) => {
    console.log('migrate Votes data: ')
    console.log(user)
    try {
      const tempUserId = localStorage.getItem("tempUserId");
      const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      console.log('id being used to migrate: '+localStorage.getItem("tempUserId"))
      console.log('migrating user old / new:')
      console.log(tempUserId, userId)
      const response = await API.post("prntApi", "/migrateVotes?internalUserId=${tempUserId}&newUserId=${userId}", {
        headers: { Authorization: `Bearer ${authToken}` },
        body: { "internalUserId": tempUserId,
        newUserId: userId
       }
      });
      // Parse the response structure
      console.log("response from migration")
      console.log(response)
      console.log('deleting tempUserId')
      localStorage.removeItem("tempUserId");
      return response;
    } catch (error) {
        console.error(error);
        return null;
    }
};