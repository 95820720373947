import React, { useState, useCallback, useEffect } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import Carousel from 'react-grid-carousel';
import SlideCarouselCardForCarousel from "components/Cards/CustomCards/SlideCarouselCardForCarousel";
import ProductButtons from "components/Rating/product_rating_buttons";
import ClipLoader from "react-spinners/ClipLoader";

export default function ShopSlideCarousel(props) {
  const { user, signOut } = useAuthenticator((conp) => [conp.user]);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minMatchPercentage, setMinMatchPercentage] = useState(0);
  // const [randCategory, setRandCategory] = useState(null);

  const [likedImages, setLikedImages] = useState(null);

  const getEcommerceLikedImages = async () => {
    setLoading(true);
    try {
      const authToken = user
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      
      const getLikeMemberData = await API.get(
        "prntApi",
        `/getproductreco?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      ).then((response) =>
        response.map((item) => ({
          ...item,
          confidence: 100,
        }))
      );

      const groupedImages = getLikeMemberData.reduce((acc, image) => {
        (acc[image.category] = acc[image.category] || []).push(image);
        return acc;
      }, {});

      if (Object.keys(groupedImages).length === 0) {
        setLikedImages(null);
      } else {
        const updatedGroupedImages = removeDuplicates(props.usersProductList, groupedImages);
        // console.log(updatedGroupedImages)
        setLikedImages(updatedGroupedImages);
      }
      setLoading(false);

    } catch (error) {
      console.error(error);
    }
  };

  const removeDuplicates = (usersProducts, groupedImages) => {
    // Create a Set of filenames from usersProducts for efficient lookup
    const usersProductFilenames = new Set(usersProducts.map(item => item.filename));
  
    // Iterate over each category in groupedImages
    Object.keys(groupedImages).forEach(category => {
      // Filter out items that exist in usersProductFilenames
      groupedImages[category] = groupedImages[category].filter(
        item => !usersProductFilenames.has(item.filename)
      );
    });
  
    // Return the modified groupedImages
    return groupedImages;
  }


  useEffect(() => {
    if (user) {
      getEcommerceLikedImages();
    } 
  }, [user]);

  const handleNavClick = (navName) => {
    window.gtag('event', 'navbar_click', {
      'event_category': 'engagement',
      'event_label': navName
    });
  };
  
  return (
    <section className="carousel">
      <div className="container mx-auto items-center flex flex-wrap">
        <div className="w-full md:w-12/12 lg:w-12/12 xl:w-12/12">
        {loading ? (
          <ClipLoader color="#000000" className="loading-centered" size={50} />
        ) : likedImages && likedImages[props.category] ? (
          (() => {
            const images = likedImages[props.category];
            const filteredImages = images.filter(
              (image) => Math.round(image.confidence) >= minMatchPercentage
            );
            if (filteredImages.length === 0) return null;
            return (
              <div key="category-painting">
                <h3 className="lower-title-text">Discover products that elevate your style, curated by users like you</h3>
                <div className="mx-auto relative min-w-0 break-words bg-white w-full mb-6 mt-5 text-left">
                  <Carousel cols={4} rows={1} gap={10} loop>
                    {filteredImages.map((image, imageIndex) => (
                      <Carousel.Item key={`image-${imageIndex}`}>
                        <SlideCarouselCardForCarousel 
                          img={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
                          href={user ? image.url : "/shopping"}
                          category={props.category}
                          blurb={
                            <div className="shopping-actions">
                                <ProductButtons
                                  productId={image.filename}
                                  imageUrl={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
                                  category={image.category}
                                  user={user}
                                  url={image.url}
                                  refreshImages={getEcommerceLikedImages}
                                  hideWishList={true}
                                />
                            </div>
                          }
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            );
          })()
        ) : (
          <p></p>
        )}

        </div>
      </div>
    </section>
  );
}
   
