import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "Utils/ProtectedRoutes";
import { formFields } from "Utils/formFields";
import { createRoot } from "react-dom/client";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/notus.css";
import "assets/styles/overrides.css";
import "assets/styles/mobile.css";

// layouts

import InteriorPage from "./layouts/InteriorPage";

// views without layouts
import Index from "views/Index.js";
import TermsPage from "views/Terms";
import PrivacyPage from "views/Privacy";
import AffiliateDisclaimerPage from "views/Affiliate";
// import ShopifyPage from "./layouts/ShopifyPage";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Authenticator.Provider>
		<BrowserRouter>
			<Switch>
				<Route path="/" exact component={Index} />
				<Route path="/about" component={InteriorPage} />
				<Route path="/member-gallery" component={InteriorPage} />
				<Route path="/contact" component={InteriorPage} />
				<Route path="/blog" component={InteriorPage} />
				<Route path="/blogpage" component={InteriorPage} />
				<Route path="/terms" exact component={TermsPage} />
				<Route path="/FAQ" exact component={InteriorPage} />
				<Route path="/technology" exact component={InteriorPage} />
				<Route path="/for-retailers" exact component={InteriorPage} />
				<Route path="/privacy" exact component={PrivacyPage} />
				<Route path="/affiliate-disclaimer" exact component={AffiliateDisclaimerPage} />
				{/* <Route path="/shopify-survey" exact component={ShopifyPage} /> */}
				<Route path="/shopify-survey" exact component={InteriorPage} />

				<Route path="/art/survey" component={InteriorPage} />
				{/* <Route path="/shopify/survey" component={InteriorPage} /> */}
				<Route path="/art/create" component={InteriorPage} />
				<Route path="/art/community" component={InteriorPage} />
				<Route path="/shopping" component={InteriorPage} />
				<Route path="/art/Portfolio" component={InteriorPage} />
				<Route path="/art/library" component={InteriorPage} />
				<Authenticator
					socialProviders={["google"]}
					variation="modal"
					loginMechanisms={["email"]}
					formFields={formFields}
				>
					{/* <ProtectedRoute path="/art/survey" component={InteriorPage} /> */}
					{/* <ProtectedRoute path="/art/create" component={InteriorPage} /> */}
					{/* <ProtectedRoute path="/art/Portfolio" component={InteriorPage} /> */}
					{/* <ProtectedRoute path="/art/community" component={InteriorPage} /> */}
					{/* <ProtectedRoute path="/art/library" component={InteriorPage} /> */}
					<ProtectedRoute path="/pinterest" component={InteriorPage} />
					{/* <ProtectedRoute path="/shopping" component={InteriorPage} /> */}
					<ProtectedRoute path="/predict" component={InteriorPage} />
					<ProtectedRoute path="/profile" exact component={InteriorPage} />
				</Authenticator>
				<Redirect from="*" to="/" />
			</Switch>
		</BrowserRouter>
	</Authenticator.Provider>
);
