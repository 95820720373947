import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import CreateArtCardVertical from "components/Cards/CustomCards/CreateArtCardVertical";
import CategoryDropdown from "components/Cards/CustomCards/CategoryDropdown";
import ClipLoader from "react-spinners/ClipLoader";
import ShopSlideCarousel from "components/SlideCarousel/ShopSlideCarousel";
import ProductButtons from "components/Rating/product_rating_buttons";
import shoppingData from "assets/data/shopping.json";
import ParentCategories from "assets/data/parentCategories.json";
import AuthModal from "components/Auth/Auth";
import ModelInferenceManager from "Utils/callInference";
import finetuneModel from "Utils/finetuneModel";
import { initAccordions } from "flowbite";
import { Tooltip } from "flowbite-react";

const capitalizeWords = (str) =>
	str
		.toLowerCase()
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");

export default function ShopArt(props) {
	const { user, signOut } = useAuthenticator((conp) => [conp.user]);
	const [showAuthModal, setShowAuthModal] = useState(false);
	const [showInstructionalVideo, setShowInstructionalVideo] = useState(false);
	const [hideCategoryList, setHideCategoryList] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [likedImages, setLikedImages] = useState(null);
	const [loading, setLoading] = useState(false);
	const [minMatchPercentage, setMinMatchPercentage] = useState(0);
	const [categories, setCategories] = useState([]);
	const [parentCategories, setParentCategories] = useState([]);
	const [loadingStates, setLoadingStates] = useState({});
	const [refreshImages, setRefreshImages] = useState(false);
	const [modelExists, setModelExists] = useState(null);
	const [categoryRefreshStatus, setCategoryRefreshStatus] = useState({});
	const [checkedCategories, setCheckedCategories] = useState([]);
	const [userNotFound, setUserNotFound] = useState(false);

	const getEcommerceLikedImages = async () => {
		setLoading(true);
		setUserNotFound(false);
		try {
			const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
			const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

			// Fetching liked images
			let likedImagesResponse = await API.get("prntApi", `/getInferencedImages?userId=${userId}`, {
				headers: { Authorization: `Bearer ${authToken}` },
			});

			const likedImagesResponseFiltered = likedImagesResponse.topLikedRecords || [];
			const categoryList = likedImagesResponse.categoryList || [];
			const newCategoryRefreshStatus = {};
			setCategories(categoryList); // Update the categories state
			setParentCategories(ParentCategories);

			likedImagesResponseFiltered.forEach((categoryData) => {
				const { category, refresh } = categoryData;
				newCategoryRefreshStatus[category] = refresh;
			});

			setCategoryRefreshStatus(newCategoryRefreshStatus);

			// Fetching wishlist images and modifying their structure
			const wishlistImagesResponse = await API.get("prntApi", `/getWishlistedProducts?userId=${userId}`, {
				headers: { Authorization: `Bearer ${authToken}` },
			}).then((response) =>
				response.map((item) => ({
					...item,
					category: "Wishlist", // Set static category for all wishlist items
					confidence: 100,
				}))
			);

			const updatedCategoryList = [...categoryList, "Wishlist"];
			const categories = Object.values(updatedCategoryList); // Convert object to array

			setCategories(categories);
			// Grouping images by category
			const groupedImages = likedImagesResponseFiltered.reduce((acc, categoryData) => {
				const { category, records } = categoryData; // Destructure to get category name and records
				// Ensure an array exists for the category, then concat the records
				acc[category] = (acc[category] || []).concat(records);
				return acc;
			}, {});

			// Adding modified wishlist images under the 'Wishlist' category
			groupedImages["Wishlist"] = wishlistImagesResponse;
			checkCheckboxesBasedOnQueryParams();
			if (Object.keys(groupedImages).length === 0) {
				setLikedImages(null);
			} else {
				setLikedImages(groupedImages);
			}
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const expandCategories = () => {
		setHideCategoryList(!hideCategoryList);
	};

	const handleImageClick = (event, image) => {
		if (!user) {
			setShowAuthModal(true);
			return;
		}
		window.gtag("event", "ecomm_image_click", {
			event_category: "engagement",
			event_label: image.filename,
			value: image.url,
		});
	};

	const checkSelectedCategories = (categoryList) => {
		categoryList.forEach((category) => {
			const sanitizedCategoryName = trimCategoryName(category);
			const checkbox = document.getElementById(sanitizedCategoryName);

			if (checkbox) {
				checkbox.checked = true;
				if (categoryList.indexOf(sanitizedCategoryName)) {
					setCheckedCategories([...categories], category);
				}
			}
		});
	};

	const checkCheckboxesBasedOnQueryParams = () => {
		if (!user) return;

		const urlParams = new URLSearchParams(window.location.search);
		const categories = urlParams.get("categories") ? urlParams.get("categories").split(",") : [];
		const parentCategories = urlParams.get("parent") ? urlParams.get("parent").split(",") : [];

		// checkSelectedCategories(categories)
		// checkSelectedCategories(parentCategories)
		categories.forEach((category) => {
			const sanitizedCategoryName = trimCategoryName(category);
			const checkbox = document.getElementById(sanitizedCategoryName);

			if (checkbox) {
				checkbox.checked = true;
				if (checkedCategories.indexOf(sanitizedCategoryName)) {
					setCheckedCategories([...categories], category);
				}
			}
		});
		parentCategories.forEach((category) => {
			const sanitizedCategoryName = trimCategoryName(category);
			const checkbox = document.getElementById(sanitizedCategoryName);

			if (checkbox) {
				checkbox.checked = true;
				if (checkedCategories.indexOf(sanitizedCategoryName)) {
					setCheckedCategories([...categories], category);
				}
			}
		});
	};

	const trimCategoryName = (category) => {
		return category
			.trim()
			.replace(/[ &/\\#,+()$~%.'":*?<>{}]/g, "")
			.toLowerCase();
	};

	const updateQueryString = (category, isChecked, parentCategory) => {
		const url = new URL(window.location);

		// Retrieve the current 'categories' from the query string, if any
		let categories = url.searchParams.get("categories") ? url.searchParams.get("categories").split(",") : [];
		let parentCategories = url.searchParams.get("parent") ? url.searchParams.get("parent").split(",") : [];

		if (isChecked) {
			if (!categories.includes(category)) {
				categories.push(category);
			}
			if (!parentCategories.includes(parentCategory)) {
				parentCategories.push(parentCategory);
			}
		} else {
			categories = categories.filter((cat) => cat !== category);
			parentCategories = parentCategories.filter((cat) => cat !== parentCategory);
		}

		url.searchParams.set("categories", categories.join(","));
		url.searchParams.set("parent", parentCategories.join(","));

		if (categories.length === 0) {
			url.searchParams.delete("categories");
		}

		if (categories.length === 0) {
			url.searchParams.delete("parent");
		}

		window.history.pushState({}, "", url);
	};

	const uncheckCategories = (checkedCategories) => {
		checkedCategories.forEach((category) => {
			const sanitizedCategoryName = trimCategoryName(category);
			const checkbox = document.getElementById(sanitizedCategoryName);

			if (checkbox) {
				checkbox.checked = false;
			}
		});
	};

	const handleParentCategorySelect = (parentCategories, isChecked, parentCategorySelected) => {
		let checkedCats = [];
		parentCategories.forEach((category) => {
			if (isChecked) {
				setCheckedCategories([...checkedCategories, trimCategoryName(category)]);
				checkedCats.push(trimCategoryName(category));
			} else {
				uncheckCategories(checkedCategories);
				setCheckedCategories([]);
				checkedCats = checkedCategories.filter((a) => {
					return a !== trimCategoryName(category);
				});
			}

			updateQueryString(trimCategoryName(category), isChecked, trimCategoryName(parentCategorySelected));
		});

		setCheckedCategories(checkedCats);
		checkCheckboxesBasedOnQueryParams();
	};

	const handleCategorySelect = (category, isChecked) => {
		if (isChecked) {
			setCheckedCategories([...checkedCategories, category]);
		} else {
			setCheckedCategories(checkedCategories.filter((a) => a !== category));
		}

		updateQueryString(trimCategoryName(category), isChecked);
	};

	const handleUnlock = async (category) => {
		setLoadingStates((prev) => ({ ...prev, [category]: true }));

		try {
			// Simulate an async action, like fetching data or calling an API
			setTimeout(() => {
				// Set the selected category for rendering ModelInferenceManager
				setSelectedCategory(category);
			}, 2000); // Simulate a 2-second operation
		} catch (error) {
			console.error(`Error during action for category: ${category}`, error);
			// Do not reset loading state here, as it will be handled by onCompleted
		}
	};

	const getWaitingMessage = () => {
		const waitStatments = [
			"Sifting through thousands of products. Your style curation is underway and should be ready in 30 seconds.",
			"Trust us, the wait [30 secs] will be worth it for a style that truly represents you.",
			"Putting the finishing touches on your style recommendations. This should take around 30 seconds.",
		];

		// Generate a random index based on the length of the 'texts' array
		const randomIndex = Math.floor(Math.random() * waitStatments.length);

		// Return a random line of text
		return waitStatments[randomIndex];
	};

	useEffect(() => {
		if (user) {
			getEcommerceLikedImages();
		} else {
			const groupedImages = shoppingData.reduce((acc, image) => {
				(acc[image.category] = acc[image.category] || []).push(image);
				return acc;
			}, {});
			// setAllCategoriesChecked()

			if (Object.keys(groupedImages).length === 0) {
				setLikedImages(null);
			} else {
				setLikedImages(groupedImages);
				setCategories(Object.keys(groupedImages));
			}
			setUserNotFound(true);
		}
	}, [user]);

	const setAllCategoriesChecked = () => {
		// for non logged in user state
		const groupedImages = shoppingData.reduce((acc, image) => {
			(acc[image.category] = acc[image.category] || []).push(image);
			return acc;
		}, {});

		let categories = [];
		Object.keys(groupedImages).forEach((key) => {
			console.log(trimCategoryName(key));
			categories.push(trimCategoryName(key));
		});
	};

	useEffect(() => {
		if (refreshImages) {
			getEcommerceLikedImages().then(() => {
				setRefreshImages(false); // Reset the trigger
			});
		}
	}, [refreshImages]);

	useEffect(() => {
		const checkModelCreation = async () => {
			if (!user) return; // Exit if no user is logged in

			const authToken = user.getSignInUserSession().getIdToken().getJwtToken();
			const userId = user.getSignInUserSession().getIdToken().payload.sub;

			try {
				const response = await API.post("prntApi", "/checkModelCreation", {
					headers: { Authorization: `Bearer ${authToken}` },
					body: { userId: userId },
				});
				// Directly check the message for "Model found"
				if (response.message.includes("Model found")) {
					setModelExists(true);
					getEcommerceLikedImages(); // Fetch images if model exists
				} else {
					setModelExists(false); // No model found
				}
			} catch (error) {
				console.error("Error checking model creation", error);
				setModelExists(false); // Assume error means no model, adjust as necessary
			}
		};

		checkModelCreation();
	}, [user]);

	useEffect(() => {
		const seenVideo = localStorage.getItem(
			"dismissedInstructionalModal_" + user?.getSignInUserSession()?.getIdToken()?.payload.sub
		);
		// if(true || props.percentComplete >= 50 && !seenVideo) {
		if (props.percentComplete >= 50 && !seenVideo) {
			setShowInstructionalVideo(true);
		}
	}, [props.percentComplete]);

	const dismissInstructionalModal = () => {
		setShowInstructionalVideo(false);
		localStorage.setItem(
			"dismissedInstructionalModal_" + user?.getSignInUserSession()?.getIdToken()?.payload.sub,
			true
		);
	};

	setTimeout(() => {
		// Fix for known bug: https://github.com/themesberg/flowbite/issues/91
		initAccordions();
	}, 1500);

	const allCategories = likedImages ? Object.keys(likedImages) : [];

	const cardStyleWithImages = {
		minHeight: "600px", // Keep the larger size when there are images
		// Other styling properties as needed
	};

	const cardStyleWithoutImages = {
		minHeight: "100px", // Reduce the size when there are no images
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		// Other styling properties as needed
	};

	return (
		<section className="header relative items-center flex text-center">
			<div className="container mx-auto items-center flex flex-wrap justify-center">
				<h2 className="text-5xl text-black">Discover Products That Truly Resonate</h2>
				<p className="text-sm">
					{!user && (
						<span>
							Take our image quiz and bring your unique style into focus. When finished every product
							recommendation is tailored to your taste.
							<br />
						</span>
					)}
					<br />
					Select the categories below. Your style twin and Evoke AI will find products you connect with
				</p>
				{modelExists && (
					<>
						<p className="text-sm mt-2">
							We may earn commission from qualifying purchases from our affiliate partners including
							Amazon
						</p>
						<p className="text-sm">
							*Refine your style journey: Remember to
							<button
								className="text-sm text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-sm text-sm px-3 py-1 text-center ml-1 mr-1 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
								type="button"
							>
								<i className="far fa-thumbs-down"></i>
							</button>
							<button
								className="text-sm text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-sm text-sm px-3 py-1 text-center mr-1 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
								type="button"
							>
								<i className="far fa-thumbs-up"></i>
							</button>
							your recommendations as each vote sharpens the precision of your style twin.
							<br />
							Use
							<button
								className="text-sm text-red-700 hover:text-red focus:ring-4 focus:outline-none focus:ring-red-300 rounded-sm text-sm px-3 py-1 text-center ml-1 mr-1"
								type="button"
							>
								<i className="far fa-heart"></i>
							</button>
							to add to your wishlist
						</p>
					</>
				)}
				<div className="shop-tut-video flex flex-wrap justify-center w-full">
					<br />
					<h2 className="text-black mt-0 w-full">
						<a href="https://www.youtube.com/watch?v=mtKWSCcxGlg" className="brand-text" target="_blank">
							Shopping tutorial video
						</a>
					</h2>
				</div>
				{showAuthModal && <AuthModal onClick={() => setShowAuthModal(false)} />}
				{modelExists === false ? (
					<>
						<h3 className="text-xl m-5">Rate more images to unlock customized recommendations!</h3>
						<img alt="rate more" className="text-black m-2" src={require("assets/img/noShopping.png")} />
					</>
				) : (
					<div className={"flex flex-row py-4 shopping-mobile-layout w-full"}>
						{/* Hide category selection if user not logged in */}
						{!userNotFound && (
							<div className="w-2/12">
								<div className="mt-5 mb-5">
									<div className="w-full px-4 bg-white border border-gray-200 rounded shadow dark:bg-gray-800 dark:border-gray-700 p-4">
										<div id="accordion-collapse" data-accordion="collapse">
											<h2 id="accordion-collapse-heading-1">
												<button
													type="button"
													className="filter-accordion flex justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 rounded-sm text-left"
													data-accordion-target="#accordion-collapse-body-1"
													aria-expanded="true"
													aria-controls="accordion-collapse-body-1"
													onClick={expandCategories}
												>
													<span>Categories</span>
													<svg
														data-accordion-icon
														className="w-3 h-3 rotate-180 shrink-0"
														aria-hidden="true"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 10 6"
													>
														<path
															stroke="currentColor"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M9 5 5 1 1 5"
														/>
													</svg>
												</button>
											</h2>
											<div
												id="accordion-collapse-body-1"
												className={hideCategoryList ? "hidden" : ""}
												aria-labelledby="accordion-collapse-heading-1"
											>
												<div className="p-5 accordion-open-box">
													<div className="border-gray-200 dark:border-gray-700">
														<ul className="py-1 space-y-3 text-sm text-gray-700 dark:text-gray-200 text-left">
															<li key={"title"} className="underline">
																Filter By:
															</li>
															{parentCategories.map((parents, index) => (
																<div key={index}>
																	<ul className="py-1 space-y-3 text-sm text-gray-700 dark:text-gray-200 text-left">
																		<li key={index} className="parent-categories">
																			<input
																				id={trimCategoryName(parents.parent)}
																				type="checkbox"
																				className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
																				onChange={(event) =>
																					handleParentCategorySelect(
																						parents.children,
																						event.target.checked,
																						parents.parent
																					)
																				}
																			/>
																			<label
																				htmlFor={trimCategoryName(
																					parents.parent
																				)}
																				className="parent-categories-label ms-2 text-xl font-medium text-gray-900 dark:text-gray-300"
																			>
																				{parents.parent}
																			</label>
																		</li>
																	</ul>
																	<ul className="py-3 space-y-3 text-sm text-gray-700 dark:text-gray-200 text-left">
																		{categories.map((category, index) => {
																			if (parents.children.indexOf(category) >= 0)
																				return (
																					<li
																						key={index}
																						className="sub-categories"
																					>
																						<div className="flex items-center">
																							<input
																								id={trimCategoryName(
																									category
																								)}
																								type="checkbox"
																								className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
																								onChange={(event) =>
																									handleCategorySelect(
																										trimCategoryName(
																											category
																										),
																										event.target
																											.checked
																									)
																								}
																							/>
																							<label
																								htmlFor={trimCategoryName(
																									category
																								)}
																								className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
																							>
																								{category}
																							</label>
																						</div>
																					</li>
																				);
																		})}
																	</ul>
																</div>
															))}
														</ul>
														{/* {categories.map((category, index) => (
                                  <li key={index}>
                                    <div className="flex items-center">
                                      <input
                                        id={trimCategoryName(category)}
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                        onChange={(event) => handleCategorySelect(trimCategoryName(category), event.target.checked)}
                                      />
                                      <label
                                        htmlFor={trimCategoryName(category)}
                                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        {category}
                                      </label>
                                    </div>
                                  </li>
                                ))} */}
													</div>
												</div>
											</div>
										</div>
										{/* <CategoryDropdown allCategories={allCategories} handleCategorySelect={handleCategorySelect}/> */}
										<div className="mt-5 mb-5">
											<label className="block text-gray-700 text-sm font-bold mb-2 text-left">
												Min Match %:{" "}
												<span style={{ float: "right" }}>{minMatchPercentage}%</span>
											</label>
											<input
												type="range"
												min="50"
												max="100"
												value={minMatchPercentage}
												onChange={(e) => setMinMatchPercentage(e.target.value)}
												className="slider bg-black"
												style={{ width: "200px" }}
											/>
										</div>
									</div>
								</div>
							</div>
						)}

						{/* Right Column (10/12 of the width) */}
						<div className="products-view w-9/12 flex flex-wrap shopping-items-column mb-6 min-height-600">
							{checkedCategories.length === 0 && user && (
								<div className="w-full">
									<div className="flex-grow flex-basis-0 mx-auto min-height-600 relative min-w-0 break-words bg-white w-full mb-6 mt-5 text-left ">
										<h3 className="no-cat-selected text-black text-3xl text-center">
											Select a category
										</h3>
									</div>
								</div>
							)}

							{loading ? (
								<ClipLoader color="#000000" className="loading-centered" size={50} />
							) : (
								categories.map((category) => {
									if (checkedCategories.indexOf(trimCategoryName(category)) < 0 && !userNotFound) {
										return null;
									}
									const images = likedImages && likedImages[category] ? likedImages[category] : [];
									const filteredImages = images.filter(
										(image) => Math.round(image.confidence) >= minMatchPercentage
									);
									const shouldDisplayRefresh = categoryRefreshStatus[category];
									return (
										// <div className={(category !== "Painting" && !secondFilterSelected) ? 'hidden' : 'w-full'} key={category}>
										<div className="w-full" key={category}>
											<div
												className="flex-grow flex-basis-0 mx-auto min-height-600 relative min-w-0 break-words bg-white w-full mb-6 mt-5 text-left "
												style={
													filteredImages.length > 0
														? cardStyleWithImages
														: cardStyleWithoutImages
												}
											>
												<h3 className="text-black text-3xl text-center">
													{capitalizeWords(category)}
												</h3>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
														justifyContent: "center",
														height: "100%",
														minWidth: "50vw",
													}}
												>
													{loadingStates[category] && (
														<>
															<ClipLoader color="#000000" size={50} />
															<p className="ml-3">{getWaitingMessage()}</p>
														</>
													)}
													{!loadingStates[category] && categoryRefreshStatus[category] && (
														<Tooltip content="See your updated style picks! Click 'Update' to refresh your recommendations">
															<button
																className="text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 brand-background"
																onClick={() => handleUnlock(category)}
															>
																Update
																<i className="far fa-question-circle in-button-icon"></i>
															</button>
														</Tooltip>
													)}
												</div>
												{filteredImages.length > 0 ? (
													filteredImages.map((image, imageIndex) => (
														<div
															key={`image-${imageIndex}`}
															onClick={(e) => handleImageClick(e, image)}
															className="shop-item-box"
														>
															<CreateArtCardVertical
																img={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
																// href={user ? image.url : "#"}
																href={image.url}
																howManyImages={filteredImages.length}
																category={category}
																blurb={
																	<div className="shopping-actions">
																		<div className="flex flex-1 flex-item mb-1">{`Match: ${Math.round(
																			image.confidence
																		)}%`}</div>
																		{image.price !== undefined &&
																			image.price !== "" && (
																				<div className="flex flex-1 flex-item mb-1 price">{`Price: ${image.price}`}</div>
																			)}
																		<ProductButtons
																			productId={image.filename}
																			imageUrl={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
																			category={image.category}
																			user={user}
																			url={image.url}
																			refreshImages={getEcommerceLikedImages}
																		/>
																	</div>
																}
															/>
														</div>
													))
												) : (
													<div className="text-center p-4">
														{!loadingStates[category] && (
															<button
																className="text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 brand-background"
																onClick={() => handleUnlock(category)}
															>
																Unlock
															</button>
														)}
													</div>
												)}
											</div>

											{/* Include the ShopSlideCarousel for each category */}
											<div className="shop-carousel">
												<ShopSlideCarousel
													category={category}
													usersProductList={filteredImages}
												/>
											</div>
										</div>
									);
								})
							)}

							{/* Conditional for ModelInferenceManager */}
							{selectedCategory && (
								<ModelInferenceManager
									category={selectedCategory}
									onCompleted={() => {
										setLoadingStates((prev) => ({ ...prev, [selectedCategory]: false }));
										setSelectedCategory(null);
										setRefreshImages(true); // Trigger image refresh
									}}
								/>
							)}
						</div>
					</div>
				)}
			</div>
			{showInstructionalVideo && (
				<>
					<div className="instructional-modal-container justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full">
							{/*content*/}
							<div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 rounded-t text-center">
									<h3 className="mobile-hidden w-full text-3xl text-black text-center">
										A quick guide on how to use your style twin for shopping
									</h3>
									<h3 className="mobile-shown w-full text-sm text-black text-center">
										A quick guide on how to use your style twin for shopping
									</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => {
											dismissInstructionalModal(1);
										}}
									>
										<span className="close-x bg-transparent text-grey opacity-5 h-6 w-6 text-3xl block outline-none focus:outline-none">
											×
										</span>
									</button>
								</div>
								<div className="instructional-vid-container relative flex-auto flex text-center justify-center">
									<iframe
										className="mobile-hidden"
										width="90%"
										height="500px"
										src="https://www.youtube.com/embed/mtKWSCcxGlg?si=1ivtGJNm54TaWlTc"
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
									></iframe>
									<iframe
										className="mobile-shown"
										width="95%"
										height="300px"
										src="https://www.youtube.com/embed/mtKWSCcxGlg?si=1ivtGJNm54TaWlTc"
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
									></iframe>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-overlay opacity-50 fixed inset-0 z-40 bg-black"></div>
				</>
			)}
		</section>
	);
}
