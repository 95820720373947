import React from 'react';

const ProductCard = ({ imageUrl, price, filename, confidence }) => (
  <div className="shopify-results-card flex flex-col bg-white shadow-md overflow-hidden text-black">
	<a href="https://quickstart-40681728.myshopify.com/products/blue-thunder" target="_blank" >
    	<img src={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${filename}.jpg`} alt="Product" className="card-image w-full object-contain h-full" />
	</a>
    <div className="p-2">
      <p className="text-black">
		Price: {price}
		<br />
	  	Match: {confidence.toFixed(2)}%
		</p>
    </div>
  </div>
);

const ProductCardGrid = ({ records }) => {
  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-wrap -mx-4">
        {records.map((record, index) => (
          <div key={index} className="sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4">
            <ProductCard imageUrl={record.imageUrl} price={record.price} filename={record.filename} confidence={record.confidence}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCardGrid;