import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getBadgeInfo } from "../../../Utils/getBadgeInfo";
import get_gravatar from "../../../Utils/getGravatar";

export default function CardProfilePrnt(props) {
	const { user } = useAuthenticator((context) => [context.user]);
	console.log("user");
	console.log(user);
	const gravatar = get_gravatar(user?.getSignInUserSession()?.getIdToken()?.payload.email, 250);

	console.log("gravatar");
	console.log(gravatar);
	return (
		<>
			<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16 pb-4">
				<div className="px-6">
					<div className="flex flex-wrap justify-center">
						<div className="w-full px-4 flex justify-center">
							<div className="relative">
								<img
									alt="..."
									src={gravatar}
									className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 max-w-150-px"
								/>
							</div>
						</div>
						<div className="w-full px-4 text-center mt-20">
							<div className="flex justify-center py-4 lg:pt-4 pt-8">
								<div className="mr-4 p-3 text-center">
									<span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
										{props.votes}
									</span>
									<span className="text-sm text-blueGray-400">Images Rated</span>
								</div>
								{/* <div className="mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    55?
                  </span>
                  <span className="text-sm text-blueGray-400">Images Liked</span>
                </div>
                <div className="lg:mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    89?
                  </span>
                  <span className="text-sm text-blueGray-400">Images Disliked</span>
                </div> */}
							</div>
						</div>
					</div>
					<div className="text-center mt-12">
						{getBadgeInfo(props.votes).image && (
							<>
								<h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
									{/* {user && user.signInUserSession.idToken.payload.given_name} */}
									Current Badge
								</h3>
								<div className="badge-card m-auto">
									<p className="badge-title text-2xl bold">{getBadgeInfo(props.votes).name}</p>
									<img src={getBadgeInfo(props.votes).image} alt={getBadgeInfo(props.votes).name} />
								</div>
							</>
						)}
					</div>
					{/* <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                  An artist of considerable range, Jenna the name taken by
                  Melbourne-raised, Brooklyn-based Nick Murphy writes, performs
                  and records all of his own music, giving it a warm, intimate
                  feel with a solid groove structure. An artist of considerable
                  range.
                </p>
                <a
                  href="#pablo"
                  className="font-normal text-lightBlue-500"
                  onClick={(e) => e.preventDefault()}
                >
                  Show more
                </a>
              </div>
            </div>
          </div> */}
				</div>
			</div>
		</>
	);
}
