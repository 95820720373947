import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ClipLoader from "react-spinners/ClipLoader";
import { v4 as uuidv4 } from "uuid";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import DisplayUploadedImages from "../components/Dropdowns/PredictDropdown";
import AuthModal from "components/Auth/Auth";

export default function ImageUploadAndPredict() {
  const { user } = useAuthenticator((comp) => [comp.user]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [projects, setProjects] = useState([]);

  const [showAuthModal, setShowAuthModal] = useState(false);
  
  const [allImageData, setAllImageData] = useState([]);
  const [finalDecision, setFinalDecision] = useState("");

  const [projectId, setProjectId] = useState("");
  const [projectFolder, setProjectFolder] = useState(null);

  const uploadImages = async () => {

    if (!user) {
      setShowAuthModal(true);
      return;
    }

    setLoading(true);
    const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
    const newProjectFolder = uuidv4();
    setProjectFolder(newProjectFolder);

    const client = new S3Client({
      credentials: {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      },
      region: process.env.REACT_APP_REGION,
    });

    try {
      for (let image of images) {
        const uniqueId = uuidv4();
        const command = new PutObjectCommand({
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: `Ecomm/custom_inference/${userId}/${newProjectFolder}/${uniqueId}.jpg`,
          Body: image,
        });
        await client.send(command);
      }
      setLoading(false);
      setUploaded(true);
      console.log("Images uploaded successfully, setting uploaded to true");
    } catch (error) {
      console.error("Error uploading images:", error);
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setImages(files);
  };
  
  const invokeInferenceLambda = async () => {
    try {
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      const response = await API.post("prntApi", "/inferenceimage", {
        headers: { Authorization: `Bearer ${authToken}` },
        body: {
          custom: true,
          userId,
          projectId: projectFolder,
        },
      });
      console.log(response);
      setImages([]);
      setUploaded(false);
      await listUserProjects();
    } catch (error) {
      console.error("Error invoking Lambda:", error);
    }
  };

  const listUserProjects = async () => {
    try {
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      const response = await API.get(
        "prntApi",
        `/getUserProjects?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      console.log(response);
      setProjects(response);
    } catch (error) {
      console.error("Error invoking Lambda:", error);
    }
  };
  useEffect(() => {
    if (!user) return;

    listUserProjects();
  }, [user]);



  const fetchData = async () => {
    const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
    const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
  
    try {
      const response = await API.get(
        "prntApi",
        `/getCustomInferencedImages?userId=${userId}&projectId=${projectId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
  
      console.log(response);
  
      setAllImageData(response);
  
      const filteredData = response.filter(image => image.confidence >= 70);
  
      let totalScore = 0;
      let totalConfidence = 0;
  
      filteredData.forEach(image => {
        totalScore += image.vote === 1 ? image.confidence : -image.confidence;
        totalConfidence += image.confidence;
      });
  
      let normalizedScore = 0;
      let averageConfidence = 0;
  
      if (filteredData.length !== 0) {
        normalizedScore = totalScore / (filteredData.length * 100);
        averageConfidence = totalConfidence / filteredData.length;
      }
  
      let decision = "Neutral";
  
      if (normalizedScore > 0.5) decision = "Strong Like";
      else if (normalizedScore > 0.25) decision = "Like";
      else if (normalizedScore > 0.1) decision = "Somewhat Like";
      else if (normalizedScore < -0.5) decision = "Strong Dislike";
      else if (normalizedScore < -0.25) decision = "Dislike";
      else if (normalizedScore < -0.1) decision = "Somewhat Dislike";
  
      const confidenceStatement = averageConfidence === 0 ? "Low Confidence" : `${averageConfidence.toFixed(2)}% confident`;
  
      setFinalDecision(`${decision} (${confidenceStatement})`);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }
    , 60000);
    return () => clearInterval(interval);
  }, [projectId])

  return (
    <>
      {showAuthModal && (
        <AuthModal onClick={() => setShowAuthModal(false)} />
      )}
    
    <div className="p-8">
      <h2 className="text-blueGray-700 text-4xl font-bold mt-0 mb-4">
        Image Prediction Tool
      </h2>

      <div className="mb-5 bg-blueGray-100 p-4 rounded-md shadow-sm">
        <p>
          Welcome to the Image Prediction Tool! Upload your images to get
          insights about their perceived appeal. The tool will analyze the
          images and provide a prediction of whether they're "Liked" or
          "Disliked", based on a deep learning model. The final decision can be
          "Strong Like", "Like", "Neutral", "Dislike", or "Strong Dislike" with
          a confidence percentage.
        </p>
        <p className="mt-2">
          Simply select your images, upload, and get predictions in no time!
        </p>
      </div>

      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
      />

      {loading ? (
        <ClipLoader color="#000000" size={50} />
      ) : (
        <>
          {images.length > 0 && !loading && !uploaded && (
            <button
              className="px-4 py-2 mx-2 my-1 bg-black text-white mt-4"
              onClick={uploadImages}
            >
              Upload Images
            </button>
          )}
          {!loading && uploaded && (
            <button
              className="px-4 py-2 mx-2 my-1 bg-black text-white mt-4"
              onClick={invokeInferenceLambda}
            >
              Predict
            </button>
          )}
        </>
      )}

      {/* Create a dropdown to select the project folder */}
      <div className="relative inline-flex">
        <svg
          className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 412 232"
        >
          <path
            d="M412 0L206 232 0 0z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </svg>
        <select
          className="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
          onChange={(e) => setProjectId(e.target.value)}
        >
          
          <option disabled value="">Select a project</option>
          {projects && projects.map((project) => (
            <option key={project.projectId} value={project.projectId}>
              {project.projectId}
            </option>
          ))}
        </select>
      </div>

      {/* Pass the updated image data and final decision*/}
      {projectId && <DisplayUploadedImages finalDecision={finalDecision} allImageData={allImageData} />}
    </div>
    </>
  );
}
