import React from "react";
import FAQ from "components/FAQ/FAQ";

export default function FAQPage() {
  return (
    <>
      <div className="items-center flex flex-wrap justify-center">
        <div>
          <h2 className="text-5xl text-black mt-0 text-center">
            Frequently Asked Questions
          </h2>
          <p className="text-center">The intersection of technology, creativity and personalization</p>
        </div>
      </div>
      <div className="container mx-auto items-center flex flex-wrap justify-center py-10">
        <div className="w-full md:w-12/12 lg:w-12/12 xl:w-12/12">
          <FAQ full={true}/>
        </div>
      </div>
  </>
  );
}
