import { useEffect } from "react";
import { Hub } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { formFields } from "Utils/formFields";
import { useHistory } from "react-router-dom";

const AuthModal = ({ onClick }) => {
	const history = useHistory(); // Initialize the useNavigate hook
	const currentPath = window.location.pathname;

	useEffect(() => {
		const listener = (data) => {
			const { payload } = data;
			if (payload.event === "signIn") {
				if (currentPath !== "/shopify-survey") {
					history.push("/art/survey");
				}
			}
			if (payload.event === "signOut") {
				console.log("logging out");
				history.push("/"); // Replace with your desired route
			}
		};

		Hub.listen("auth", listener);
		return () => Hub.listen("auth", listener); // Cleanup listener
	}, [history]);

	return (
		<Authenticator
			components={{
				Header: () => (
					<div className="flex justify-between items-center p-4 bg-gray-200">
						<div style={{ cursor: "pointer" }} onClick={onClick}></div>
						<div>
							<button onClick={onClick}>Close</button>
						</div>
					</div>
				),
			}}
			initialState={{
				loginRedirectURL: currentPath,
			}}
			socialProviders={["google"]}
			variation="modal"
			loginMechanisms={["email"]}
			formFields={formFields}
		/>
	);
};

export default AuthModal;
