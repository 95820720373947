import React from 'react';
import { Dropdown } from 'flowbite-react';


export default function ShareButtonsPopout(props) {
  if (!props.user) return null;
  const encodedURL = encodeURIComponent(props.url);
  const encodedImageURL = encodeURIComponent(props.imageUrl);
  const description =
    "Found a product that resonates with my unique taste using my personal aesthetic model on Evoke AI! Your style, digitally defined.";

  return (
    <div className="shop-share-btn rounded-sm">
      <Dropdown className="text-black" label={<i className="fas fa-share-alt text-black"></i>} dismissOnClick={true}>
        <Dropdown.Item className="rounded">
          <a
            href={`https://twitter.com/intent/tweet?text=${description} ${encodedURL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter fa-lg" style={{ color: "#1DA1F2" }}></i>
          </a>
        </Dropdown.Item>
        <Dropdown.Item className="rounded">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f fa-lg" style={{ color: "#1877F2" }}></i>
          </a>
        </Dropdown.Item>
        <Dropdown.Item className="rounded">
          <a
            href={`https://www.instagram.com/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram fa-lg" style={{ color: "#E4405F" }}></i>
          </a>
        </Dropdown.Item>
        <Dropdown.Item className="rounded">
          <a
            href={`https://pinterest.com/pin/create/button/?url=${encodedURL}&media=${encodedImageURL}&description=${description}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-pinterest fa-lg" style={{ color: "#BD081C" }}></i>
          </a>
        </Dropdown.Item>
      </Dropdown>
    </div>    
  );
}
   
