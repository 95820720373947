import React from "react";

// components

export default function SlideCarouselCardForCarousel(props) {
  const fixHeight = {
    height: (props.category === "Women's Loafers" || props.category === "Men's Loafers") ? 'auto' : '' // You can change '100px' to whatever you like
  };


  return (
    <>
      <div className="slide-carousel-card">
        <div className="image-container-vertical-reccos">
          <a href={props.href} target="_blank">
            <img src={props.img} alt="Card Image" className={"card-image w-full"} style={fixHeight} />
          </a>
        </div>
        <div className="m-2">
          <h3 className="card-title text-xl font-bold">{props.title}</h3>
          <h3 className="card-subtitle text-l">{props.subtitle}</h3>
          <div className="card-blurb font-bold">{props.blurb}</div>
        </div>
      </div>
    </>
  );
}
