import React, { useState, useEffect } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { API } from "aws-amplify";
import ClipLoader from "react-spinners/ClipLoader";
import CreateArtCardVertical from "components/Cards/CustomCards/CreateArtCardVertical";
import ProductButtons from "components/Rating/product_rating_buttons";
// import MemberStyleFronts from "components/StyleFronts/MemberStyleFronts";

export default function MembersGallery(props) {
  const { user, signOut } = useAuthenticator((conp) => [conp.user]);
  const [loading, setLoading] = useState(false);
  const [likedImages, setLikedImages] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [minMatchPercentage, setMinMatchPercentage] = useState(0);

  const getEcommerceLikedImages = async () => {
    setLoading(true);
    try {
      const authToken = user
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      
      // Fetching wishlist images and modifying their structure
      const getLikeMemberData = await API.get(
        "prntApi",
        `/getproductreco?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      ).then((response) =>
        response.map((item) => ({
          ...item,
          confidence: 100,
        }))
      );
        console.log("getLikeMemberData")
        console.log(getLikeMemberData)
      const groupedImages = getLikeMemberData.reduce((acc, image) => {
        (acc[image.category] = acc[image.category] || []).push(image);
        return acc;
      }, {});

      // Adding modified wishlist images under the 'Wishlist' category
      // groupedImages["Wishlist"] = wishlistImagesResponse;

      if (Object.keys(groupedImages).length === 0) {
        setLikedImages(null);
      } else {
        setLikedImages(groupedImages);
      }
      setLoading(false);

    } catch (error) {
      console.error(error);
    }
  };

  const handleImageClick = (event, image) => {
    if (!user) {
      event.preventDefault();
      return;
    }
    window.gtag("event", "ecomm_image_click", {
      event_category: "engagement",
      event_label: image.filename,
      value: image.url,
    });
  };

  useEffect(() => {
    if (user) {
      getEcommerceLikedImages();
    } 
  }, [user]);

  return (
    <>
      <div className="spacer-30"></div>
      <div className="items-center flex flex-wrap justify-center">
        <div>
          <h2 className="text-5xl text-black mt-0 text-center">
            Similar Members
          </h2>
          <p className="text-center">Products Matched To Styles</p>
        </div>
      </div>
      <div className="spacer-30"></div>
      <div className="products-view w-9/12 flex flex-wrap shopping-items-column mb-6 min-height-600">
        {loading ? (
          <ClipLoader color="#000000" className="loading-centered" size={50} />
        ) : likedImages ? (
          Object.entries(likedImages)
            .filter(
              ([category]) =>
                selectedCategories.size === 0 ||
                selectedCategories.has(category)
            )
            .map(([category, images], categoryIndex) => {
              const filteredImages = images.filter(
                (image) =>
                  Math.round(image.confidence) >= minMatchPercentage
              );
              if (filteredImages.length === 0) return null;
              return (
                <div key={`category-${categoryIndex}`}>
                  <div className="flex-grow flex-basis-0 mx-auto min-height-600 relative min-w-0 break-words bg-white w-full mb-6 mt-5 text-left">
                    <h3 className="text-black text-3xl text-center">
                      {category}
                    </h3>
                    {filteredImages.map((image, imageIndex) => (
                      <span
                        key={`image-${imageIndex}`}
                        onClick={(e) => handleImageClick(e, image)}
                        className="shop-item-box"
                      >
                        <CreateArtCardVertical
                          img={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
                          href={user ? image.url : "#"}
                          howManyImages={filteredImages.length}
                          category={category}
                          blurb={
                            <div className="shopping-actions">
                              <ProductButtons
                                productId={image.filename}
                                imageUrl={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
                                category={image.category}
                                user={user}
                                url={image.url}
                                refreshImages={getEcommerceLikedImages}
                              />
                            </div>
                          }
                        />
                      </span>
                    ))}
                  </div>
                </div>
              );
            })
        ) : (
          <p>No image found</p>
        )}
      </div>



      {/* <MemberStyleFronts /> */}
      
    </>
  );
}
