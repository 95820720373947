import React, { useState } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import sendContactForm from '../Utils/sendContactForm';

export default function ContactUs() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const sendEmail = async () => {
    const input = {name: name, email: email, company: company, message: message}
    const result = await sendContactForm(user, input);
    if (result) {
      setFormSuccess(true);
      console.log("contact form sent successfully.");
    } else {
      // Handle failure (e.g., show error message)
      console.error("Failed to send contact form.");
    }
  };

  return (
    <>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 px-4 py-2">
            <img
              alt="stock lightbulb image"
              src={require("assets/img/contactPage1.png")}
              className="mx-auto rounded-sm"
            />
          </div>
          <div className="flex-1 px-4 py-2">
            <h2 className="text-3xl text-black mt-0 mb-0 font-bold ml-2">
              Contact Us
            </h2>
            {!formSuccess ? (
              <>
                <p className="text-sm m-1 ml-3">We would love to hear from you. </p>
                <div className="spacer-30"></div>
                <form
                  className="max-w-lg mx-auto"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                      Name
                    </label>
                    <input
                      className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="name"
                      type="text"
                      placeholder="Your Name"
                      value={name}
                      onChange={handleInputChange(setName)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      placeholder="Your Email"
                      value={email}
                      onChange={handleInputChange(setEmail)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                      Company
                    </label>
                    <input
                      className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="company"
                      type="text"
                      placeholder="Your Company Name"
                      value={company}
                      onChange={handleInputChange(setCompany)}
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="message"
                      placeholder="Your message"
                      value={message}
                      onChange={handleInputChange(setMessage)}
                  ></textarea>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-sm focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={sendEmail}
                  >
                    Send
                  </button>
                </div>
                <div className="spacer-100"></div>
            </form>
          </>
          ): (
            <div className="flex-1 py-2">
              <h2 className="text-xl mt-0 mb-0 font-bold ml-2"> 
                Thank you! Message received, we will respond shortly
              </h2>
              <div className="spacer-100"></div>
              <div className="spacer-100"></div>
              <div className="spacer-100"></div>
              <div className="spacer-100"></div>
              <div className="spacer-100"></div>
              <div className="spacer-100"></div>
              <div className="spacer-100"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
