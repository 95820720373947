import { API } from 'aws-amplify';


const initiateInference = async (userId, authToken, category) => {
  try {
    const inferenceResponse = await API.post("prntApi", "/inference", {
      headers: { Authorization: `Bearer ${authToken}` },
      body: { userId: userId, category: category },
    });
    // Optionally return the response for further processing
    return inferenceResponse;
  } catch (error) {
    console.error("Error initiating inference:", error);
    // Optionally return an indication of failure or the error itself
    return null;
  }
};

export default initiateInference;