import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

// import Sidebar from "../components/Sidebar/Sidebar.js";
import { fetchVoteCount } from "../Utils/fetchVoteCount.js";
import { fetchVoteData } from "../Utils/fetchVoteData.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

import About from "../views/About.js";
import ContactUs from "../views/Contact.js";
import Blog from "../views/Blog.js";
import BlogPage from "../views/BlogPage.js";
import MemberGallery from "../views/MemberGallery.js";
import Technology from "../views/technology.js";
import ForRetailers from "../views/forRetailers.js";
import FAQPage from "../views/FAQ.js";
import ArtSurvey from "../views/ArtSurvey.js";
import ShopifySurvey from "../views/ShopifySurvey.js";
import CommunityArt from "../views/CommunityArt.js";
import Portfolio from "../views/Portfolio.js";
import ShopArt from "../views/ShopArt.js";
import Library from "../views/Library.js";
import Settings from "../views/admin/Settings.js";
import Predict from "../views/Predict.js";
import PinterestAuth from "views/Pinterest.js";
// import ShopifyPage from "./ShopifyPage.js";

export default function InteriorPage() {
	const [votes, setVotes] = useState(0);
	const [voteData, setVoteData] = useState({ total: 0, likes: 0, dislikes: 0 });
	const [percentComplete, setPercentComplete] = useState(0);
	const { user } = useAuthenticator((context) => [context.user]);
	const [showAuthModal, setShowAuthModal] = useState(false);

	const fetchVotes = async () => {
		try {
			const fetchedVoteCount = await fetchVoteCount(user);
			if (fetchedVoteCount) {
				setVotes(fetchedVoteCount);
			}
		} catch (error) {
			console.log(`ERROR: ${error}`);
		}
	};

	const showAuthModalFromFooter = () => {
		console.log("show auth called");
		setShowAuthModal(true);
	};

	const resetVotes = () => {
		setVotes(0);
	};

	useEffect(() => {
		if (!user) return;
		// New criteria for calculating percentage
		const halfwayVotes = 60;
		const halfwayLikes = 25;
		const halfwayDislikes = 25;
		const fullVotes = 100;
		const fullLikes = 30;
		const fullDislikes = 30;

		// Initialize the percent complete
		let newPercentComplete = 0;

		// Check if halfway criteria are met
		if (voteData.total >= halfwayVotes && voteData.likes >= halfwayLikes && voteData.dislikes >= halfwayDislikes) {
			// Calculate what percentage of the way from 50% to 100% the user is
			const additionalVotes = voteData.total - halfwayVotes;
			const additionalLikes = voteData.likes - halfwayLikes;
			const additionalDislikes = voteData.dislikes - halfwayDislikes;

			// Calculate how much additional percentage is achieved beyond 50%
			const additionalVotesPercentage = Math.min(1, additionalVotes / (fullVotes - halfwayVotes));
			const additionalLikesPercentage = Math.min(1, additionalLikes / (fullLikes - halfwayLikes));
			const additionalDislikesPercentage = Math.min(1, additionalDislikes / (fullDislikes - halfwayDislikes));

			// Average out additional percentages to get the overall completion beyond 50%
			const additionalComplete = Math.min(
				additionalVotesPercentage,
				additionalLikesPercentage,
				additionalDislikesPercentage
			);

			// Assign new percent complete (50% + additional completion percentage)
			newPercentComplete = 50 + additionalComplete * 50;
		} else {
			// If halfway criteria are not met, calculate the percentage towards 50%
			const votePercentageToHalf = Math.min(1, voteData.total / halfwayVotes);
			const likePercentageToHalf = Math.min(1, voteData.likes / halfwayLikes);
			const dislikePercentageToHalf = Math.min(1, voteData.dislikes / halfwayDislikes);

			// Use the minimum percentage towards halfway mark to represent progress towards 50%
			newPercentComplete = Math.min(votePercentageToHalf, likePercentageToHalf, dislikePercentageToHalf) * 50;
		}

		// Set the new percentage complete
		setPercentComplete(newPercentComplete);
		console.log("Percent complete:", newPercentComplete);
	}, [voteData, user]);

	useEffect(() => {
		if (!user) return;
		async function fetchData() {
			// You can await here
			try {
				const fetchedVoteData = await fetchVoteData(user);
				if (fetchedVoteData) {
					setVoteData(fetchedVoteData);
				}
			} catch (error) {
				console.log(`ERROR: ${error}`);
			}
		}
		fetchData();
	}, [fetchVoteData, user]);

	useEffect(() => {
		if (!user) return;
		fetchVotes();
	}, [fetchVotes, user]);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	// Now you can use urlParams to get specific query parameters
	const shopParam = urlParams.get("shop");

	return (
		<>
			{shopParam === "shopify-surf" ? (
				<Switch>
					<Route
						path="/shopify-survey"
						exact
						render={() => (
							<ShopifySurvey
								percentComplete={percentComplete}
								user={user}
								showAuthModalFromFooter={showAuthModalFromFooter}
							/>
						)}
					/>
				</Switch>
			) : (
				<>
					<IndexNavbar fixed />
					<div className="inner-container container mx-auto items-center flex flex-wrap justify-center pt-10">
						<div className="w-full sm:w-12/12 md:w-10/12 lg:w-8/12 xl:w-10/12 px-4">
							<Switch>
								<Route path="/about" exact render={() => <About />} />
								<Route
									path="/art/survey"
									exact
									render={() => <ArtSurvey percentComplete={percentComplete} />}
								/>

								<Route
									path="/art/community"
									exact
									render={() => <CommunityArt votes={votes} percentComplete={percentComplete} />}
								/>
								<Route
									path="/art/portfolio"
									exact
									render={() => (
										<Portfolio
											votes={votes}
											percentComplete={percentComplete}
											resetVotes={resetVotes}
										/>
									)}
								/>
								<Route
									path="/art/library"
									exact
									render={() => <Library votes={votes} percentComplete={percentComplete} />}
								/>
								<Route
									path="/shopping"
									exact
									render={() => <ShopArt votes={votes} percentComplete={percentComplete} />}
								/>
								<Route
									path="/profile"
									exact
									render={() => <Settings votes={votes} percentComplete={percentComplete} />}
								/>
								<Route path="/faq" exact render={() => <FAQPage />} />
								<Route path="/contact" exact render={() => <ContactUs />} />
								<Route path="/blog" exact render={() => <Blog />} />
								<Route path="/blogpage" exact render={() => <BlogPage />} />
								<Route
									path="/member-gallery"
									exact
									render={() => <MemberGallery votes={votes} percentComplete={percentComplete} />}
								/>
								<Route path="/technology" exact render={() => <Technology />} />
								<Route path="/for-retailers" exact render={() => <ForRetailers />} />
								<Route path="/predict" exact render={() => <Predict />} />
								<Route path="/pinterest" exact render={() => <PinterestAuth />} />
							</Switch>
						</div>
					</div>
					<Footer showAuthModal={showAuthModalFromFooter} />
				</>
			)}
		</>
	);
}
