import React from "react";

export default function Blog() {

  const blog = [];
  blog["20240227"] = {
    title: "The Psychology of Aesthetic Traits: Understanding the Impact on Perception and Behavior",
    description: "Dive into the realm of psychology and explore how aesthetic traits shape our perception and behavior, uncovering the fascinating connection between visual preferences and human psychology.",
    blogMainImage: "assets/img/blog/20240227-cover.jpeg",
    socialMediaPostText: "Dive into the captivating world of psychology! 🌟 Explore the impact of aesthetic traits on perception and behavior in our latest blog. 💭 Discover the connection between visual preferences and human psychology. 🎨✨ #PsychologyOfAestheticTraits #PerceptionAndBehavior #VisualPreferences #ExploreWithUs #BlogPost"
  }
  blog["20240229"] = {
    title: "Why Your Aesthetic Matters in True Personalization of Product Recommendation Systems",
    description: "In today's digital era, product recommendation systems have become an integral part of the online shopping experience. These systems use various algorithms to analyze user behavior, preferences, and purchase history to suggest the most relevant products to customers. However, many companies overlook the importance of aesthetics in these recommendation systems. In this blog post, we will explore why your aesthetic matters in achieving true personalization in product recommendations",
    blogMainImage: "assets/img/blog/20240229-cover.jpeg",
    socialMediaPostText: "#ProductRecommendation #UserExperience #Personalization #ECommerceTips #DesignThinking #CustomerEngagement #AestheticDesign #BrandTrust #EmotionalConnection #DecisionFatigue #digitaltwin #digitalstyletwin #myaesthetic"
  }

  blog["20240411"] = {
    title: "The Role of AI in Personalized Fashion Recommendations",
    description: "Discover how AI revolutionizes personalized fashion recommendations based on shopping habits, trends, and preferences.Discover how AI is revolutionizing personalized fashion recommendations! Dive into our latest blog discussing the use of machine learning in understanding shopping habits to tailor suggestions just for you. Stay ahead of the fashion game with cutting-edge technology.",
    blogMainImage: "assets/img/blog/20240411-cover.jpeg",
    socialMediaPostText: "Discover how AI is revolutionizing personalized fashion recommendations! Dive into our latest blog discussing the use of machine learning in understanding shopping habits to tailor suggestions just for you. Stay ahead of the fashion game with cutting-edge technology. Click the link to learn more. #AI #PersonalizedFashion #Recommendations #MachineLearning #ShoppingHabits #FashionTech"
  }

  return (
    <div className="min-height-600">
      <div className="spacer-30"></div>
      <h2 className="text-xl text-black">Blog / Evoke AI</h2>
      
      {/* Start of blog post */}
      <a href="/blogpage?date=20240227">
        <div className="bg-gray-100 p-4">
          <div className="flex gap-4">
            {/* Image Container */}
            <div className="w-1/2">
              <img
                alt="blog 1 ocean image"
                className="blog-cover-img rounded-sm"
                src={require('assets/img/blog/20240227-cover.jpeg')}
              />
            </div>
            {/* Text Container */}
            <div className="w-1/2 flex flex-col ">
              <h2 className="text-xl font-bold mt-0 text-black">{blog["20240227"].title}</h2>
              <p>
                {blog["20240227"].description}
              </p>
            </div>

          </div>
        </div>
      </a>
      <div className="spacer-30"></div>
      {/* End of blog post */}

      {/* Start of blog post */}
      <a href="/blogpage?date=20240229">
        <div className="bg-gray-100 p-4">
          <div className="flex gap-4">
            {/* Image Container */}
            <div className="w-1/2">
              <img
                alt="blog 1 ocean image"
                className="blog-cover-img rounded-sm"
                src={require('assets/img/blog/20240229-cover.jpeg')}
              />
            </div>
            {/* Text Container */}
            <div className="w-1/2 flex flex-col ">
              <h2 className="text-xl font-bold mt-0 text-black">{blog["20240229"].title}</h2>
              <p>
                {blog["20240229"].description}
              </p>
            </div>

          </div>
        </div>
      </a>
      <div className="spacer-30"></div>
      {/* End of blog post */}

      {/* Start of blog post */}
      <a href="/blogpage?date=20240411">
        <div className="bg-gray-100 p-4">
          <div className="flex gap-4">
            {/* Image Container */}
            <div className="w-1/2">
              <img
                alt="blog 1 ocean image"
                className="blog-cover-img rounded-sm"
                src={require('assets/img/blog/20240411-cover.jpeg')}
              />
            </div>
            {/* Text Container */}
            <div className="w-1/2 flex flex-col ">
              <h2 className="text-xl font-bold mt-0 text-black">{blog["20240411"].title}</h2>
              <p>
                {blog["20240411"].description}
              </p>
            </div>

          </div>
        </div>
      </a>
      <div className="spacer-30"></div>
      {/* End of blog post */}

    </div>
  );
}
