import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import ClipLoader from "react-spinners/ClipLoader";

const DisplayUploadedImages = ({ finalDecision, allImageData }) => {
  const { user } = useAuthenticator((comp) => [comp.user]);

  return (
    <div className="p-8">
      <h2 className="text-blueGray-700 text-4xl font-bold mt-0 mb-4">
        Projects
      </h2>
      <>
        <h3 className="text-blueGray-600 text-2xl font-bold mt-0 mb-4">
          Final Decision: {finalDecision}
        </h3>

        <div className="grid grid-cols-3 gap-4">
          {allImageData.map((image, i) => (
            <div key={i} className="mb-4 p-2">
              <div className="bg-gray-100 overflow-hidden mb-2">
                <img
                  src={image.imageUrl}
                  alt="Uploaded"
                  className="w-full h-auto mx-auto object-contain"
                  style={{ maxWidth: "256px", maxHeight: "256px" }}
                />
              </div>
              <p className="text-center">
                {image.vote === 1 ? "Liked" : "Disliked"}
              </p>
              <p className="text-center">
                Confidence: {image.confidence.toFixed(2)}%
              </p>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default DisplayUploadedImages;
