import React, { useState } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function TermsPage() {
  const [showAuthModal, setShowAuthModal] = useState(false);

  const showAuthModalFromFooter = () => {
    setShowAuthModal(true);
  }

  return (
    <>
    <IndexNavbar fixed showAuthModalFromFooter={showAuthModal} />
    <section className="terms-page relative items-center flex text-center">
        <div className="container mx-auto items-center flex flex-wrap justify-center">
          <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4">
            <h2 className="text-5xl text-black m-4">
              Evoke AI Terms of Use
            </h2>
            <div className="mx-auto relative flex flex-col min-w-0 break-words w-full mb-6 mt-10 text-left">
              <p>PLEASE READ THE FOLLOWING TERMS OF USE AGREEMENT CAREFULLY. BY ACCESSING OR USING EVOKE-AI.COM (THE “SITE”) OR USING ANY IMAGES GENERATED AT EVOKE-AI.COM (THE “IMAGES”, AND TOGETHER WITH THE SITE, THE “SERVICE”) YOU HEREBY AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITE OR OUR SERVICES.</p>
              <p>These terms and conditions (this “Agreement” or “Terms”) is a legal agreement between you and Evoke AI, Inc (hereinafter “Evoke” or “we”, “us” or “our”, as applicable), a company duly organized and validly existing under the laws of the State of Delaware, for use of the Site.</p>
              <p>Overview</p>
              <p>Evoke offers this Site, including all information, tools and services available from this Site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
              <p>By visiting our Site or using our Images you engage in our “Service” and agree to be bound by the following terms and conditions, including those additional terms and conditions and policies referenced herein and/or available by hyperlink. If you do not agree to all the Terms of this Agreement, then you may not access the Site or use any Service.</p>
              <p>We reserve the right to update, change or replace any part of these Terms by posting updates and/or changes to our Site. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Site following the posting of any changes constitutes acceptance of those changes.</p>
              <p>General Terms</p>
              <p>Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-sublicensable, non-transferrable, non-assignable, revocable license to use the Service. A breach or violation of any of the Terms will result in an immediate termination of your right to use our Service.</p>
              <p>By using the Site, you represent that you are at least the age of majority in your jurisdiction of residence. You may not use our products or Site for any illegal or unauthorized purpose nor may you, in the use of our products or Site, violate any laws in your jurisdiction. We have the right, but not the obligation, to take any of the following actions in our sole discretion at any time and for any reason without giving you any prior notice:</p>
              <ol className="list-decimal">
              <li>Restrict, suspend or terminate your access to all or any part of our Site;</li>
              <li>Change, suspend or discontinue all or any part of our products or Site;</li>
              <li>Refuse, move, or remove any content that is available on all or any part of our Site;</li>
              <li>Deactivate or delete your account;</li>
              <li>Establish general practices and limits concerning use of our Site; and</li>
              <li>Rescind or modify the terms of your ability to pursue Commercial Use of the Site.</li>
              </ol>
              <p>You agree that we will not be liable to you or any third party for taking any of these actions. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Site, use of the Site, or access to the Site or any contact on the Site, without express written permission by us. You may not transmit any worms or viruses or any code of a destructive nature. You also agree not to access or attempt to access our Services through any means other than through the interface (whether website or API) which is provided by Evoke for use in accessing our Services. You further agree not to interfere with or disrupt any Evoke Services, servers and/or networks that may be connected or related to our Site, including, but not limited to, by the use of any device software and/or routine to bypass any robot exclusion headers. You agree not to alter or change the Site in any manner, nature or form, and as such, not to use any modified versions of the Site, including and without limitation, for the purpose of obtaining unauthorized access to our Services.</p>

              <p>Except as otherwise provided herein, you may not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit the Site or any of the content, in whole or in part, found on the Site. Use of the Site does not entitle users to make any unauthorized use of any Images that may infringe on the intellectual property rights of Evoke or other parties (“Protected Content”). You will use Protected Content solely for your personal use and will make no other use of Protected Content, including any Commercial Use or public display of Protected Content.</p>

              <p>You hereby grant to Evoke, its successors, and assigns, a perpetual, worldwide, non-exclusive, sublicensable, no-charge, royalty-free, irrevocable copyright license to reproduce, prepare derivative works of, publicly display, publicly perform, sublicense, or distribute any prompts (in any form) you enter into the Site and any Images produced by the Services at your direction.</p>

              <p>This license survives termination of this Agreement by any party for any reason.</p>

              <p>Conduct</p>

              <p>You agree not to make use of the Site, our models, or derivatives of our models:</p>

              <ol className="list-decimal">
              <li>To infringe upon the intellectual property rights of any third party;</li>
              <li>In any way that violates any applicable law or regulation;</li>
              <li>To create content that exploits or abuses children, including, but not limited to, images or depictions of child abuse, sexual abuse, or presenting children in a sexual manner.</li>
              <li>To generate or disseminate verifiably false information with the purpose of harming others;</li>
              <li>To impersonate or attempt to impersonate others;</li>
              <li>To generate or disseminate personally identifying or identifiable information;</li>
              <li>To defame, libel, disparage, bully, threaten, stalk, or otherwise harass others;</li>
              <li>To create content that promotes self-harm;</li>
              <li>To create content that implies or promotes support or funding of, or membership in, a terrorist organization.</li>
              <li>To create content that condones or promotes violence against people based on race, ethnicity, color, national origin, religion, age, gender, sexual orientation, disability, medical condition, veteran status, or any other protected legal category.</li>
              </ol>

              <p>Commercial Use</p>

              <p>”Commercial Use” means any use case intended to generate direct or indirect financial gain. You may use the Site in connection with any Commercial Use provided that, if you are not a Subscriber, you must credit Evoke in text accompanying any image(s) you use commercially. In the sole event that it is not possible for text to accompany such image(s), the placement of our logo in the corner constitutes sufficient attribution.</p>

              <p>Any Commercial Use of the Site inconsistent with our Code of Conduct or the rest of these Terms is prohibited.</p>

              <p>Subscriptions</p>

              <p>Only one individual may use the Site per account, and Subscriptions may not be shared between or among different individuals or accounts. If you purchase a Subscription, we will make reasonable efforts to provide the benefits included with your tier of Subscription. Nonetheless, we do not guarantee or make any assurances as to the speed at which Images will generate or the timing or extent of access to any features in the future. We reserve the right to modify the terms, including pricing, of any Subscription at any time.</p>

              <p>Indemnity</p>

              <p>You agree to release, defend, indemnify and hold harmless Evoke, our subsidiaries, affiliates, agents, employees, officers, partners and/or licensors for any claims, damages, costs, liabilities and expenses of every kind and nature (including, but not limited to, reasonable attorneys' fees) arising out of or related to your use of Evoke Services or your conduct in connection with these Services, including your violations of the Terms and/or your violation of any such rights of another person, your use or inability to use Site, your breach or alleged breach of these Terms or of any representation or warranty contained herein, or your use of Images in such a way that violates any rights of another party.</p>

              <p>You further acknowledge that the Images are generated using a model that was trained algorithmically using public or proprietary data sets. We do not accept any responsibility for, or guarantee the accuracy, integrity, quality, or content of, any Images. You acknowledge, understand, and agree that you are solely responsible for your use of the Images. Whether the use of any Image infringes on another party's intellectual property rights depends on both the nature of the specific Image and your particular use of the Image. If you have doubts about whether the use of a particular Image may infringe on another party's intellectual property rights, you should seek legal advice.</p>

              

              <p>Modifications of Service</p>

              <p>Evoke reserves the right at any time it may deem fit, to modify, alter and or discontinue, whether temporarily or permanently, our service, or any part thereof, with or without prior notice. In addition, we shall not be held liable to you or to any third party for any such alteration, modification, suspension and/or discontinuance of our Services, or any part thereof.</p>

              <p>Termination</p>

              <p>Evoke may, without any prior written notice, immediately suspend, terminate, discontinue and/or limit your access to any of our Services or your license to use our Services. The cause for such termination, discontinuance, suspension and/or limitation of access may include, but is not limited to:</p>

              <ol className="list-decimal">
              <li>any breach or violation of our Terms or any other incorporated agreement, regulation and/or guideline;</li>
              <li>at the request of law enforcement or any other governmental agencies;</li>
              <li>the discontinuance, alteration and/or material modification to our Services, or any part thereof;</li>
              <li>unexpected technical or security issues and/or problems;</li>
              <li>any engagement by you in any fraudulent or illegal activities; and/or</li>
              <li>the nonpayment of any associated fees that may be owed by you in connection you're your use of the Services.</li>
              </ol>

              <p>Furthermore, you herein agree that any and all terminations, suspensions, discontinuances, and or limitations of access for cause shall be made at our sole discretion and that we shall not be liable to you or any other third party with regards to the termination of your access to any of our Services.</p>

              <p>Links</p>

              <p>Either Evoke or any third parties may provide links to other websites and/or resources. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and as such, we do not endorse nor are we responsible or liable for any content, products, advertising or any other materials, on or available from such third-party sites or resources.</p>

              <p>Furthermore, you acknowledge and agree that Evoke shall not be responsible or liable, directly or indirectly, for any such damage or loss which may be a result of, caused or allegedly to be caused by or in connection with the use of or the reliance on any such content, goods or Services made available on or through any such site or resource.</p>

              <p>Warranty Disclaimers</p>

              <p>YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:</p>

              <ol className="list-decimal">
              <li>THE USE OF EVOKE SERVICES AND SOFTWARE ARE AT THE SOLE RISK BY YOU. OUR SERVICES AND SOFTWARE SHALL BE PROVIDED ON AN “AS IS” AND/OR “AS AVAILABLE” BASIS. EVOKE AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON- INFRINGEMENT.</li>
              <li>EVOKE AND OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO SUCH WARRANTIES THAT (i) EVOKE SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii) EVOKE SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THAT SUCH RESULTS WHICH MAY BE OBTAINED FROM THE USE OF EVOKE SERVICES OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) QUALITY OF ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER MATERIAL WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v) THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE CORRECTED.</li>
              <li>ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY OF EVOKE SERVICES OR SOFTWARE SHALL BE ACCESSED BY YOUR SOLE DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR DISPLAYING, OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF ANY SUCH INFORMATION OR MATERIAL.</li>
              <li>NO ADVICE AND/OR INFORMATION, DESPITE WHETHER WRITTEN OR ORAL, THAT MAY BE OBTAINED BY YOU FROM EVOKE OR BY WAY OF OR FROM OUR SERVICES OR SOFTWARE SHALL CREATE ANY WARRANTY.</li>
              </ol>

              <p>Limitation of Liability</p>

              <p>YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT EVOKE AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA AND/OR OTHER INTANGIBLE LOSSES, EVEN THOUGH WE MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES MAY OCCUR, AND RESULT FROM:</p>

              <ol className="list-decimal">
              <li>THE USE OR INABILITY TO USE OUR SERVICE;</li>
              <li>THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;</li>
              <li>UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR TRANSMISSIONS AND/OR DATA;</li>
              <li>STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR SERVICE;</li>
              <li>AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.</li>
              </ol>

              <p>Notice</p>

              <p>Evoke may furnish you with notices, including those with regards to any changes to the Terms, including but not limited to email, regular mail, MMS or SMS, text messaging, postings on our website Services, or other reasonable means currently known or any which may be herein after developed. Any such notices may not be received if you violate any aspects of the Terms by accessing our Services in an unauthorized manner. Your acceptance of this Agreement constitutes your agreement that you are deemed to have received any and all notices that would have been delivered had you accessed our Services in an authorized manner.</p>

              <p>Entire Agreement</p>

              <p>This Agreement constitutes the entire agreement between you and Evoke and shall govern the use of our Services, superseding any prior version of this Agreement between you and us with respect to Evoke Services.</p>

              <p>You may also be subject to additional terms and conditions that may apply when you use or purchase certain other Evoke Services.</p>

              <p>Disputes</p>

              <p>Any dispute, controversy or claim arising out of or relating to this contract, including the formation, interpretation, breach or termination thereof, and including whether the claims asserted are arbitrable, will be referred to and finally determined by arbitration in accordance with the JAMS Streamlined Arbitration Rules and Procedures. The Tribunal will consist of one arbitrator. The place of arbitration will be Houston, Texas, United States of America. The language to be used in the arbitral proceedings will be English. Judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. Each party shall bear its own costs in the arbitration. Evoke shall also have the right to commence and prosecute any legal or equitable action or proceeding before any court of competent jurisdiction to obtain injunctive or other relief against you in the event that, in the opinion of Evoke, such action is necessary or desirable.</p>

              <p>Except as explicitly set forth in this arbitration section, the arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of these Terms, including, but not limited to any claim that all or any part of these Terms are void or voidable, whether a claim is subject to arbitration, and any dispute regarding the payment of JAMS administrative or arbitrator fees (including the timing of such payments and remedies for nonpayment). The arbitrator must follow these terms and can award the same damages and relief as a court. No arbitration award or decision will have any preclusive effect as to issues or claims in any dispute with anyone who is not a named party to the arbitration. Finally, if Evoke's or your claim is for monetary relief of $10,000 or less and doesn't request any equitable remedy, the party bringing the claim may, based solely on the documents submitted to the arbitrator, choose whether arbitration will be conducted through a telephonic hearing or by an in-person hearing under the JAMS Rules. The Streamlined Arbitration Rules governing the arbitration may be accessed at <a href="https://www.jamsadr.com/">https://www.jamsadr.com/</a> or by calling JAMS at (800) 352-5267. The parties understand that, absent this mandatory provision, they would have the right to sue in court and have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court. ARBITRATION MEANS THAT YOU WAIVE YOUR RIGHT TO A JURY TRIAL. You may, in arbitration, seek any and all remedies otherwise available to you pursuant to law.</p>

              <p>These Terms are governed by and shall be construed in accordance with the laws of the State of Texas, without respect to its conflict of laws principles. In the event of any conflict between these Terms and any license agreement you enter with Evoke, the terms of such license agreement shall control. These Terms shall be construed neither against nor in favor of any party, but rather in accordance with the fair meaning of the language hereof.</p>

              <p>Waiver and Severability of Terms</p>

              <p>At any time, should Evoke fail to exercise or enforce any right or provision of the Agreement, such failure shall not constitute a waiver of such right or provision. If any provision of this Agreement is found to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Agreement remain in full force and effect.</p>

              <p>Foreign Access of Site</p>

              <p>The Site is controlled, operated and administered by Evoke from our offices within the United States of America. If you access the Site from a location outside the United States of America, you are responsible for compliance with all local laws. You agree that you will not use Evoke's content accessed through the Site in any country or in any manner prohibited by any applicable laws, restrictions or regulations. ADDITIONALLY, REGARDLESS OF YOUR LOCATION, YOU AGREE TO THE FORUM AND JURISDICTIONAL CLAUSES CONTAINED IN THE SECTION ENTITLED “DISPUTES”.</p>

              <p>Privacy Policy</p>

              <p>Evoke's Privacy Policy is available at <a href="www.evoke-ai.com/privacy">www.evoke-ai.com/privacy</a>. By using the Site, you consent to the collection and use of the information provided, including the transfer of information within the United States and/or other countries for storage, processing or use by Evoke and/or our subsidiaries and affiliates.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer showAuthModal={showAuthModalFromFooter} />
    </>
  );
}
