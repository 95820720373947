import { API } from "aws-amplify";

const checkModelGenerated = async (user) => {
  try {
    const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
    const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

    const response = await API.post("prntApi", "/checkModelCreation", {
      headers: { Authorization: `Bearer ${authToken}` },
      body: { userId: userId },
    });

    console.log("Check model generated response:", response);
    return response;
  } catch (error) {
    console.error("Error checking model generation status:", error);
    return false;
  }
};

export default checkModelGenerated;