import React from "react";
import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import ArtProfile from "../components/Profile/artprofile"; // path to ArtProfile component
import ArtPersonality from "../components/Profile/artpersonality"; // path to ArtPersonality component
import LibraryPagination from "Utils/libraryPagination";
import ImageOutput from "../components/Library/ImageOutput";

import portfolioImages from "assets/data/portfolio.json";
import libraryImages from "assets/data/library.json";
import WishListOnly from "components/WishListOnly/WishListOnly";

export default function Portfolio(props) {
  const { user, signOut } = useAuthenticator((conp) => [conp.user]);
  const [likedImages, setLikedImages] = useState(null);
  const [favImages, setFavImages] = useState(null);
  const [personalityType, setPersonalityType] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [hideBlurbTrue, setHideBlurbTrue] = useState(false); // Add loading state

  const getPersonalityType = (type) => {
    setPersonalityType(type);
  };

  const getFavImages = async () => {
    try {
      const authToken = user
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      const response = await API.get(
        "prntApi",
        `/getGeneratedImages?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const responseAsArray = Object.values(response);
      setFavImages(responseAsArray);
    } catch (error) {
      console.error(error);
    }
  };

  const getLikedImages = async () => {
    setLoading(true); // Start loading
    try {
      const authToken = user
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      const response = await API.get(
        "prntApi",
        `/getlikedImages?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const responseAsArray = Object.values(response.filenames);
      setLikedImages(responseAsArray);
      setLoading(false); // Stop loading
    } catch (error) {
      console.error(error);
    }
  };

  const hideArtBlurb = () => {
    setHideBlurbTrue(true);
  }

  useEffect(() => {
    if (user) {
      getFavImages(user?.getSignInUserSession()?.getIdToken()?.getJwtToken());
    } else {
      setFavImages(portfolioImages);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      getLikedImages(user?.getSignInUserSession()?.getIdToken()?.getJwtToken());
    } else {
      setLikedImages(libraryImages);
    }
  }, [user]);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentLikedPage, setCurrentLikedPage] = useState(1);
  const imagesPerPage = 9;

  // Logic to calculate current images to display
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages =
    favImages === null
      ? []
      : favImages.slice(indexOfFirstImage, indexOfLastImage);

  const indexOfLastLikedImage = currentLikedPage * imagesPerPage;
  const indexOfFirstLikedImage = indexOfLastLikedImage - imagesPerPage;
  const currentLikedImages =
    likedImages === null
      ? []
      : likedImages.slice(indexOfFirstLikedImage, indexOfLastLikedImage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleLikedImagePageChange = (pageNumber) => {
    setCurrentLikedPage(pageNumber);
  };

  return (
    <>
      <div className="items-center flex flex-wrap justify-center">
        <div>
          <h2 className="text-5xl text-black mt-0 text-center">
            Your Style Portfolio
          </h2>
          <p className="text-center">Personality Type {personalityType}</p>
        </div>
      </div>
      <div className="spacer-30"></div>

      <div
        className="flex flex-col md:flex-row justify-between"
        style={{ minHeight: "550px" }}
      >
        <div className="mx-2 text-left art-types-boxes mb-4 md:mb-0 w-1/2 w-full">
          <ArtPersonality
            votes={props.votes}
            percentComplete={props.percentComplete}
            getPersonalityType={getPersonalityType}
          />
        </div>
        <div className="mx-2 text-left art-types-boxes mb-4 w-1/2 w-full">
          <ArtProfile
            votes={props.votes}
            percentComplete={props.percentComplete}
          />
        </div>
      </div>

      <div className="w-full">
        {favImages && favImages.length === 0 && (
          <p>
            You haven't created any art yet. Rate more images to unlock Create
            Art!
          </p>
        )}
        <hr className="p-5 mt-5" />
        <div className="items-center flex flex-wrap justify-center">
          <div>
            <h2 className="text-3xl text-black mt-0 text-center">
              Bonus feature: Create AI Art that Reflects Your Style
            </h2>
            {hideBlurbTrue ? (
              <p className="text-center">
                Enter a subject / topic to create related images. or just press 
                "Create" to skip using a custom subject.
              </p>
            ) :(
              <p className="text-center">
                This art piece will be placed in the "My Favorites" section below.
            </p>
            )}
          </div>
          <div className="spacer-15 mobile-hidden"></div>
          <div className="w-full lg:w-2/12 px-4">
            <ImageOutput
              votes={props.votes}
              percentComplete={props.percentComplete}
              resetVote={props.resetVote}
              hideArtBlurb={hideArtBlurb}
            />
          </div>
        </div>

        <hr className="p-5 mt-10" />
        {favImages && favImages.length > 0 && (
          <div className="content-area mx-auto min-height-600 relative flex flex-col min-w-0 break-words  w-full mb-6 rounded-lg mt-5">
            <h2 className="text-3xl text-black mt-0 text-center">
              My Favorites
            </h2>
            <p className="text-center">Created Art Pieces</p>
            <LibraryPagination
              likedImages={favImages}
              imagesPerPage={imagesPerPage}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
            <ul className="library-image-container">
              {currentImages.map((image, index) => (
                <li key={index}>
                  {user ? (
                    <a
                      href={image.imageUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img key={index} src={image.imageUrl} alt="Art Piece" />
                    </a>
                  ) : (
                    <a href={image} target="_blank" rel="noopener noreferrer">
                      <img key={index} src={image} alt="Art Piece" />
                    </a>
                  )}
                </li>
              ))}
            </ul>
            <LibraryPagination
              likedImages={favImages}
              imagesPerPage={imagesPerPage}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
        <hr className="p-5 mt-5" />
        <section className="wishlisted-items">
          <WishListOnly />
        </section>
      </div>
    </>
  );
}
