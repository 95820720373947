import React from "react";
import { initAccordions } from "flowbite";

export default function FAQ(props) {

  setTimeout(() => {
      // Fix for known bug: https://github.com/themesberg/flowbite/issues/91
      initAccordions();
  }, 1500);

  const handleNavClick = (navName) => {
    window.gtag("event", "navbar_click", {
      event_category: "engagement",
      event_label: navName,
    });
  };

  return (
    <section className="faq-section">
      <div className="container mx-auto items-center flex flex-wrap">
        <div className="w-full md:w-10/12 lg:w-10/12 xl:w-10/12">
          <div id="accordion-collapse" data-accordion="collapse">
            <h2 id="accordion-collapse-heading-1">
              <button
                type="button"
                className="flex justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                data-accordion-target="#accordion-collapse-body-1"
                aria-expanded="true"
                aria-controls="accordion-collapse-body-1"
              >
                <span>What does Evoke AI provide?</span>
                <svg
                  data-accordion-icon
                  className="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id="accordion-collapse-body-1"
              className="hidden"
              aria-labelledby="accordion-collapse-heading-1"
            >
              <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  We are an aesthetic intelligence platform that creates your
                  customized aesthetic model - your digital style twin - that
                  reflects your individual style and tastes. Our AI-powered
                  personalization engine then curates and recommends products
                  that match your style.{" "}
                </p>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-2">
              <button
                type="button"
                className="flex justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                data-accordion-target="#accordion-collapse-body-2"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-2"
              >
                <span>How Do I Create My Digital Style Twin?</span>
                <svg
                  data-accordion-icon
                  className="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id="accordion-collapse-body-2"
              className="hidden"
              aria-labelledby="accordion-collapse-heading-2"
            >
              <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  To create your style twin, you'll need to take our proprietary
                  visual quiz. It’s easy. Simply like or dislike the images.
                  Based on your votes, we will create your style twin.
                </p>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-3">
              <button
                type="button"
                className="flex justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                data-accordion-target="#accordion-collapse-body-3"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-3"
              >
                <span>
                  How Does the Platform Enhance My Online Shopping Experience?
                </span>
                <svg
                  data-accordion-icon
                  className="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id="accordion-collapse-body-3"
              className="hidden"
              aria-labelledby="accordion-collapse-heading-3"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Our aesthetic intelligence platform revolutionizes your online
                  shopping experience by providing product recommendations that
                  are tailored to your unique aesthetic preferences. We can
                  suggest items that match your personal style, whether you're
                  shopping for fashion, home decor, or art. This means you spend
                  less time searching for products and more time discovering
                  items that truly resonate with your taste.
                </p>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-4">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                data-accordion-target="#accordion-collapse-body-4"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-4"
              >
                <span>
                  Are there any fees or charges associated with creating my
                  digital style twin?
                </span>
                <svg
                  data-accordion-icon
                  className="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id="accordion-collapse-body-4"
              className="hidden"
              aria-labelledby="accordion-collapse-heading-4"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  No, it is free of charge for users.
                </p>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-5">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm"
                data-accordion-target="#accordion-collapse-body-5"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-5"
              >
                <span>How Is My Privacy Protected?</span>
                <svg
                  data-accordion-icon
                  className="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id="accordion-collapse-body-5"
              className="hidden"
              aria-labelledby="accordion-collapse-heading-5"
            >
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Protecting your privacy is our top priority. Your responses to
                  the image test and the resulting aesthetic model are
                  confidential and securely stored. We do not share your
                  personal information or individual responses with third
                  parties without your explicit consent. We adhere to industry
                  best practices and comply with applicable data protection
                  regulations to safeguard user data. Here is our privacy policy
                  and terms of use.
                </p>
                <a className="brand-text" href="/privacy">
                  Privacy Policy
                </a>{" "}
                |{" "}
                <a className="brand-text" href="/terms">
                  Terms of use
                </a>{" "}
                |{" "}
                <a className="brand-text" href="/affiliate-disclaimer">
                  Affiliate Disclosure
                </a>
              </div>
            </div>
            {props.full && (
            <>
                <h2 id="accordion-collapse-heading-6">
                    <button
                        type="button"
                        className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm"
                        data-accordion-target="#accordion-collapse-body-6"
                        aria-expanded="false"
                        aria-controls="accordion-collapse-body-6"
                    >
                        <span>What Information Does My Style Twin Provide?</span>
                        <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-6"
                    className="hidden"
                    aria-labelledby="accordion-collapse-heading-6"
                >
                    <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Your style twin provides insights into your aesthetic preferences. It includes a visual representation that shows your tendencies towards certain aesthetic and personality dimensions. What’s more, we curate and recommend products that match your aesthetics. 
                        </p>
                    </div>
                </div>

                <h2 id="accordion-collapse-heading-7">
                    <button
                        type="button"
                        className="flex justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                        data-accordion-target="#accordion-collapse-body-7"
                        aria-expanded="false"
                        aria-controls="accordion-collapse-body-7"
                    >
                        <span>What technology does the platform use to analyze my aesthetic preferences?</span>
                        <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-7"
                    className="hidden"
                    aria-labelledby="accordion-collapse-heading-7"
                >
                    <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Our platform utilizes advanced image recognition and machine learning algorithms to analyze your responses to visual stimuli. This technology identifies patterns in your likes and dislikes, helping us create a detailed and accurate aesthetic model that reflects your unique style preferences.
                        </p>
                    </div>
                </div>

                <h2 id="accordion-collapse-heading-8">
                    <button
                        type="button"
                        className="flex justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                        data-accordion-target="#accordion-collapse-body-8"
                        aria-expanded="false"
                        aria-controls="accordion-collapse-body-8"
                    >
                        <span>How accurate is the aesthetic model in reflecting my personal style?</span>
                        <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-8"
                    className="hidden"
                    aria-labelledby="accordion-collapse-heading-8"
                >
                    <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            The accuracy of your aesthetic model largely depends on the consistency and honesty of your responses in the image test. Our algorithm is designed to capture a nuanced understanding of your style preferences, making the model quite accurate in representing your personal aesthetic.
                        </p>
                    </div>
                </div>

                <h2 id="accordion-collapse-heading-9">
                    <button
                        type="button"
                        className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm"
                        data-accordion-target="#accordion-collapse-body-9"
                        aria-expanded="false"
                        aria-controls="accordion-collapse-body-9"
                    >
                        <span>What should I do if I encounter technical issues on the platform?</span>
                        <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-9"
                    className="hidden"
                    aria-labelledby="accordion-collapse-heading-9"
                >
                    <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                        If you experience any technical difficulties, please contact our support team - <a href="mailto:support@evoke-ai.com">support@evoke-ai.com</a>. Provide a detailed description of the issue, and our team will assist you promptly.
                        </p>
                    </div>
                </div>

                <h2 id="accordion-collapse-heading-10">
                    <button
                        type="button"
                        className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                        data-accordion-target="#accordion-collapse-body-10"
                        aria-expanded="false"
                        aria-controls="accordion-collapse-body-10"
                    >
                        <span>How does the platform enhance my online shopping experience?</span>
                        <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-10"
                    className="hidden"
                    aria-labelledby="accordion-collapse-heading-10"
                >
                    <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Our platform transforms your shopping experience by providing personalized product recommendations based on your aesthetic model. When we expand this feature to our partner retail sites, your model is used to filter products, showing you items that align with your unique style and preferences.
                        </p>
                    </div>
                </div>

                <h2 id="accordion-collapse-heading-11">
                    <button
                        type="button"
                        className="flex justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                        data-accordion-target="#accordion-collapse-body-11"
                        aria-expanded="false"
                        aria-controls="accordion-collapse-body-11"
                    >
                        <span>How does the platform ensure the relevance and quality of shopping recommendations?</span>
                        <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-11"
                    className="hidden"
                    aria-labelledby="accordion-collapse-heading-11"
                >
                    <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Our platform uses sophisticated algorithms to match products with your aesthetic model. We constantly update and refine our recommendation engine based on user feedback and shopping trends to ensure high relevance and quality of the suggestions.
                        </p>
                    </div>
                </div>

                <h2 id="accordion-collapse-heading-12">
                    <button
                        type="button"
                        className="flex justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm text-left"
                        data-accordion-target="#accordion-collapse-body-12"
                        aria-expanded="false"
                        aria-controls="accordion-collapse-body-12"
                    >
                        <span>How can I provide feedback or suggestions about the platform?</span>
                        <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-12"
                    className="hidden"
                    aria-labelledby="accordion-collapse-heading-12"
                >
                    <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            We highly value user feedback. You can provide feedback or suggestions via the feedback form available on our platform or by contacting our support team. We continually strive to improve our services based on user input.
                        </p>
                    </div>
                </div>

                <h2 id="accordion-collapse-heading-13">
                    <button
                        type="button"
                        className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 brand-border rounded-sm"
                        data-accordion-target="#accordion-collapse-body-13"
                        aria-expanded="false"
                        aria-controls="accordion-collapse-body-13"
                    >
                        <span>Where can I find help if I have questions or need assistance?</span>
                        <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                        </svg>
                    </button>
                </h2>
                <div
                    id="accordion-collapse-body-13"
                    className="hidden"
                    aria-labelledby="accordion-collapse-heading-13"
                >
                    <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 accordion-open-box">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            For any questions or assistance, please contact our support team - <a href="mailto:support@evoke-ai.com">support@evoke-ai.com</a>
                        </p>
                    </div>
                </div>
            </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
