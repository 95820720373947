import { getBadgeInfo } from "../../Utils/getBadgeInfo";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function ProgressBarWidget(props) {

    const getShownPercentage = () => {
        console.log("props")
        console.log(props)
        console.log(props.rawPercentComplete * 100)
        return Math.round(props.rawPercentComplete * 100);
    }

    return (
        <div className="progress-bar-container">
            <div className="relative pt-1">
                <div className="progress-badge">
                    <CircularProgressbar value={getShownPercentage()} text={`${getShownPercentage()}%`} />
                </div>
            </div>
        </div>
    )
}