import React from "react";

export default function Technology() {
  return (
    <>
      <div className="items-center flex flex-wrap justify-center">
        <h2 className="text-5xl text-black mt-0 text-center">
          Revolutionizing Retail with Aesthetic Intelligence Technology
        </h2>
        <div className="spacer-30"></div>

        <div className="technology-blurb-box">
          <div className="inner-blurb-box py-10 py-10">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full text-blurb-contain md:w-1/2">
                <p className="text-2xl font-bold text-black">
                  Empowering Personalized Experiences
                </p>
                <p className="mb-5 text-sm-mobile">
                  Our aesthetic intelligence technology leveraging advanced
                  algorithms and deep learning, we provide an unprecedented level
                  of personalization in product suggestions, catering to the
                  unique style and preferences of each customer.
                </p>
                <a
                  href="mailto:support@evoke-ai.com"
                  className="contact-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>

              <div className="w-full md:w-1/2 flex justify-center">
                <img
                  alt="retail image 1"
                  className="blurb-image text-black w-9/10 h-auto object-cover mx-auto"
                  src={require("assets/img/technology.png")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="technology-blurb-box">
          <div className="technology-blurb-box-grey"></div>
          <div className="inner-blurb-box py-10">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full text-blurb-contain md:w-1/2">
                <img
                  alt="retail image 2"
                  className="blurb-image text-black w-9/10 h-auto object-cover mx-auto"
                  src={require("assets/img/technology2.png")}
                />
              </div>
              <div className="flex flex-col w-1/2 text-blurb-contain w-full">
                <p className="text-2xl font-bold text-black">
                  How It Works: A Symphony of Data and Design
                </p>
                <p className="mb-5 text-sm-mobile">
                  Our technology is a blend of sophisticated visual and data
                  analysis and an intuitive understanding of design aesthetics. By
                  analyzing a vast array of data points, including past purchasing
                  behavior, our system intelligently predicts and recommends
                  products that align with individual tastes.
                  <br />
                  <br />
                  Our system's deep learning capabilities are continuously refined
                  with each customer interaction, ensuring a dynamic and
                  ever-improving recommendation process.
                </p>
                <a
                  href="mailto:support@evoke-ai.com"
                  className="contact-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="technology-blurb-box">
          <div className="inner-blurb-box pt-5">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full text-blurb-contain md:w-1/2">
                <p className="text-2xl font-bold text-black">
                  Unique Features that Set Us Apart
                </p>
                <p className="mb-5 text-sm-mobile">
                  Advanced Aesthetic Matching: Unlike traditional recommendation
                  engines, our system understands the nuances of design and style,
                  offering recommendations that resonate on a deeper aesthetic
                  level with customers.
                  <br />
                  <br />
                  Dynamic Learning Algorithms: Our technology continuously learns
                  and evolves with each customer interaction, ensuring that the
                  recommendations become more accurate and relevant over time.
                  <br />
                  <br />
                  Data-Driven Insights: We provide valuable insights into customer
                  preferences and trends, enabling retailers to make informed
                  decisions about inventory, marketing and product design.
                </p>
                <a
                  href="mailto:support@evoke-ai.com"
                  className="contact-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>

              <div className="w-full md:w-1/2 flex justify-center">
                <img
                  alt="retail image 3"
                  className="blurb-image text-black w-9/10 h-auto object-cover mx-auto"
                  src={require("assets/img/technology3.png")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="technology-blurb-box">
          <div className="technology-blurb-box-grey"></div>
          <div className="inner-blurb-box py-10">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full text-blurb-contain md:w-1/2">
                <img
                  alt="retail image 4"
                  className="blurb-image text-black w-9/10 h-auto object-cover mx-auto"
                  src={require("assets/img/technology4.png")}
                />
              </div>
              <div className="flex flex-col w-1/2 text-blurb-contain w-full">
                <p className="text-2xl font-bold text-black">
                  Tailored for Today, Designed for Tomorrow
                </p>
                <p className="mb-5 text-sm-mobile">
                  Our aesthetic intelligence technology isn't just about meeting
                  today's needs; it's about anticipating the future of retail. We
                  empower retailers to stay ahead of the curve, constantly
                  innovating and adapting to changing consumer behaviors and
                  preferences. With our technology, you're not just keeping up
                  with the trends; you're setting them.
                </p>
                <a
                  href="mailto:support@evoke-ai.com"
                  className="contact-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
