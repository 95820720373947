import { API } from "aws-amplify";

export const fetchVoteData = async (user) => {
    console.log('fetch data: ')
    console.log(user)
    try {
        if(!user) {
            console.log('getting votes for user');
            let tempUserId = localStorage.getItem("tempUserId")
            console.log('id being used: '+localStorage.getItem("tempUserId"))
            const response = await API.get("prntApi", `/getRatedImages?userId=${tempUserId}`, {
              headers: { Authorization: `8GGJ1pR4Hc40kx8YB0UBBasNBvpwGTnI8iyEuwkM` }
            });
            console.log('fetchVoteCount for temp user ', response.count)
            console.log(response);
            const { count, likes, dislikes } = response;
            return {
                total: count,
                likes,
                dislikes
            };
          }
          const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
          const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
          const response = await API.get("prntApi", `/getRatedImages?userId=${userId}`, {
            headers: { Authorization: `Bearer ${authToken}` }
          });
        // Parse the response structure
        const { count, likes, dislikes } = response;

        return {
            total: count,
            likes,
            dislikes
        };
    } catch (error) {
        console.error(error);
        return null;
    }
};