import { useAuthenticator } from '@aws-amplify/ui-react';
import { API } from "aws-amplify";
import { useState, useEffect, useRef, useCallback } from 'react';
import { ClipLoader } from 'react-spinners'; // Import spinner
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function GenerateUpscaleImage(props) {
    const { user } = useAuthenticator((context) => [context.user]);
    const [buttonState, setButtonState] = useState("idle");
    const [generatedImages, setGeneratedImages] = useState([]);
    const intervalId = useRef(null);
    const [stage, setStage] = useState("start");
    const [apiError, setApiError] = useState(null);
    const [subject, setSubject] = useState("");
    const [promptVisible, setPromptVisible] = useState({});

    const checkImageStatus = useCallback(async (uniqueIds) => {
        const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
        const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

        try {
            const response = await API.post("prntApi", "/getImagetxt", {
                headers: { Authorization: `Bearer ${authToken}` },
                body: {
                    "userId": userId,
                    "uniqueIds": uniqueIds
                }
            });

            if (response.images) {
                setGeneratedImages(response.images);
                setButtonState("idle");
                if (response.images.length === 1) {
                    cleanup();
                }
            }
        } catch (error) {
            console.error("Error occurred while calling API:", error);
        }
    }, [user]);

    const SocialShareButtons = ({ imageUrl }) => {
        const websiteURL = "https://evoke-ai.com";
        const encodedWebsiteURL = encodeURIComponent(websiteURL);
        const description = "Check out the art I created that resonates with my unique taste using my personal aesthetic model on Evoke AI! Your style, digitally defined.";

        return (
            <div className="share-buttons flex justify-left space-x-4 my-4">
                <a href={`https://twitter.com/intent/tweet?text=${description} ${encodedWebsiteURL}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter fa-lg" style={{ color: '#1DA1F2' }}></i>
                </a>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedWebsiteURL}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f fa-lg" style={{ color: '#1877F2' }}></i>
                </a>
                <a href={`https://www.instagram.com/`} target="_blank" rel="noopener noreferrer" title="Download the image and share on Instagram">
                    <i className="fab fa-instagram fa-lg" style={{ color: '#E4405F' }}></i>
                </a>
                <a href={`https://pinterest.com/pin/create/button/?url=${encodedWebsiteURL}&media=${imageUrl}&description=${description}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-pinterest fa-lg" style={{ color: '#BD081C' }}></i>
                </a>
                <a href={imageUrl} download title="Download Image" className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    <i className="fas fa-download"></i> Download
                </a>
            </div>
        );
    };

    const generateImages = async () => {
        setButtonState("loading");
        setStage("loading");
        props.hideArtBlurb();

        const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
        const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

        try {
            const response = await API.post("prntApi", "/generateImage", {
                headers: { Authorization: `Bearer ${authToken}` },
                body: { "userId": userId, "subject": subject }
            });

            setSubject("");

            if (response.uniqueIds && response.uniqueIds.length === 1) {
                intervalId.current = setInterval(() => checkImageStatus(response.uniqueIds), 5000);
            }

        } catch (error) {
            console.error("Error occurred while calling API:", error);
            if (error.response && error.response.status === 501) {
                setApiError('Hold up! You reached your limit for the day. Come back tomorrow to generate more personalized art!');
            }
            setButtonState("idle");
        }
    };

    useEffect(() => {
        if (generatedImages.length === 1) {
            setStage("complete");
            cleanup();
        }
    }, [generatedImages]);

    useEffect(() => {
        return () => {
            cleanup();
            setButtonState("idle");
            setGeneratedImages([]);
        };
    }, []);

    const cleanup = () => {
        if (intervalId.current !== null) {
            clearInterval(intervalId.current);
            intervalId.current = null;
        }
    };

    let buttonText;
    if (props.percentComplete < 100) {
        buttonText = "Vote More to Unlock";
    } else {
        switch (buttonState) {
            case "loading":
                buttonText = "Generating...";
                break;
            case "complete":
                buttonText = "Generation Complete";
                break;
            default:
                buttonText = "Create Image";
        }
    }

    return (
        <>
            {apiError && (
                <div className="alert alert-danger">
                    {apiError}
                </div>
            )}

            {/* {stage === "start" && (
                <>
                    <div className="lg:w-12/12 inline-block">
                        <p>Click the button below to create your personalized AI art.</p>
                        <p>You can add a custom subject (if unlocked) or have us figure out a subject for you based on your model<br /></p>
                        <p>The generation of your AI art will take about 1 min or less. <br />
                        So just sit tight and prepare to be amazed!</p>
                    </div>
                    <div className="spacer-15"></div>
                </>
            )} */}


                {stage === "start" && props.percentComplete >= 100 && (
                    <>
                        <div className="spacer-15"></div>

                        <input
                            type="text"
                            placeholder="Enter art subject *optional"
                            value={subject}
                            className="subject-input float-left rounded-sm"
                            onChange={(e) => setSubject(e.target.value)}
                        />

                        {stage === "start" && (
                            <button
                                className="generate-images-btn text-white px-5 py-3 rounded-sm outline-none focus:outline-none mr-1 mb-2 brand-background active:bg-lightBlue-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    if (props.percentComplete < 100) {
                                        window.location.href = "/art/survey";
                                    } else {
                                        generateImages();
                                        // gtag event
                                        window.gtag('event', 'generate_images_click', {
                                            'event_category': 'generate_images',
                                            'event_label': 'Generate Images Button Clicked'
                                        });
                                    }
                                }}
                            >
                                {buttonText}
                            </button>
                        )}
                    </>
                )}
                {/* {props.percentComplete >= 100 && stage !== "complete" && stage !== "loading" && (
                    <span>
                        <p className="text-xs">Enter a subject/topic to create related images.</p>
                        <p className="text-xs">*Or just press “generate images” to skip using a custom subject.</p>
                        <p className="centered-gen-text text-center">- Then -</p>
                    </span>
                )} */}

            <div className="generated-image-grid">
                {buttonState === "loading" && 
                    <>
                        <ClipLoader color="#000000" size={50} /> ...This may take up to a minute 
                    </>
                }
                <div className="generated-image-box">
                    {generatedImages.map((image, index) => (
                        <div key={index}>
                            <img className="generated-image" src={image.imageUrl} alt={`Generated ${index}`} style={{ marginRight: '15px' }} />
                            <SocialShareButtons imageUrl={image.imageUrl} />
                            <button
                                onClick={() => setPromptVisible(prevState => ({
                                    ...prevState,
                                    [image.imageId]: !prevState[image.imageId]
                                }))}
                                className="prompt-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            >
                                Prompt
                            </button>
                            {promptVisible[image.imageId] && <p className="prompt-text"><span className="font-bold">Image prompt: </span>{image.prompt}</p>}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}




