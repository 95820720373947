import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

export const ShopifyLoadingModel = (props) => {
	const goToShop = () => {
		console.log("navigate to shop with model results");
		props.nextScreen();
	};

	return (
		<div className="shopify-ad bg-white shadow-md rounded-lg p-6 mx-auto text-black">
			<div className="flex flex-col space-y-4">
				<h3 className="text-xl font-bold">
					<span className="shopify-pink-text">Great</span> Job
				</h3>
				<p>
					Your <span className="shopify-pink-text">Personalized AI Shopping Assistant</span> is working on
					product recommendations you are sure to love. This should only take a minute.
				</p>
				<p>
					Once the <span className="shopify-pink-text">countdown</span> below is complete your personalized
					recommendations will be available and you will be taken to your{" "}
					<span className="shopify-pink-text">personalized shopping results</span>.
				</p>
				<img
					src={require("assets/img/loadingModelDreaming.png")}
					alt="Dreaming up AI Model"
					className="mx-auto"
				></img>
				<h3 className="font-bold text-black">
					<span className="shopify-pink-text">Personalizing</span> Your Recommendations
				</h3>
				<div className="text-3xl shopify-purple-text mx-auto">
					<CountdownCircleTimer
						isPlaying
						duration={60}
						size={100}
						onComplete={goToShop}
						colors={["#7163e4", "#bf6cf7"]}
						colorsTime={[50, 15]}
					>
						{({ remainingTime }) => remainingTime}
					</CountdownCircleTimer>
				</div>
			</div>
		</div>
	);
};
