/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_5ulrmVDTl",
  aws_user_pools_web_client_id: "4etq0vrm9vrt536dkn63tspcmm",
  
  oauth: {
    domain: "prnt-user-pool.auth.us-east-1.amazoncognito.com",
    scope: ["phone", "email", "openid", "profile"],
    redirectSignIn: "https://evoke-ai.com/",
    redirectSignOut: "https://evoke-ai.com/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  API: {
    endpoints: [
      {
        name: "prntApi",
        endpoint: "https://7qekcvxuu9.execute-api.us-east-1.amazonaws.com/prod",
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: 'prnt-assets-bucket', 
      region: 'us-east-1',
    },
  },
};

export default awsmobile;
