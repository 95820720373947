import React, { useState } from 'react';
import { API } from "aws-amplify";
import ShareButtonsPopout from "components/ShareButtonsPopout/ShareButtonsPopout";

const ProductButtons = ({ productId, imageUrl, category, user, refreshImages, hideWishList }) => {
  const [isLiked, setIsLiked] = useState(false);
  if (!user) return null;
  if (!user || category === 'Wishlist') return null;

  const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
  const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

  const handleButtonClick = async (event, action) => {
    event.preventDefault();  // Prevent default action
    event.stopPropagation(); // Prevents the event from reaching the parent <a> tag

    console.log('Image vote cast '+event.target.value)
    console.log(event)
    console.log(action)

    try {
      let endpoint = '';
      let body = {};

      if (action === 'wishlist') {
        endpoint = "/wishlistProduct";
        body = { userId, imageId: productId, url: imageUrl, category };
      } else if (action === 'vote') {
        endpoint = "/voteProduct";
        body = { userId, imageId: productId, vote: event.target.value };
      }

      if (endpoint) {
        await API.post("prntApi", endpoint, {
          headers: { Authorization: `Bearer ${authToken}` },
          body
        });

        if (refreshImages) {
          refreshImages(); // Refresh images after the action
        }
      }
    } catch (error) {
      console.error('Error during action:', error);
    }
  };

  return (
    // <div className="flex space-x-2">
    <div className="flex justify-between mt-5">
       <button
        className="flex flex-item shop-dislike-btn text-xl text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-sm text-sm px-4 py-1 text-center me-1 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
        onClick={(e) => handleButtonClick(e, 'vote')}
        value={0}
        >
          <i className="far fa-thumbs-down pointer-events-none"></i>
        </button>
        <button
          className="flex flex-item shop-like-btn text-xl text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-sm text-sm px-4 py-1 text-center me-1 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
          onClick={(e) => handleButtonClick(e, 'vote')}
          value={1}
        >
          <i className="far fa-thumbs-up pointer-events-none"></i>
        </button>
        <span className="flex flex-item ">
          <ShareButtonsPopout
            user={user}
            url={imageUrl}
            imageUrl={imageUrl}
          />
        </span>
        <button
          className={"shop-wish-btn text-xl text-red " +(hideWishList && 'hidden')}
          title="Add to wishlist"
          onClick={(e) => handleButtonClick(e, 'wishlist')}
          onMouseEnter={() => setIsLiked(true)} // Change to solid heart on hover
          onMouseLeave={() => setIsLiked(false)} // Revert back on hover out if not clicked
        >
          <i className={isLiked ? "fas fa-heart" : "far fa-heart"}></i>
        </button>
    </div>
  );
};


export default ProductButtons;