export const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email",
        label: "Email",
        isRequired: true,
      },
    },
    signUp: {
      given_name: {
        placeholder: "Enter your Name",
        label: "Full Name",
        isRequired: true,
        order: 1,
      },
      email: {
        placeholder: "Enter your email",
        label: "Email",
        isRequired: true,
        order: 2,
      },
      password: {
        label: "Password:",
        placeholder: "Enter your Password:",
        isRequired: true,
        order: 3,
      },
      confirm_password: {
        label: "Confirm Password:",
        order: 4,
      },
    },
  };