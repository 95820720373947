import { useState, useEffect, useRef } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { API } from "aws-amplify";
import ClipLoader from "react-spinners/ClipLoader";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import html2canvas from 'html2canvas';

function ArtProfile(props) {
    const { user } = useAuthenticator((context) => [context.user]);
    const [artProfile, setArtProfile] = useState([]);
    const [artisticName, setArtisticName] = useState(""); 
    const [loading, setLoading] = useState(false);
    const chartRef = useRef(null);

    useEffect(() => {
        if (props.percentComplete >= 50) {
            setLoading(true);
            const fetchArtProfile = async () => {
                const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
                const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
                const userEmail = user?.getSignInUserSession()?.getIdToken()?.payload.email;

                try {
                    const payload = {
                        body: {
                            "userId": userId,
                            "userEmail": userEmail
                        },
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                            "Content-Type": "application/json"
                        },
                    };

                    const response = await API.post("prntApi", "/artprofile", payload);
                    if (response && response.prompt) {
                        const lines = response.prompt.split("\n");
                        const parsedData = lines.map(line => {
                            const [title, score] = line.split(':');
                            if (title.trim() === "Artistic Name") { 
                                setArtisticName(score.trim());
                                return null; 
                            } else {
                                return {
                                    trait: title.trim(),
                                    value: parseFloat(score.trim())
                                };
                            }
                        }).filter(Boolean);
                        setArtProfile(parsedData);
                        setLoading(false);
                    }
                } catch (error) {
                    console.error("Error occurred while calling API:", error);
                }
            };

            const intervalId = setInterval(fetchArtProfile, 15000);

            return () => {
                clearInterval(intervalId);
            }
        }
    }, [props.percentComplete]);

    const handleDownload = () => {
        if (chartRef.current) {
            html2canvas(chartRef.current, {
                width: 750,
                height: 500,
                windowWidth: chartRef.current.scrollWidth,
                windowHeight: chartRef.current.scrollHeight
            }).then(canvas => {
                const imgURL = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = imgURL;
                link.download = 'art_profile.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
    };

    const SocialShareButtons = () => {
        const websiteURL = "https://evoke-ai.com";
        const encodedWebsiteURL = encodeURIComponent(websiteURL);
        const description = "Look at my unique Art Profile on Evoke AI!";
        return (
            <div className="share-buttons flex justify-left space-x-4 my-4">
                <a href={`https://twitter.com/intent/tweet?text=${description} ${encodedWebsiteURL}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter fa-lg" style={{ color: '#1DA1F2' }}></i>
                </a>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedWebsiteURL}`} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f fa-lg" style={{ color: '#1877F2' }}></i>
                </a>
                <a href={`https://www.instagram.com/`} target="_blank" rel="noopener noreferrer" title="Download the radar chart and share on Instagram">
                    <i className="fab fa-instagram fa-lg" style={{ color: '#E4405F' }}></i>
                </a>
                <button onClick={handleDownload} className="px-2 py-1 border rounded text-sm text-black hover:bg-blue-700 hover:text-white transition duration-150">
                    <i className="fas fa-download"></i> Download
                </button>
            </div>
        );
    };

    return (
        <div>
            <h2 className="text-2xl subtitle-underlined text-black">Aesthetic Traits</h2>
            {loading ? (
                <ClipLoader color="#000000" size={50} />
            ) : artProfile.length > 0 ? (
                <>
                <div ref={chartRef} className="mobile-hidden">
                    <RadarChart cx={240} cy={150} outerRadius={100} width={550} height={350} data={artProfile}>
                        <PolarGrid stroke="#3B82F6" strokeDasharray="solid" />
                        <PolarAngleAxis dataKey="trait" tick={{ fontSize: '14px', fontWeight: 'bold' }} />
                        <PolarRadiusAxis angle={25} domain={[0, 100]} tick={{ fontSize: '14px', fontWeight: 'bold', fill: 'black' }} />
                        <Radar name="Preferences" dataKey="value" stroke="#3B82F6" fill="#3B82F6" fillOpacity={0.6} strokeWidth={2} />
                    </RadarChart>
                    <h3 className="text-xl mt-4 strong">Artistic Name: {artisticName}</h3>
                    <SocialShareButtons />
                </div>
                <div ref={chartRef} className="mobile-shown">
                    <RadarChart cx={'45%'} cy={120} outerRadius={'40%'} width={375} height={250} data={artProfile}>
                        <PolarGrid stroke="#3B82F6" strokeDasharray="solid" />
                        <PolarAngleAxis dataKey="trait" tick={{ fontSize: '10px' }} />
                        <PolarRadiusAxis angle={25} domain={[0, 100]} tick={{ fontSize: '10px' }} />
                        <Radar name="Preferences" dataKey="value" stroke="#3B82F6" fill="#3B82F6" fillOpacity={0.6} strokeWidth={2} />
                    </RadarChart>
                    <h3 className="mt-4 strong text-black">Artistic Name: {artisticName}</h3>
                    <SocialShareButtons />
                </div>
                </>
            ) : (
                <div>No data available yet, rate more images to unlock.</div>
            )}
            <div className="spacer-30"></div>
        </div>
    );
}

export default ArtProfile;