import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchVoteCount } from "../../Utils/fetchVoteCount.js";
import ImageRatingWidget from "../../components/Rating/imageRatingWidget.js";
import ProgressBarWidget from "../../components/Rating/progressBarWidget.js";

export const ShopifyQuiz = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [percentComplete, setPercentComplete] = useState(0);

	const { user } = useAuthenticator((context) => [context.user]);

	const [votes, setVotes] = useState(0);

	const incrementVotes = () => {
		console.log("incrementVotes");
		console.log("votes");
		console.log(votes);
		setVotes(votes + 1);
	};

	const getRawPercentComplete = (rawPercentComplete) => {
		console.log("rawPercentComplete");
		console.log(rawPercentComplete);
		setPercentComplete(rawPercentComplete);
	};

	useEffect(() => {
		console.log("effects!");
		console.log(props);
		console.log("votes");
		console.log(votes);
		const fetchVotes = async () => {
			try {
				const fetchedVoteCount = await fetchVoteCount(user);
				if (fetchedVoteCount) {
					setVotes(fetchedVoteCount);
				}
			} catch (error) {
				console.log(`ERROR: ${error}`);
			}
		};
		fetchVotes();
	}, [props, votes, user]);

	useEffect(() => {
		console.log("change frame pre");
		const changeIframeParentSize = (width = "100%", height = "800px", background = "#fff") => {
			let message = { height: height, width: width, background: background };
			window.parent.postMessage(message, "*");
			console.log("changing frame ");
			console.log(message);
		};
		changeIframeParentSize();
	}, [isOpen]);

	return (
		<>
			<section className="shopify-art-survey text-black text-center">
				<p className="text-2xl text-black mt-0 text-center">
					<span className="shopify-pink-text">Rate</span> the Following Images
				</p>
				<p>
					We will use this data to create your{" "}
					<span className="shopify-pink-text text-center">Style Assistant</span>
				</p>
				<div className="items-center flex flex-wrap justify-center">
					<div className="mobile-rating-progress-bar mobile-shown">
						<ProgressBarWidget rawPercentComplete={props.percentComplete} numVotes={votes} />
					</div>
					<ImageRatingWidget
						getRawPercentComplete={getRawPercentComplete}
						percentComplete={props.percentComplete}
						numVotes={votes}
						incrementVotes={incrementVotes}
						handleScreenChange={props.handleScreenChange}
					/>
				</div>

				<div className="w-full md:w-12/12 lg:w-12/12 xl:w-12/12">
					<div className="items-center flex flex-wrap justify-center">
						<ProgressBarWidget rawPercentComplete={percentComplete} numVotes={votes} />
						<div className="progress-bar-text font-bold mx-4 text-black">
							Survey Progress
							<br />
							<span className="vote-amount-text font-bold mx-4 text-sm brand-text">{votes} Votes</span>
						</div>
					</div>
				</div>
				<div className="reward-button-container">
					{Math.floor(props.rawPercentComplete * 100) >= 100 && (
						<a href="/art/create">
							<button className="bg-pink-500 take-survey-from-create-btn ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
								Create Art
							</button>
						</a>
					)}
					{Math.floor(props.rawPercentComplete * 100) >= 50 && (
						<a href="/art/portfolio">
							<button
								href="/art/portfolio"
								className="bg-purple-500 take-survey-from-create-btn ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
							>
								Portfolio
							</button>
						</a>
					)}
				</div>
				<div className="spacer-30 mobile-hidden"></div>
			</section>
		</>
	);
};
