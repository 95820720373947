
import React from "react";

export default function Community() {
  return (
      <div className="content-area mx-auto">
        <h1 className="text-2xl font-bold">Community Art</h1>
        <h2 className="subtitle text-xl ">Arriving At Your Aesthetic Preferences</h2>
        <p>Ready to unleash your creativity? Our survey is designed to help us understand your aesthetic preferences, discover your art profile and art personality, and allows us to create AI art that is uniquely yours.</p>
        <ul className="list-decimal">
          <li>Go with your initial instincts to simply rate each image by “dislike” or “ like”. </li>
          <li>We will then create your personalized art. </li>
          <ul className="list-alpha">
            <li>Please rate rate your art on a scale of 1 to 10 [ten: really like].</li>
            <li>If you like your personalized art piece, share it on social media </li>
          </ul>
        </ul>
      </div>
  );
}
