import React from "react";
// components

import CardSettingsPrntProfile from "components/Cards/CustomCards/CardSettingsPrntProfile";
import CardProfilePrnt from "components/Cards/CustomCards/CardProfilePrnt.js";

export default function Settings(props) {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <CardSettingsPrntProfile votes={props.votes} percentComplete={props.percentComplete}/>
        </div>
        <div className="w-full lg:w-4/12 px-4">
          <CardProfilePrnt votes={props.votes} percentComplete={props.percentComplete}/>
        </div>
      </div>
    </>
  );
}
