import React, { useState, useEffect } from "react";

export default function CreateArtCardVertical(props) {
  const [howManyImages, setHowManyImages] = useState(0);

  useEffect(() => {
    if(!props.howManyImages) return setHowManyImages('more');
    if(props.howManyImages === 1) return setHowManyImages('one');
    if(props.howManyImages === 2) return setHowManyImages('two');
    setHowManyImages('more');
  }, [props.howManyImages]);

  return (
    <>
      <div className={"create-art-card-wrapper-vertical " + howManyImages}>
        {props.category === 'Wishlist' && (<i className={"wishlist-heart text-xl text-red fas fa-heart"}></i>)}
        <div className="image-container-vertical">
          <a href={props.href} target="_blank" rel="noopener noreferrer">
            <img src={props.img} alt="Card Image" className={"card-image w-full " + ((props.category === "Women's Loafers" || props.category === "Men's Loafers")  ? '' : 'object-contain h-full')} />
          </a>
        </div>
        <div className="p-3 pt-0">
          <h3 className="card-title text-xl font-bold">{props.title}</h3>
          <h3 className="card-subtitle text-l">{props.subtitle}</h3>
          <div className="card-blurb font-bold">{props.blurb}</div>
        </div>
      </div>
    </>
  );
}
