import React from "react";

export default function ForRetailers() {
  return (
    <>
      <div className="items-center flex flex-wrap justify-center">
        <h2 className="text-5xl text-black mt-0 text-center">
          Product Recommendations
          <br />
          Personalized to Perfection
        </h2>
        <div className="spacer-30"></div>
        
        <div className="technology-blurb-box">
          <div className="inner-blurb-box py-10 py-10">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full text-blurb-contain md:w-1/2">
                <p className="text-2xl font-bold text-black">
                  Revolutionizing Retail with Aesthetic Intelligence
                </p>
                <p className="mb-5">
                  Welcome to the forefront of retail innovation! Our cutting-edge
                  aesthetic intelligence technology is redefining how retailers
                  connect with their customers. By transcending traditional
                  product recommendation methods, we offer a uniquely personalized
                  shopping experience, tailoring suggestions to each individual's
                  style and preferences.
                </p>
                <a
                  href="mailto:support@evoke-ai.com"
                  className="contact-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>

              <div className="w-full md:w-1/2 flex justify-center">
                <img
                  alt="retail image 1"
                  className="blurb-image text-black w-9/10 h-auto object-cover mx-auto"
                  src={require("assets/img/forRetailers1.png")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="technology-blurb-box">
          <div className="technology-blurb-box-grey"></div>
          <div className="inner-blurb-box py-10">
           <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full text-blurb-contain md:w-1/2">
                <img
                  alt="retail image 2"
                  className="blurb-image text-black w-9/10 h-auto object-cover mx-auto"
                  src={require("assets/img/forRetailers2.png")}
                />
              </div>
              <div className="flex flex-col w-1/2 text-blurb-contain w-full">
                <p className="text-2xl font-bold text-black">
                  Beyond Conventional Methods
                </p>
                <p className="mb-5">
                  Gone are the days of one-size-fits-all recommendations. Our
                  technology goes beyond the limits of standard content and
                  collaborative filtering. We understand that each customer is
                  unique, and our sophisticated algorithms are designed to cater
                  to their distinct tastes. This means more relevant suggestions,
                  higher customer satisfaction, and increased loyalty.
                </p>
                <a
                  href="mailto:support@evoke-ai.com"
                  className="contact-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="technology-blurb-box">
          <div className="inner-blurb-box py-10">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full text-blurb-contain md:w-1/2">
                <p className="text-2xl font-bold text-black">
                  Expanding the Demand Curve: Unlocking New Market Opportunities
                </p>
                <p className="mb-5">
                  Our technology doesn’t just match products to customers; it
                  uncovers hidden desires. By identifying and recommending
                  products that customers didn’t even know they wanted, we help
                  you enhance product visibility, and ultimately, expand your
                  demand curve. This leads to new revenue streams and a stronger
                  market position.
                </p>
                <a
                  href="mailto:support@evoke-ai.com"
                  className="contact-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>

              <div className="w-full md:w-1/2 flex justify-center">
                <img
                  alt="retail image 3"
                  className="blurb-image text-black w-9/10 h-auto object-cover mx-auto"
                  src={require("assets/img/forRetailers3.png")}
                />
              </div>
            </div>

          </div>
        </div>

        <div className="technology-blurb-box">
          <div className="technology-blurb-box-grey"></div>
          <div className="inner-blurb-box py-10">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full text-blurb-contain md:w-1/2">
                <img
                  alt="retail image 4"
                  className="blurb-image text-black w-9/10 h-auto object-cover mx-auto"
                  src={require("assets/img/forRetailers4.png")}
                />
              </div>
              <div className="flex flex-col w-1/2 text-blurb-contain w-full">
                <p className="text-2xl font-bold text-black">
                  Seamless Integration and Ease of Use
                </p>
                <p className="mb-5">
                  Integrating our technology into your existing systems is a
                  breeze. Designed with simplicity and efficiency in mind, it
                  ensures a seamless transition and a user-friendly experience for
                  both you and your customers.
                  <br />
                  To start with us, all that is needed is to account for three
                  aspects on your site: user registration, visual quiz and
                  AI-powered product recommendatiions
                </p>
                <a
                  href="mailto:support@evoke-ai.com"
                  className="contact-button text-white px-6 py-4 rounded-sm outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
