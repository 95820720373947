/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import AuthModal from "components/Auth/Auth";
import evokeAILogo from 'assets/img/Evoke_black.png';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut])
  const [currentPage, setCurrentPage] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut();
      console.log('this on sign out?')
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
      // User has been signed out
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };

  const handleNavClick = (navName) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'navbar_click', {
        'event_category': 'engagement',
        'event_label': navName
      });
    }
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname.slice(1));
  }, []);

  useEffect(() => {
    if (props.showAuthModalFromFooter === true) {
        setShowAuthModal(true)
    }
  }, [props.showAuthModalFromFooter]);
  
  return (
    <>
      {showAuthModal && (
        <AuthModal
          onClick={() => setShowAuthModal(false)}
        />
      
      )}
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg bg-white shadow fixed">
        <div className="top-nav-bar container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="nav-menu-box w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a href="/"><span className="mobile-shown text-black text-xl m-5">Evoke AI</span></a>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div className="w-12/12 md:w-8/12 lg:w-8/12 md:px-1 mr-auto ml-auto mobile-top-menu">
            <div
              className={
                "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
                (navbarOpen ? " block" : " hidden")
              }
              id="example-navbar-warning"
            >
              <Link
                to="/#"
                className="text-black font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap font-aldrich mobile-hidden"
                onClick={() => handleNavClick('Home Page Button')}
              >
                <img src={evokeAILogo} alt="Evoke AI" style={{ height: 'auto', maxWidth: '125px' }} /> 
                {/* <img src={require("assets/img/prnt-logo-black.png")} alt="prnt-logo" className="prnt-logo-main" /> */}
              </Link>
              <ul className="nav-item-list flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center">
                  <a
                    className={"hover:text-blueGray-500 px-3 lg:py-2 flex items-center " + (currentPage === 'art/survey' && 'brand-text font-bold')}
                    href="/art/survey"
                    onClick={() => handleNavClick('Rate')}
                  >
                    <span>Rate</span>
                  </a>
                </li>
                {/* {!user && (
                  <li style={{width: "50px"}}></li>
                )} */}
                  <>
                  {/* <li className="flex items-center">
                    <a
                      className={"hover:text-blueGray-500 px-3 lg:py-2 flex items-center " + (currentPage === 'art/library' && 'brand-text font-bold')}
                      href="/art/library"
                      onClick={() => handleNavClick('Library')}
                    >
                      Library
                      </a>
                  </li> */}
                  <li className="flex items-center">
                    <a
                      className={"hover:text-blueGray-500 px-3 lg:py-2 flex items-center " + (currentPage === 'art/portfolio' && 'brand-text font-bold')}
                      href="/art/portfolio"
                      onClick={() => handleNavClick('Portfolio')}
                    >
                      Portfolio
                      {/* <span className="lg:hidden inline-block ml-2">Portfolio</span> */}
                      </a>
                  </li>
                  <li className="flex items-center">
                    <a
                      className={"hover:text-blueGray-500 px-3 lg:py-2 flex items-center " + (currentPage === 'shopping' && 'brand-text font-bold')}
                      href="/shopping?categories=painting"
                      onClick={() => handleNavClick('Shop')}
                    >
                      Shop
                      {/* <span className="lg:hidden inline-block ml-2">Portfolio</span> */}
                      </a>
                  </li>
                  {/* <li className="flex items-center">
                    <a
                      className={"hover:text-blueGray-500 px-3 lg:py-2 flex items-center " + (currentPage === 'member-gallery' && 'brand-text font-bold')}
                      href="/member-gallery"
                      onClick={() => handleNavClick('Members')}
                    >
                      <span>Member Gallery</span>
                    </a>
                  </li> */}
                  {/* <li className="flex items-center">
                    <a
                      className={"hover:text-blueGray-500 px-3 lg:py-2 flex items-center " + (currentPage === 'profile' && 'brand-text font-bold')}
                      href="/profile"
                      onClick={() => handleNavClick('settings')}
                    >
                      Settings
                      </a>
                  </li> */}
                  <li style={{width: "50px"}}></li>
                  {/* <li className="flex items-center">
                    <a
                      className="hover:text-blueGray-500 px-3 lg:py-2 flex items-center"
                      href="/art/community"
                    >
                      Community Art
                      <span className="lg:hidden inline-block ml-2">Profile</span>
                      </a>
                  </li> */}
                  </>
                <li className="flex items-end">
                  <span className="hover:text-blueGray-500 lg:py-2 flex items-center">
                      {user ? (
                        <div className="">
                           <a
                              className={"hover:text-blueGray-500 px-3 lg:py-2 flex items-center " + (currentPage === 'profile' && 'brand-text font-bold')}
                              href="/profile"
                              onClick={() => handleNavClick('settings')}
                            >
                              <p>Welcome, {user && user.signInUserSession?.idToken?.payload?.given_name}!</p>
                            </a>
                        </div>
                        ) : (
                          <div>
                              <button className="get-started text-white px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150" 
                                onClick={() => {
                                  setShowAuthModal(true);
                                  setNavbarOpen(false);
                                }}>Sign In</button>
                          </div>
                        )}
                        {user && (
                          <>
                            <img
                            src={require("assets/img/gravatarSample.jpg")}
                            style={{height: '35px', position: 'relative', top: '-3px', cursor: 'pointer'}}
                            alt="..."
                          />
                          <button  onClick={handleSignOut}>Sign Out</button>
                          </>
                        )}
                    </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}