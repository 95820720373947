import React, { useState, useCallback, useEffect } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import Carousel from 'react-grid-carousel';
import SlideCarouselCard from "components/Cards/CustomCards/SlideCarouselCard";
import shoppingData from "assets/data/shopping.json";
import ClipLoader from "react-spinners/ClipLoader";

export default function SlideCarousel(props) {
  const { user, signOut } = useAuthenticator((conp) => [conp.user]);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minMatchPercentage, setMinMatchPercentage] = useState(0);
  const [randCategory, setRandCategory] = useState(null);

  const [likedImages, setLikedImages] = useState(null);

  const capitalizeWords = (str) =>
  str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const getEcommerceLikedImages = async () => {
    setLoading(true);
    try {
      const authToken = user
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

      // Fetching liked images
      const response = await API.get(
        "prntApi",
        `/getInferencedImages?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
  
      const { topLikedRecords: likedImagesResponse, categoryList } = response;

      // Grouping images by category
      const groupedImages = likedImagesResponse.reduce((acc, image) => {
        (acc[image.category] = acc[image.category] || []).push(image);
        return acc;
      }, {});

      if (Object.keys(groupedImages).length === 0) {
        setLikedImages(null);
      } else {
        setLikedImages(groupedImages);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      getEcommerceLikedImages();
    } else {
      const groupedImages = shoppingData.reduce((acc, image) => {
        (acc[image.category] = acc[image.category] || []).push(image);
        return acc;
      }, {});
      if (Object.keys(groupedImages).length === 0) {
        setLikedImages(null);
      } else {
        setLikedImages(groupedImages);
      }
    }
  }, [user]);

  const handleNavClick = (navName) => {
    window.gtag('event', 'navbar_click', {
      'event_category': 'engagement',
      'event_label': navName
    });
  };

  useEffect(() => {
    const allCategories = likedImages ? Object.keys(likedImages) : [];
    const updatedCategories = allCategories.length ? allCategories.slice(0, 11).concat(allCategories.slice(15)) : allCategories;

    if(updatedCategories) {
      const randN = Math.floor(Math.random() * updatedCategories.length -1);
      if(randN >= 0) {
        setRandCategory(updatedCategories[randN])
      } else {
        setRandCategory(updatedCategories[0])
      }
    }
  }, [likedImages]);
  
  return (
    <section className="carousel">
    <div className="container mx-auto items-center flex flex-wrap justify-center">
      <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4">
      {loading ? (
        <ClipLoader color="#000000" className="loading-centered" size={50} />
      ) : likedImages && likedImages[randCategory] ? (
        (() => {
          const filteredImages = likedImages[randCategory];
          if (!filteredImages || !filteredImages[0] || !filteredImages[0]['records']) return null;
          return (
            <div key="category-painting">
              <div className="mx-auto relative min-w-0 break-words bg-white w-full mb-6 mt-5 text-left">
                <Carousel cols={4} rows={1} gap={10} loop>
                  {filteredImages[0]['records'].map((image, imageIndex) => (
                    <Carousel.Item key={`image-${imageIndex}`}>
                      <SlideCarouselCard 
                        img={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
                        href={user ? image.url : "/shopping"}
                        category={randCategory}
                        blurb={
                          <div className="">
                            <div className="flex flex-1 flex-item text-xs">{`${Math.round(image.confidence)}% Match`}</div>
                            <div className="flex flex-1 flex-item text-xs">Recommended for {user && user.signInUserSession?.idToken?.payload?.given_name ? user.signInUserSession?.idToken?.payload?.given_name : 'you'}</div>
                            <div className="spacer-15 mobile-hidden"></div>
                          </div>
                        }
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
          );
        })()
      ) : (
        <p></p>
      )}

      </div>
    </div>
  </section>
  );
}
   
