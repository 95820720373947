import React, { Suspense, useState, useEffect } from "react";

const loadComponent = (fileName) => React.lazy(() => import(`views/blog-pages/${fileName}.js`));

export default function BlogPage() {

  const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

  const [Component, setComponent] = useState(null);

  useEffect(() => {
    // Takes the query param and grabs the file with that name eg: blogpage?date=20240227 grabs 20240227.js
    const fileName = queryParams.date;
    let DynamicComponent;
    try {
      if(!fileName) return;
      DynamicComponent = loadComponent(fileName);
    } catch (e) {
      console.log('no file found')
    }
    
    setComponent(DynamicComponent);
  }, []);

  return (
    <div className="blog-page">
      <div className="blog-return-link">
        <a href="/blog"><i className="fas fa-arrow-left"></i> Back to Blog list</a>
      </div>
      {Component && (
        <Suspense fallback={<div>Loading...</div>}>
          <Component />
        </Suspense>
      )}
      <div className="spacer-50"></div>
    </div>
  );
}
