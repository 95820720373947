import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

import ImageRatingWidget from "../components/Rating/imageRatingWidget.js";
import ProgressBarWidget from "../components/Rating/progressBarWidget";
import { fetchVoteCount } from "../Utils/fetchVoteCount";

export default function ArtSurvey(props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [percentComplete, setPercentComplete] = useState(0);
  const [votes, setVotes] = useState(0);
  const [popupIndex, setPopupIndex] = useState(0);
  const [loadingVotes, setLoadingVotes] = useState(true);
  const popups = [
    "Start here! Rate images to build your preference profile",
    "After rating images go to create section to automatically create art based on your style preferences (50 votes)",
    "Rate more to unlock and discover your art profile (100 votes), and art personality (150 votes) in your portfolio",
    "View the images you liked in your Library",
    "Continue rating to unlock your very own aesthetic model (250 votes). It will power your personalized shopping experience in the shop section!",
  ];

  const fetchVotes = async () => {
    try {
      const fetchedVoteCount = await fetchVoteCount(user);
      if (fetchedVoteCount) {
        localStorage.setItem("currentVoteCount", fetchedVoteCount);
        setVotes(fetchedVoteCount);
        setLoadingVotes(false);
      }
    } catch (error) {
      console.log(`ERROR: ${error}`);
      setLoadingVotes(false);
    }
  };

  const getMenuItemPositions = (step) => {
    let menuItemPopoverPosition;
    let localOffset = 0;
    switch (step) {
      case 0:
        menuItemPopoverPosition = document.querySelector(".rate-menu-item");
        break;
      case 1:
        menuItemPopoverPosition = document.querySelector(".create-menu-item");
        localOffset = -10;
        break;
      case 2:
        menuItemPopoverPosition = document.querySelector(
          ".portfolio-menu-item"
        );
        break;
      case 3:
        menuItemPopoverPosition = document.querySelector(".library-menu-item");
        localOffset = 10;
        break;
      case 4:
        menuItemPopoverPosition = document.querySelector(".shopping-menu-item");
        localOffset = -10;
        break;
      default:
        menuItemPopoverPosition = document.querySelector(".rate-menu-item");
    }

    if (menuItemPopoverPosition) {
      return menuItemPopoverPosition.offsetTop + localOffset;
    }
  };

  const incrementVotes = () => {
    console.log("incrementVotes");
    console.log("votes");
    console.log(votes);
    setVotes(votes + 1);
  };

  const getRawPercentComplete = (rawPercentComplete) => {
    console.log("rawPercentComplete");
    console.log(rawPercentComplete);
    setPercentComplete(rawPercentComplete);
  };

  const scrollTo = (className) => {
    let elements = document.getElementsByClassName(className);
    console.log("SCROLLING TO");
    if (elements.length > 0) {
      elements[0].scrollIntoView();
    } else {
      console.log(
        "No elements with the class name " + className + " were found"
      );
    }
  };

  useEffect(() => {
    fetchVotes();
  }, [user]);

  return (
    <section className="art-survey">
      <div className="items-center flex flex-wrap justify-center">
        <h2 className="text-5xl text-black mt-0 text-center">
          Unlock Your Digital Style Twin Vote Here
        </h2>
        <div className="spacer-15 mobile-hidden"></div>
        <div className="mobile-rating-progress-bar mobile-shown">
          <ProgressBarWidget
            rawPercentComplete={percentComplete}
            numVotes={votes}
          />
        </div>
        <ImageRatingWidget
          getRawPercentComplete={getRawPercentComplete}
          percentComplete={props.percentComplete}
          numVotes={votes}
          incrementVotes={incrementVotes}
        />
      </div>

      <div className="w-full md:w-12/12 lg:w-12/12 xl:w-12/12">
        <div className="items-center flex flex-wrap justify-center">
          <ProgressBarWidget
            rawPercentComplete={percentComplete}
            numVotes={votes}
          />
          <div className="progress-bar-text font-bold mx-4 text-black">
            Survey Progress
            <br />
            <span className="vote-amount-text font-bold mx-4 text-sm brand-text">
              {votes} Votes
            </span>
          </div>
        </div>
      </div>
      <div className="reward-button-container">
        {Math.floor(props.rawPercentComplete * 100) >= 100 && (
          <a href="/art/create">
            <button className="bg-pink-500 take-survey-from-create-btn ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
              Create Art
            </button>
          </a>
        )}
        {Math.floor(props.rawPercentComplete * 100) >= 50 && (
          <a href="/art/portfolio">
            <button
              href="/art/portfolio"
              className="bg-purple-500 take-survey-from-create-btn ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              Portfolio
            </button>
          </a>
        )}
      </div>
      <div className="spacer-30 mobile-hidden"></div>
      <div className="spacer-30"></div>
      <div className="items-center flex flex-col md:flex-row flex-wrap justify-center">
        <h2 className="text-2xl text-black text-center">
          Import your other visual preferences to enhance your digital style
          twin
        </h2>
        <p className="text-xl text-black">
          <a href="/pinterest" className="brand-text">
            Get your pins
          </a>{" "}
          from Pinterest
        </p>
      </div>
      <div className="spacer-30 mobile-hidden"></div>
      <div className="items-center flex flex-col md:flex-row flex-wrap justify-center">
        <h2 className="text-3xl text-black mt-10">Refine Your Style</h2>
        <div className="spacer-15"></div>
        <div className="three-shopping-links flex flex-col md:flex-row md:justify-between w-full">
          <div className="mb-2 md:mb-0 md:flex-1 mx-2">
            <a href="/shopping?categories=pillowsets">
              <img
                src={require("assets/img/survey-link-home.png")}
                alt="home-decor"
                className="rounded-sm w-full h-auto object-cover"
              />
            </a>
          </div>
          <div className="mb-2 md:mb-0 md:flex-1 mx-2">
            <a href="/shopping?categories=mensloafers">
              <img
                src={require("assets/img/survey-link-footware.png")}
                alt="footware"
                className="rounded-sm w-full h-auto object-cover"
              />
            </a>
          </div>
          <div className="md:flex-1 mx-2">
            <a href="/shopping?categories=womenscasualjackets">
              <img
                src={require("assets/img/survey-link-fashion.png")}
                alt="fashion"
                className="rounded-sm w-full h-auto object-cover"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
