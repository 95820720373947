import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";

export default function PinterestAuth() {
  const { user } = useAuthenticator((comp) => [comp.user]);
  const [loggedIn, setLoggedIn] = useState(false);
  //   const [accessToken, setAccessToken] = useState(
  //     "pina_AMATTQAWACCK2AQAGCADUD3AZK335CQBQBIQC42XPG575G323MOFKE7XYVKZ4ME5WITNMOHFBXPNR3F4PKIQWK3BN6EDLMQA"
  //   );
  const [pins, setPins] = useState([]);

  const handlePinterestLogin = async () => {
    window.location.href = `https://www.pinterest.com/oauth/?client_id=${
      process.env.REACT_APP_PINTEREST_APP_ID
    }&redirect_uri=${
      process.env.REACT_APP_PINTEREST_REDIRECT_URI
    }&response_type=code&scope=boards:read,pins:read&state=${
      user?.getSignInUserSession()?.getIdToken()?.payload.sub
    }`;
  };

  const fetchLoginStatus = async () => {
    const response = await API.get("prntApi", `/requestPinterestToken?userId=${user?.getSignInUserSession()?.getIdToken()?.payload.sub}`);
    console.log("Resp", response.redirectUri)
    if (response.redirectUri) {
      setLoggedIn(true)
    }
    else {
      setLoggedIn(false)
    }
    
  }

  const listPins = async () => {
    const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
    const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();

    const response = await API.post("prntApi", `/importPinterestPins`, {
      headers: { Authorization: `Bearer ${authToken}` },
      body: {
        userId: userId,
      },
    });
    console.log(response)
    setPins(response);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    if (state === user?.getSignInUserSession()?.getIdToken()?.payload.sub) {
      setLoggedIn(true);
    } else {
      fetchLoginStatus()
      console.log("State:", state);
      setLoggedIn(false);
    }
  }, []);

  return (
    <div className="p-8">
      <h2 className="text-black text-4xl font-bold mt-0 mb-4">
        Connect to Pinterest
      </h2>
      <p className="text-black mb-4">
        Seamlessly import your Pinterest pins to empower your aesthetic model
      </p>
      {/* Logign with pinterest button */}
      {loggedIn ? (
        <button
          onClick={listPins}
          className="bg-blueGray-800 text-white active:bg-blueGray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        >
          Import Pins
        </button>
      ) : (
        <button
          onClick={handlePinterestLogin}
          className="bg-blueGray-800 text-white active:bg-blueGray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        >
          Login with Pinterest
        </button>
      )}

      {/* display images */}
      {pins.length > 0 && (
        <div className="grid grid-cols-3 gap-4">
          {pins.map((pin, i) => (
            <div className="relative" key={i}>
              <img src={pin.media.images["400x300"].url} alt={pin.description} className="rounded-lg"  />
              <div className="absolute bottom-0 left-0 p-2 bg-white bg-opacity-50 rounded-lg">
                {pin.description}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
