

export default function LibraryPagination(props) {

    return (
        <div className="pagination">
            <div className="p-2">
            <nav className="block">
                <ul className="flex pl-0 rounded list-none flex-wrap justify-end">
                    <li className="pagination-title">
                        Pages:
                    </li>
                    {Array(Math.ceil(props.likedImages.length / props.imagesPerPage))
                        .fill()
                        .map((_, index) => (
                            <li
                                key={index}
                                onClick={() => props.handlePageChange(index + 1)}
                                className={`pagination-number first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 text-white bg-lightBlue-500`}
                            >
                                {index + 1}
                            </li>
                    ))}
                </ul>
            </nav>
            </div>
        </div>
    )
}