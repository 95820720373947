import React from "react";
import { Link } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from "uuid";
import evokeAILogo from "assets/img/Evoke_white.png";

export default function Footer(props) {
	const { user } = useAuthenticator((context) => [context.user]);
	console.log("called footer here!");
	if (!user && !localStorage.getItem("tempUserId")) {
		console.log("no id found, setting to random");
		localStorage.setItem("tempUserId", uuidv4());
	}

	const SocialMediaLinks = () => (
		<div className="flex space-x-4 my-4 text-xl social-links">
			<a
				href="https://www.facebook.com/prnt.by.evokeai"
				target="_blank"
				rel="noopener noreferrer"
				className="rounded-full border border-white"
			>
				<i className="fab fa-facebook text-white"></i>
			</a>
			<a
				href="https://www.youtube.com/@prnt_by_evokeAI"
				target="_blank"
				rel="noopener noreferrer"
				className="rounded-full border border-white"
			>
				<i className="fab fa-youtube text-white"></i>
			</a>
			<a
				href="https://www.instagram.com/prnt_by_evokeai/"
				target="_blank"
				rel="noopener noreferrer"
				className="rounded-full border border-white"
			>
				<i className="fab fa-instagram text-white"></i>
			</a>
		</div>
	);

	const handleNavClick = (navName) => {
		if (typeof window !== "undefined") {
			window.gtag("event", "navbar_click", {
				event_category: "engagement",
				event_label: navName,
			});
		}
	};

	const showAuthModal = () => {
		console.log("showAuthModal from footer");
		props.showAuthModal();
	};

	return (
		<>
			<footer className="relative pt-8 pb-6">
				<div className="container mx-auto items-center flex flex-wrap justify-center">
					<div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4">
						<div className="container mx-auto p-4">
							<div className="flex gap-4">
								<div className="flex-1">
									<Link
										to="/"
										className="text-white text-xl leading-relaxed inline-block mr-4 py-2 whitespace-nowrap font-aldrich"
										onClick={() => handleNavClick("Home Page Button")}
									>
										<img
											src={evokeAILogo}
											alt="Evoke AI"
											style={{ height: "auto", maxWidth: "100px" }}
										/>
									</Link>
									<div
										className={
											"sm:block flex flex-col mt-10" + user &&
											user?.getSignInUserSession()?.getIdToken()?.getJwtToken()
												? "hidden"
												: ""
										}
									>
										<button
											className="get-started text-white px-5 py-3 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
											onClick={() => {
												showAuthModal();
											}}
										>
											Register
										</button>
										<button
											className="github-star sm:ml-1 text-white px-5 py-3 rounded outline-none focus:outline-none mr-1 mb-1 text-sm shadow hover:shadow-lg"
											onClick={() => {
												showAuthModal();
											}}
										>
											<i className="fa-circle-question text-lg mr-1"></i>
											<span>Login</span>
										</button>
									</div>
									<div className="spacer-30 mobile-hidden"></div>
									<SocialMediaLinks />
								</div>

								<div className="flex flex-col md:flex-row">
									<ul className="footer-list spacing list-disc flex-1">
										<a href="/about">
											<li>About Us</li>
										</a>
										<a href="/faq">
											<li>FAQ</li>
										</a>
										<a href="/blog">
											<li>Blog</li>
										</a>
										{/* <li>News & Blogs</li>
                    <li>Careers</li> */}
									</ul>

									<ul className="footer-list list-disc">
										<a href="/for-retailers">
											<li>For Retailers</li>
										</a>
										<a href="/technology">
											<li>Technology </li>
										</a>
										{user ? (
											<a href="/contact">
												<li>Contact Us</li>
											</a>
										) : (
											<a href="mailto:support@evoke-ai.com">
												<li>Contact Us</li>
											</a>
										)}
										{/* <li>Press</li> */}
									</ul>
								</div>
							</div>
							<hr className="my-6" />
							<div className="flex">
								<div className="flex-1">
									<div className="text-sm font-semibold py-1">
										Copyright © {new Date().getFullYear()}{" "}
										<a
											href="https://www.creative-tim.com?ref=nr-footer"
											className="hover:text-blueGray-800"
										>
											Evoke A.I. Corp
										</a>
										.
									</div>
								</div>
								<div className="flex">
									<a href="/terms" className="" style={{ margin: "0 30px" }}>
										Terms of service
									</a>
									<a href="/privacy" className="">
										Privacy Policy
									</a>
									<a href="/cookies" className="hidden">
										Cookies
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
