import React from "react";

export default function AboutUs() {
  return (
    <>
      <div className="items-center flex flex-wrap justify-center">
        <h2 className="text-5xl text-black mt-0 mb-0 text-center">About</h2>
      </div>

      <div className="technology-blurb-box">
        <div
          className="inner-blurb-box pt-10 text-center"
        >
          <p className="">
            Founded by a team of visionaries passionate about technology and
            design, our journey began with a simple yet profound question: How
            can we transform the retail experience through the power of
            aesthetic intelligence? Using computer vision concepts this question
            led to the creation of our pioneering technology, designed to
            revolutionize the way retailers connect with their customers.
          </p>
          <div className="flex w-full justify-center">
            <iframe width="500" height="275" src="https://www.youtube.com/embed/kz5DH5pCfM4?si=Sy0RiBVDDE_NBBRC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div className="technology-blurb-box text-center">
        <div className="technology-blurb-box-grey"></div>
        <div className="inner-blurb-box py-10">
          <div className="flex w-full justify-center">
          <img
              alt="retail image 1"
              style={{ height: "250px" }}
              src={require("assets/img/aboutPageImage.png")}
            />
          </div>
          <h2 className="text-2xl text-black text-center">
            Our Mission: Empowering Retailers with Aesthetic Intelligence
          </h2>
          <p className="">
            At Evoke AI, we believe in the power of aesthetics to transform the
            shopping experience. Our mission is to empower retailers with
            intelligent technology that not only understands customer
            preferences but also anticipates their evolving tastes. We are
            dedicated to bridging the gap between data-driven insights and the
            art of design, providing solutions that are as intuitive as they are
            innovative.
          </p>
        </div>
      </div>

      <div className="technology-blurb-box">
        <div className="inner-blurb-box py-10">
          <div className="spacer-30"></div>
          <div className="spacer-30"></div>
          <div className="text-black text-3xl mb-5 text-center">Our Values</div>
          <div className="spacer-30"></div>
          <div className="spacer-30"></div>
          <div className="flex flex-col md:flex-row w-full">
            <div className="w-full md:w-4/12 lg:w-4/12 xl:w-4/12 px-4 text-center">
              <h2 className="text-xl font-bold mb-2 text-black">Innovation</h2>
              <p>
                We are committed to continuous advancement, pushing the
                boundaries of what's possible in retail technology.
              </p>
            </div>

            <div className="w-full md:w-4/12 lg:w-4/12 xl:w-4/12 px-4 text-center">
              <h2 className="text-xl font-bold mb-2 text-black">Intuition</h2>
              <p>
                We value the art of design and aesthetics, integrating them
                seamlessly with our solutions.
              </p>
            </div>

            <div className="w-full md:w-4/12 lg:w-4/12 xl:w-4/12 px-4 text-center">
              <h2 className="text-xl font-bold mb-2 text-black">Integrity</h2>
              <p>
                We believe in building lasting relationships with our clients,
                grounded in trust and mutual respect.
              </p>
            </div>
          </div>

        </div>
      </div>

      <div className="technology-blurb-box text-center">
        <div className="technology-blurb-box-grey"></div>
        <div className="inner-blurb-box py-10">
          <div className="flex w-full justify-center">
            <img
              alt="retail image 2"
              className="text-black m-2"
              style={{ height: "250px" }}
              src={require("assets/img/aboutPage1.png")}
            />
          </div>
          <h2 className="text-2xl text-black text-center">
            Our Commitment: Shaping the Future of Retail
          </h2>
          <p className="">
            We are committed to create solutions that enhance customer
            experiences and drive business growth. We are excited to partner
            with forward-thinking retailers who share our vision of a more
            personalized and aesthetically rich shopping world.
          </p>
        </div>
      </div>
    </>
  );
}
