import { API } from "aws-amplify";

const generateModel = (() => {
  let allowCall = true; // Flag to control the call

  // This is the inner function that performs the actual logic
  return async (user) => {
    if (!allowCall) {
      console.log('Skipping generate model call due to throttling');
      return; // Early exit if a call is not allowed
    }

    // Disallow further calls
    allowCall = false;
    // Allow a new call after 0.5 seconds
    setTimeout(() => {
      allowCall = true;
    }, 500);

    try {
      const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

      const response = await API.post("prntApi", "/generate", {
        headers: { Authorization: `Bearer ${authToken}` },
        body: { userId: userId },
      });

      console.log("Generate model response:", response);
    } catch (error) {
      console.error("Error generating model:", error);
    }
  };
})();

export default generateModel;