import React, { useEffect, useState, useRef } from 'react';
import { Auth, API } from 'aws-amplify';

const ModelInferenceManager = ({ category, onCompleted }) => {
  const [isLoading, setIsLoading] = useState(true);
  const allowInferenceCall = useRef(true);

  useEffect(() => {
    const performModelCheckAndInference = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
        const authToken = user.signInUserSession.idToken.jwtToken;

        // Step 1: Check if the model exists
        const modelCheckResponse = await API.post("prntApi", "/checkModelCreation", {
          headers: { Authorization: `Bearer ${authToken}` },
          body: { userId: userId },
        });

        if (modelCheckResponse.message === 'Model found') {
          // Model exists, proceed to initiate inference
          await initiateInference(userId, authToken, category);
        } else {
          // Handle the case where the model does not exist or other errors
          console.error("Model does not exist or error checking model:", modelCheckResponse);
          setIsLoading(false);
          onCompleted(false); // Indicate completion with a failure or absence of model
        }
      } catch (error) {
        console.error("Error during model check and inference initiation:", error);
        setIsLoading(false);
        onCompleted(false); // Indicate completion with an error
      }
    };

    performModelCheckAndInference();
  }, [category, onCompleted]); // Dependencies

  const initiateInference = async (userId, authToken, category) => {

    if (!allowInferenceCall.current) {
      // Throttling in effect, skipping this call
      console.log('Skipping inference call due to throttling');
      return;
    }

    // Allow the first call
    allowInferenceCall.current = false;

    // Reset the flag after 0.5 seconds, allowing another call
    setTimeout(() => {
      allowInferenceCall.current = true;
    }, 500);

    try {
      // Step 2: Call /inference API with the category
      const inferenceResponse = await API.post("prntApi", "/inference", {
        headers: { Authorization: `Bearer ${authToken}` },
        body: { userId: userId, category: category },
      });

        pollForInferredImages(userId, authToken, category);
      } catch (error) {
        // If an error occurs during the API call, log it and indicate failure.
        console.error("Error initiating inference:", error);
        setIsLoading(false);
        onCompleted(false);
      }
    };

    const pollForInferredImages = async (userId, authToken, category) => {
      if (!category) {
        console.error("Category to check is undefined.");
        return;
      }
    
      console.log(`Starting to poll for images in category: ${category}`);
    
      const intervalId = setInterval(async () => {
        try {
          
          
          const inferredImagesResponse = await API.get("prntApi", `/getInferencedImages?userId=${userId}`, {
            headers: { Authorization: `Bearer ${authToken}` },
          });
    
          console.log(`Received response: `, inferredImagesResponse);
    
          if (inferredImagesResponse && inferredImagesResponse.topLikedRecords) {
            const categoryImageFound = inferredImagesResponse.topLikedRecords.some(record => record.category === category && record.refresh === false);
            
            if (categoryImageFound) {
              clearInterval(intervalId);
              // Extract records for the category with refresh = false
              const filteredRecords = inferredImagesResponse.topLikedRecords.filter(record => record.category === category && record.refresh === false);
              
              setIsLoading(false); // Stop loading once we have the desired records
              onCompleted(true, filteredRecords); // Pass the filtered records to the callback
            }
            // Keep polling if categoryImageFound condition is not met; do not set isLoading to false here
          }
        } catch (error) {
          console.error("Error polling for inferred images:", error);
          // Continue polling in case of error, so no action is taken here
        }
      }, 2000); // Poll every 2 seconds
    };

  return null; // This component does not render anything
};

export default ModelInferenceManager;