import React from "react";

// components

export default function SlideCarouselCard(props) {

  return (
    <>
      <div className="slide-carousel-card">
        <div className="image-container-vertical">
          <img src={props.img} alt="Card Image" className="card-image" />
        </div>
        <div className="content">
          <h3 className="card-title text-xl font-bold">{props.title}</h3>
          <h3 className="card-subtitle text-l">{props.subtitle}</h3>
          <div className="card-blurb font-bold">{props.blurb}</div>
        </div>
        <div className="container mx-auto items-center flex flex-wrap justify-center">
            <a
              href={props.href}
              target="_blank"
              className="get-started text-white px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-4 bg-lightBlue-500 active:bg-lightBlue-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 text-center break-words"
            >
              Shop {props.category}
            </a>
        </div>
      </div>
    </>
  );
}
