import React, { useState, useCallback, useEffect } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import CreateArtCardVertical from "components/Cards/CustomCards/CreateArtCardVertical";
import ClipLoader from "react-spinners/ClipLoader";
import ProductButtons from "components/Rating/product_rating_buttons";
import shoppingData from "assets/data/shopping.json";
import ShareButtonsPopout from "components/ShareButtonsPopout/ShareButtonsPopout";

export default function WishListOnly(props) {
  const { user, signOut } = useAuthenticator((conp) => [conp.user]);
  const [likedImages, setLikedImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [wishListData, setWishListData] = useState(null);

  const getEcommerceLikedImages = async () => {
    setLoading(true);
    try {
      const authToken = user
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

      // Fetching liked images
      const likedImagesResponse = await API.get(
        "prntApi",
        `/getInferencedImages?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const likedImagesResponseFiltered = likedImagesResponse.topLikedRecords || [];
      
      // Fetching wishlist images and modifying their structure
      const wishlistImagesResponse = await API.get(
        "prntApi",
        `/getWishlistedProducts?userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      ).then((response) =>
        response.map((item) => ({
          ...item,
          category: "Wishlist", // Set static category for all wishlist items
          confidence: 100,
        }))
      );
      setWishListData(wishlistImagesResponse)
      // Grouping images by category
      const groupedImages = likedImagesResponseFiltered.reduce((acc, image) => {
        (acc[image.category] = acc[image.category] || []).push(image);
        return acc;
      }, {});

      // Adding modified wishlist images under the 'Wishlist' category
      groupedImages["Wishlist"] = wishlistImagesResponse;

      if (Object.keys(groupedImages).length === 0) {
        setLikedImages(null);
      } else {
        setLikedImages(groupedImages);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageClick = (event, image) => {
    if (!user) {
      event.preventDefault();
      return;
    }
    window.gtag("event", "ecomm_image_click", {
      event_category: "engagement",
      event_label: image.filename,
      value: image.url,
    });
  };

  useEffect(() => {
    if (user) {
      getEcommerceLikedImages();
    } else {
      const groupedImages = shoppingData.reduce((acc, image) => {
        (acc[image.category] = acc[image.category] || []).push(image);
        return acc;
      }, {});
      if (Object.keys(groupedImages).length === 0) {
        setLikedImages(null);
      } else {
        setLikedImages(groupedImages);
      }
    }
  }, [user]);
  

  return (
    <section className="wishList">
      <div className="container mx-auto items-center flex flex-wrap justify-center">
        <div className="w-full md:w-12/12 lg:w-12/12 xl:w-12/12 px-4">
          <div className="wishList-view w-9/12 flex flex-wrap">
            {loading ? (
              <ClipLoader color="#000000" className="loading-centered" size={50} />
            ) : (
              <div className="mx-auto min-height-600 relative min-w-0 break-words bg-white w-full mb-6 mt-5 text-left">
                <h3 className="text-black text-3xl text-center">
                  My Wishlist
                </h3>
                {wishListData && wishListData.length ? wishListData.map((image, imageIndex) => (
                  <span
                    key={`image-${imageIndex}`}
                    onClick={(e) => handleImageClick(e, image)}
                  >
                  <CreateArtCardVertical
                    img={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
                    href={user ? image.url : "#"}
                    blurb={
                      <div className="wishList-actions">
                        <div className="flex flex-1 flex-item m-2">{`${Math.round(image.confidence)}% Match`}</div>
                          <ShareButtonsPopout
                            user={user}
                            url={image.url}
                            imageUrl={`https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/${image.filename}.jpg`}
                          />
                      </div>
                    }
                  />
                </span>
                )) : (
                  <p>No Items Wishlisted yet. Visit the shopping page to add items here</p>
                )}
              </div>
            )}
            </div>
        </div>
      </div>
    </section>
  );
}
   




              