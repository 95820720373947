export const getBadgeInfo = (percentComplete) => {
    if(percentComplete === undefined) return {};
    if (percentComplete >= 50 && percentComplete < 100) {
        console.log('showing modal 2')
        return { 
            name: "Aesthetic trailblazer", 
            image: require("assets/img/badge-explorer.png"), 
            message: `<strong>Congratulations!</strong>
            <p>
            You unlocked your digital-style twin! We are creating it now. (15-30s)
            </p>
            <p>
            “Connect Pinterest” to import your pins from Pinterest and enhance your twin.  
            </p>
            <p>
            “Shop” for products that match your style
            </p>
            <p>
            “Portfolio” to discover your personality and style traits
            </p>
            <p>
            “Keep rating” to achieve final badge - unlock art creation
            </p>`
        };
    } else if (percentComplete >= 100 || percentComplete === 0) {
        console.log('showing modal 3')
        return { 
            name: "Artistic Visionary", 
            image: require("assets/img/badge-enthusiast.png"), 
            message: `<p class="text-xl">Bravo! Your creative vision is taking flight. <br /><br /></p>
            <p>You have unlocked new tools to express your unique artistry.
                <ul>
                    <li>“Shop” for curated products</li>
                    <li>“Create art” for your personalized art</li>
                    <li>“Connect Pinterest” to import your pins</li>
            </p>` 
        };
    } 
    return { 
        name: "Beginner", 
        image: "", 
        message: `Great start! Keep going, the magic is coming soon.`
    };
};