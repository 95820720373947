import React, { useState } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function PrivacyPage() {
  const [showAuthModal, setShowAuthModal] = useState(false);

  const showAuthModalFromFooter = () => {
    setShowAuthModal(true);
  }

  return (
    <>
    <IndexNavbar fixed showAuthModalFromFooter={showAuthModal} />
    <section className="terms-page relative items-center flex text-center">
        <div className="container mx-auto items-center flex flex-wrap justify-center">
          <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4">
            <h2 className="text-5xl text-black m-4">
              Evoke AI Privacy Policy
            </h2>
      
            <div className="mx-auto relative flex flex-col min-w-0 break-words  w-full mb-6 mt-10 text-left">
              <h2 className="text-xl font-bold text-center">Cookie Policy</h2>

              <p>
              This website will store certain information related to your preferences on your personal computer using a small file known as a cookie. A cookie is a tiny data piece that a website requests your browser to store on your computer or mobile device. It enables the website to remember your actions and preferences over time.
              </p>
              <p>
              You have the option to delete existing cookies on your computer and configure most browsers to prevent new ones from being stored. However, by doing so, you may need to manually adjust certain preferences each time you visit a website, and some services and features may not function properly.
              </p>
              <p>
              Although most browsers support cookies, you can choose to decline them and delete them whenever desired. Instructions on how to do this on different browsers can be found here.
              </p>
              <p>
              This website uses cookies for the following purposes:
              Identifying you as a returning user and tracking your visits for traffic statistics analysis.
              Remembering your customized display preferences, such as the collapsing or expanding of comments.
              Providing additional usability features, including tracking your consent to the use of cookies.
              </p>
              <p>
              Enabling cookies is not strictly necessary for the website to operate, but it enhances your browsing experience. The cookie-related information is not used to personally identify you and is solely utilized for the purposes described here.
              </p>
              <p>
              After visiting this website, other types of cookies may also be created. This site employs Google Analytics, a widely-used web analytics service that employs cookies to analyze user behavior on the site. The information generated by these cookies, including your IP address, is transmitted to and stored by Google on servers in the United States. Google uses this information to evaluate your usage of other websites, compile reports on website activity, and provide related services. Google may share this information with third parties when legally required or when those parties process the information on Google's behalf. Google guarantees that your IP address will not be associated with any other data it holds.
              </p>
              
              <h2 className="text-xl font-bold text-center">Sharing Information</h2>
              <p>
              This site does not sell, rent, or disclose the collected information to external parties, except in the following circumstances:
              </p>
              <p>
              Affiliated Service Providers: This site has agreements with various affiliated service providers to facilitate its operation. For example, the site may share your credit card information with the credit card service provider to process your purchase. All administrative service providers used by this site are required to maintain the same level of privacy protection. Thus, your information will be handled with equal care. Additionally, this site may utilize analytic or marketing services such as Google Analytics, Google Adsense, Taboola, or RevContent, for which you provide unconditional consent.
              </p>
              <p>
              Legal Obligations: This site may share collected information if required by law, particularly in response to government authorities' lawful demands.
              Statistical Analysis: This site may share non-personal and aggregated information with third parties for advertising or marketing purposes. This sharing does not include any personal information.
              </p>
              <p>
              Transactions: In situations such as mergers, company asset sales, financing or acquisition, or any other circumstance involving the disclosure or transfer of personal information as a business asset, this site may share information.
              </p>

              <h2 className="text-xl font-bold text-center">Contact</h2>
              <p>If you have any questions regarding this policy, please contact us at <a href="mailto:support@evoke-ai.com">support@evoke-ai.com</a>.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer showAuthModal={showAuthModalFromFooter} />
    </>
  );
}
