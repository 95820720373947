import { useState, useEffect } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import { getBadgeInfo } from "../../Utils/getBadgeInfo";
import TinderCard from "react-tinder-card";
import ClipLoader from "react-spinners/ClipLoader";
import { fetchVoteData } from "../../Utils/fetchVoteData.js";
import { MigrateVotes } from "../../Utils/migrateVotes.js";
import { formFields } from "Utils/formFields";
import AuthModal from "components/Auth/Auth";
import checkModelGenerated from "../../Utils/checkModel";
import generateModel from "../../Utils/generateModel";
import finetuneModel from "../../Utils/finetuneModel";
import initiateInference from "Utils/initiateInference";
import { v4 as uuidv4 } from "uuid";

export default function ImageRatingWidget(props) {
	const { user } = useAuthenticator((conp) => [conp.user]);
	const [imagesToRate, setImagesToRate] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [color, setColor] = useState(null);
	const [voteData, setVoteData] = useState({ total: 0, likes: 0, dislikes: 0 });
	const [rawPercentComplete, setRawPercentComplete] = useState(0);
	const [userMustMigrate, setUserMustMigrate] = useState(false);
	const [showAuthModal, setShowAuthModal] = useState(false);
	const [percentComplete, setPercentComplete] = useState(0);
	const [cycleCount, setcycleCount] = useState(0);
	const [currentImageSet, setCurrentImageSet] = useState(0);
	// const [modalDismissed, setModalDismissed] = useState(false);
	const [lastDisplayedThreshold, setLastDisplayedThreshold] = useState(0);
	const [postFirstCycleThreshold, setPostFirstCycleThreshold] = useState(0);
	const [modelGenerationStatus, setModelGenerationStatus] = useState(null);
	const [alertShownOnce, setAlertShownOnce] = useState(false);
	const currentPath = window.location.pathname;
	//   if(currentPath === '/shopify-survey') { do shopify stuff }

	useEffect(() => {
		const fetchData = async () => {
			const data = await fetchVoteData(user);
			if (data) {
				setVoteData({
					total: data.total,
					likes: data.likes,
					dislikes: data.dislikes,
				});
			}
		};
		// if (!user) return;

		fetchData();
	}, [user, userMustMigrate]);

	useEffect(() => {
		// Define the thresholds
		const votesForHalfCompletion = 60;
		const likesForHalfCompletion = 25;
		const dislikesForHalfCompletion = 25;
		const totalVotesForFullCompletion = 100;
		const likesForFullCompletion = 30;
		const dislikesForFullCompletion = 30;

		let percentComplete = 0;
		let cycleCount = 0;
		console.log(voteData);
		// Calculate progress towards half completion (50%)
		if (
			voteData.total >= votesForHalfCompletion &&
			voteData.likes >= likesForHalfCompletion &&
			voteData.dislikes >= dislikesForHalfCompletion
		) {
			// User has reached at least 50% completion
			percentComplete = 50;

			// Calculate progress towards 100% completion
			if (
				voteData.total >= totalVotesForFullCompletion &&
				voteData.likes >= likesForFullCompletion &&
				voteData.dislikes >= dislikesForFullCompletion
			) {
				// User has reached 100% completion
				percentComplete = 100;

				cycleCount +=
					(voteData.total - votesForHalfCompletion) / (totalVotesForFullCompletion - votesForHalfCompletion);
				percentComplete +=
					((voteData.total - votesForHalfCompletion) %
						(totalVotesForFullCompletion - votesForHalfCompletion)) *
					(100 / (totalVotesForFullCompletion - votesForHalfCompletion));
			} else {
				// User is between 50% and 100% completion
				const extraVotesNeeded = Math.max(totalVotesForFullCompletion - voteData.total, 0);
				const extraLikesNeeded = Math.max(likesForFullCompletion - voteData.likes, 0);
				const extraDislikesNeeded = Math.max(dislikesForFullCompletion - voteData.dislikes, 0);

				// Calculate how many more votes, likes, and dislikes are needed to reach 100%
				const maxExtraNeeded = Math.max(extraVotesNeeded, extraLikesNeeded, extraDislikesNeeded);
				// Assuming that the distance from 50% to 100% is equally divided by the number of extra votes, likes, and dislikes needed
				const progressPast50Percent =
					maxExtraNeeded === 0
						? 50
						: 50 * (1 - maxExtraNeeded / (totalVotesForFullCompletion - votesForHalfCompletion));
				percentComplete += progressPast50Percent;
			}
		} else {
			// User is below 50% completion
			const votePercentage = Math.min(1, voteData.total / votesForHalfCompletion);
			const likePercentage = Math.min(1, voteData.likes / likesForHalfCompletion);
			const dislikePercentage = Math.min(1, voteData.dislikes / dislikesForHalfCompletion);
			percentComplete = 50 * Math.min(votePercentage, likePercentage, dislikePercentage);
		}
		cycleCount = Math.floor(percentComplete / 100); // Ensure cycleCount is an integer representing the completion cycles
		percentComplete = percentComplete % 100; // Reset percentComplete to max 100% for current cycle
		setRawPercentComplete(percentComplete / 100);
		console.log("setting percent " + percentComplete);
		setPercentComplete(percentComplete);
		setcycleCount(cycleCount);
		props.getRawPercentComplete(percentComplete / 100);

		// TODO: CHANGE VOTE COUNT TO 50 after testing
		if (percentComplete >= 50 && !user) {
			setShowAuthModal(true);
			setUserMustMigrate(true);
		}
		if (percentComplete >= 50 && !user && !alertShownOnce) {
			setAlertShownOnce(true);
			alert(
				"Voting complete. Want to see the recommendations from your style twin? Please register for a free account."
			);
		}

		// fetch vote data after migration complete
		const fetchData = async () => {
			const data = await fetchVoteData(user);
			if (data) {
				setVoteData({
					total: data.total,
					likes: data.likes,
					dislikes: data.dislikes,
				});
			}
		};

		if (user && localStorage.getItem("tempUserId")) {
			MigrateVotes(user).then((migrateResponse) => {
				console.log("migrateResponse:");
				console.log(migrateResponse);
				if (migrateResponse) {
					fetchData();
					setUserMustMigrate(false);
				}
			});
		}
	}, [voteData, user]);

	const dismissModal = (modalNumber) => {
		// Only set the dismissal state if the modal is actually being shown
		if (showModal && user) {
			localStorage.setItem(
				"dismissedModal" + user?.getSignInUserSession()?.getIdToken()?.payload.sub,
				modalNumber
			);
			setShowModal(false); // Hide the modal after dismissing
		}
	};

	useEffect(() => {
		if (!user) {
			return;
		}

		console.log("percentComplete:");
		console.log(percentComplete);

		// Initialize or check if the model generation has been previously triggered
		const modelGenKey = `modelGenerationTriggered_${user?.getSignInUserSession()?.getIdToken()?.payload.sub}`;
		const modelGenerationPreviouslyTriggered = localStorage.getItem(modelGenKey) === "true";
		const modelGenKey100 = `modelGenerationTriggered100_${user?.getSignInUserSession()?.getIdToken()?.payload.sub}`;
		const modelGenerationPreviouslyTriggered100 = localStorage.getItem(modelGenKey100) === "true";

		// Retrieve the dismissed modal state from local storage
		const dismissedModal = localStorage.getItem(
			"dismissedModal" + user?.getSignInUserSession()?.getIdToken()?.payload.sub
		);

		// Determine if thresholds have been crossed
		const hasJustCrossed50 = lastDisplayedThreshold < 50 && percentComplete >= 50;
		const hasJustCrossed100 = lastDisplayedThreshold < 100 && percentComplete == 0;
		console.log("modelGenerationPreviouslyTriggered100");
		console.log(modelGenerationPreviouslyTriggered100);
		if (
			hasJustCrossed100 &&
			cycleCount === 1 &&
			!modelGenerationPreviouslyTriggered100 &&
			currentPath === "/shopify-survey"
		) {
			console.log("load results page with model");
			checkModelGenerated(user)
				.then((isGenerated) => {
					if (isGenerated) {
						console.log("model is generated. Show modal data");
						// go to modal display page
						setModelGenerationStatus("Fine-tuning your style twin."); // Set initial message
						generateModel(user) // Call your generateModel function
							.then(() => {
								// Instead of polling, wait for approximately 1 minute
								props.handleScreenChange();
								// setTimeout(() => {
								// 	setModelGenerationStatus("Fine-tuning complete!");
								// }, 60000); // 60000 milliseconds = 1 minute
							})
							.catch(console.error);
						localStorage.setItem(modelGenKey, "false");
						localStorage.setItem(modelGenKey100, "true");
					}
				})
				.catch(console.error);
		}
		if (currentPath === "/shopify-survey") return;

		if (hasJustCrossed50 && dismissedModal !== "1" && !modelGenerationPreviouslyTriggered && cycleCount === 0) {
			console.log("Triggering 50% modal...");
			setShowModal(true);
			window.gtag &&
				window.gtag("event", "badge1_model", {
					event_category: "progress",
					event_label: "50_percent",
					value: 50,
				});
			dismissModal(1);
			setLastDisplayedThreshold(50); // Update to reflect that the 50% modal was shown

			//check if the model is already generated
			checkModelGenerated(user)
				.then((isGenerated) => {
					if (!isGenerated) {
						setModelGenerationStatus("Creating Digital Style Twin ~1min"); // Set initial message
						generateModel(user) // Call your generateModel function
							.then(() => {
								// After model generation, start polling for the status
								const checkStatusInterval = setInterval(async () => {
									const result = await checkModelGenerated(user);
									if (result && result.message.includes("Model found")) {
										setModelGenerationStatus("Digital Style Twin Completed!");
										clearInterval(checkStatusInterval);
										const user = await Auth.currentAuthenticatedUser();
										const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
										const authToken = user.signInUserSession.idToken.jwtToken;
										initiateInference(userId, authToken, "Painting");
									}
								}, 2000);
							})
							.catch(console.error);

						localStorage.setItem(modelGenKey, "true"); // Mark model generation as triggered
					}
				})
				.catch(console.error);
		} else if (
			hasJustCrossed100 &&
			dismissedModal !== "2" &&
			cycleCount === 1 &&
			!modelGenerationPreviouslyTriggered100
		) {
			console.log("Triggering 100% modal...");
			setShowModal(true);
			window.gtag &&
				window.gtag("event", "badge2_art", {
					event_category: "progress",
					event_label: "100_percent",
					value: 100,
				});
			dismissModal(2);
			setLastDisplayedThreshold(100); // Update to reflect that the 100% modal was shown

			//check if the model is already generated
			checkModelGenerated(user)
				.then((isGenerated) => {
					if (isGenerated) {
						setModelGenerationStatus("Fine-tuning your style twin."); // Set initial message
						generateModel(user) // Call your generateModel function
							.then(() => {
								// Instead of polling, wait for approximately 1 minute
								setTimeout(() => {
									setModelGenerationStatus("Fine-tuning complete!");
								}, 60000); // 60000 milliseconds = 1 minute
							})
							.catch(console.error);
						localStorage.setItem(modelGenKey, "false");
						localStorage.setItem(modelGenKey100, "true");
					}
				})
				.catch(console.error);
		} else if (
			hasJustCrossed50 &&
			cycleCount >= 1 &&
			!modelGenerationPreviouslyTriggered // For triggering at 50% in every cycle beyond the first
		) {
			setLastDisplayedThreshold(50);
			checkModelGenerated(user)
				.then((isGenerated) => {
					if (isGenerated) {
						setModelGenerationStatus("Fine-tuning your style twin."); // Set initial message
						generateModel(user) // Call to generate the model
							.then(() => {
								// Instead of direct polling, waiting for approximately 1 minute
								setTimeout(() => {
									setModelGenerationStatus("Fine-tuning complete!");
								}, 60000); // 60000 milliseconds equates to 1 minute
							})
							.catch(console.error);
						localStorage.setItem(modelGenKey, "true");
						localStorage.setItem(modelGenKey100, "false");
					}
				})
				.catch(console.error);
		} else if (
			cycleCount > 1 &&
			hasJustCrossed100 // For triggering at 100% in every cycle beyond the first
		) {
			setLastDisplayedThreshold(100);
			checkModelGenerated(user)
				.then((isGenerated) => {
					if (isGenerated) {
						setModelGenerationStatus("Fine-tuning your style twin."); // Set initial message
						generateModel(user) // Call to generate the model
							.then(() => {
								// Instead of direct polling, waiting for approximately 1 minute
								setTimeout(() => {
									setModelGenerationStatus("Fine-tuning complete!");
								}, 60000); // 60000 milliseconds equates to 1 minute
							})
							.catch(console.error);
						localStorage.setItem(modelGenKey, "false");
						localStorage.setItem(modelGenKey100, "true");
					}
				})
				.catch(console.error);
		}

		// If the modal has been shown, make sure it's not shown again
		if (!hasJustCrossed50 && lastDisplayedThreshold === 50 && percentComplete < 100) {
			setLastDisplayedThreshold(50);
			console.log(lastDisplayedThreshold);
		}
		if (!hasJustCrossed100 && lastDisplayedThreshold === 100) {
			setLastDisplayedThreshold(100);
			console.log(lastDisplayedThreshold);
		}
	}, [percentComplete, user, lastDisplayedThreshold]);

	const getImage = async () => {
		console.log("get image running");
		try {
			const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
			const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
			let randomUUID;
			let tempUserId;
			let response;
			if (!user) {
				if (!localStorage.getItem("tempUserId")) {
					console.log("no id found, setting");
					randomUUID = uuidv4();
					localStorage.setItem("tempUserId", randomUUID);
					response = await API.get("prntApi", `/getImages?userId=${randomUUID}`, {
						headers: { Authorization: `8GGJ1pR4Hc40kx8YB0UBBasNBvpwGTnI8iyEuwkM` }, // allows getimage without login
					});
					return;
				}
				tempUserId = localStorage.getItem("tempUserId");
				console.log("id being used: " + localStorage.getItem("tempUserId"));
				response = await API.get("prntApi", `/getImages?userId=${tempUserId}`, {
					headers: { Authorization: `8GGJ1pR4Hc40kx8YB0UBBasNBvpwGTnI8iyEuwkM` }, // allows getimage without login
				});
			} else {
				response = await API.get("prntApi", `/getImages?userId=${userId}`, {
					headers: { Authorization: `Bearer ${authToken}` },
				});
			}

			const responseAsArray = Object.values(response);
			// setNumberOfImagesToRate(responseAsArray.length);
			setImagesToRate(responseAsArray);
		} catch (error) {
			console.error(error);
		}
	};

	const voteImage = async (imageObj, vote) => {
		let payload;
		let tempUserId = localStorage.getItem("tempUserId");
		if (vote >= 0) {
			const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
			const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
			console.log(imageObj, vote, userId, authToken);
			console.log(authToken);
			try {
				if (!user) {
					if (userMustMigrate) {
						alert(
							"Voting complete. Want to see the recommendations from your style twin? Please register for a free account."
						);
						return;
					}
					console.log("user not found using temp id " + tempUserId);
					payload = {
						headers: {
							Authorization: `8GGJ1pR4Hc40kx8YB0UBBasNBvpwGTnI8iyEuwkM`,
							"Content-Type": "application/json",
						},
						body: {
							imageId: imageObj.PK,
							userId: tempUserId,
							filename: imageObj.filename,
							vote: vote,
						},
					};
				} else {
					payload = {
						headers: {
							Authorization: `Bearer ${authToken}`,
							"Content-Type": "application/json",
						},
						body: {
							imageId: imageObj.PK,
							userId: userId,
							filename: imageObj.filename,
							vote: vote,
						},
					};
				}

				console.log(payload);

				await API.post("prntApi", "/voteImage", payload);

				props.incrementVotes();

				// After casting the vote, update the voteData state
				if (vote === 0) {
					// Dislike
					setVoteData((prevData) => ({
						...prevData,
						total: prevData.total + 1,
						dislikes: prevData.dislikes + 1,
					}));
				} else if (vote === 1) {
					// Like
					setVoteData((prevData) => ({
						...prevData,
						total: prevData.total + 1,
						likes: prevData.likes + 1,
					}));
				}
			} catch (error) {
				console.error(error);
				// eslint-disable-next-line no-restricted-globals
				location.reload();
			}
		}

		// Remove the image from the array
		const newImagesToRate = imagesToRate.filter((image) => image.PK !== imageObj.PK);
		console.log("newImagesToRate");
		console.log(newImagesToRate);
		if (newImagesToRate.length === 0) {
			setCurrentImageSet(currentImageSet + 1);
		} else {
			console.log("use current image list");
			setImagesToRate(newImagesToRate);
		}
	};

	const swiped = (direction, imagesToRate) => {
		if (direction === "left") {
			console.log("dislike");
			voteImage(imagesToRate, 0);
			// setColor('red');
		} else {
			console.log("like");
			voteImage(imagesToRate, 1);
			// setColor('green');
		}
	};

	//   // Function to check if the model has already been generated for the user
	// const checkModelGenerated = async () => {
	//   try {
	//     const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
	//     const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

	//     // Adjusting for POST request for /checkModelCreation
	//     const response = await API.post("prntApi", "/checkModelCreation", {
	//       headers: { Authorization: `Bearer ${authToken}` },
	//       body: { userId: userId }, // Adjust based on backend expectations
	//     });

	//     console.log("Check model generated response:", response);
	//     return response;
	//   } catch (error) {
	//     console.error("Error checking model generation status:", error);
	//     return false;
	//   }
	// };

	// const generateModel = async () => {
	//   try {
	//     const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
	//     const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

	//     // Since /generate might be a POST request, adjust accordingly if it's not
	//     const response = await API.post("prntApi", "/generate", {
	//       headers: { Authorization: `Bearer ${authToken}` },
	//       body: { userId: userId }, // Adjust based on backend expectations
	//     });

	//     console.log("Generate model response:", response);
	//   } catch (error) {
	//     console.error("Error generating model:", error);
	//   }
	// };

	useEffect(() => {
		// if (!user) {
		//   return;
		// }

		const getImageData = async () => {
			const images = getImage(user?.getSignInUserSession()?.getIdToken()?.getJwtToken());
			setImagesToRate(images);
		};
		getImageData().catch(console.error);
	}, [currentImageSet, user]);

	// TODO: This is perhaps a temporary keyboard shortcut way to rate images... Maybe remove before launch?
	useEffect(() => {
		const handleKeyPress = (event) => {
			// console.log(`Key pressed: ${event.key}`); // `Key pressed: ${event.key}
			switch (event.key) {
				case "ArrowLeft":
					// Handle Dislike action
					console.log("Disliked");
					voteImage(imagesToRate[0], 0);
					break;
				case "ArrowRight":
					// Handle Like action
					console.log("Liked");
					voteImage(imagesToRate[0], 1);
					break;
				default:
					break;
			}
		};

		window.addEventListener("keydown", handleKeyPress);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [imagesToRate]);

	return (
		<>
			{showAuthModal && <AuthModal onClick={() => setShowAuthModal(false)} />}

			<div className={`rate-image-container`}>
				<div
					className={`rating-animation margin-none min-height-500 relative flex flex-col min-w-0 break-words bg-white w-full ${color}`}
				>
					<div className="pt-2">
						{false ? (
							// commenting out for now, using fake uuid
							// <TinderCard
							//   className="swipe"
							//   key="not-logged-in"
							//   preventSwipe={["up", "down"]}
							//   onSwipe={(dir) => {
							//     setShowAuthModal(true);
							//   }}
							// >
							<img alt="..." src={require("assets/img/vertCardImage3.jpg")} className="mx-auto" />
						) : (
							// </TinderCard>
							!imagesToRate[0]?.imageUrl && <ClipLoader color="#000000" size={50} />
						)}
						<div className="currently-rating-image-box">
							{imagesToRate?.length > 0 && (
								// <TinderCard
								//   className="swipe"
								//   key={imagesToRate[0].imageUrl}
								//   preventSwipe={["up", "down"]}
								//   onSwipe={(dir) => swiped(dir, imagesToRate[0])}
								// >
								<img alt="..." src={imagesToRate[0].imageUrl} className="survey-image rounded" />
								// </TinderCard>
							)}

							{imagesToRate && imagesToRate[0] && imagesToRate?.length && (
								<>
									<div className="font-bold mobile-hidden text-sm mt-5">
										Use your arrow keys left (Dislike) or right (Like)
										<br />
										Or click the buttons below
									</div>
									<div className="rating-buttons py-4 mobile-hidden">
										<button
											className="thumbs-down-btn bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-6 py-3 rounded-sm shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
											type="button"
											onClick={() => voteImage(imagesToRate[0], 0)}
										>
											<i className="far fa-thumbs-down rating-icon"></i>
										</button>

										<button
											className="thumbs-up-btn bg-red-500 brand-background text-white active:bg-red-600 font-bold uppercase text-xs px-6 py-3 rounded-sm shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
											type="button"
											onClick={() => voteImage(imagesToRate[0], 1)}
										>
											<i className="far fa-thumbs-up rating-icon up pointer-events-none"></i>
										</button>

										{/* <button
                                          classNameName="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                          type="button"
                                          onClick={() => setShowModal(true)}
                                      >
                                          Open modal (for testing)
                                      </button> */}
									</div>
									{modelGenerationStatus === "Creating Digital Style Twin ~1min" && (
										<div style={{ textAlign: "center", marginTop: "20px" }}>
											<ClipLoader color="#000000" size={50} />
											<p>Creating Digital Style Twin...</p>
										</div>
									)}
									{modelGenerationStatus === "Digital Style Twin Completed!" && (
										<div style={{ textAlign: "center", marginTop: "20px" }}>
											<p>
												Digital style twin complete. <br />
												Go to the{" "}
												<a href="/shopping?categories=painting" className="brand-text">
													Shop
												</a>{" "}
												page to see your recommendations.
											</p>
										</div>
									)}

									{modelGenerationStatus === "Fine-tuning your style twin." && (
										<div style={{ textAlign: "center", marginTop: "20px" }}>
											<ClipLoader color="#000000" size={50} />
											<p>Fine-tuning your style twin. Hold tight.</p>
										</div>
									)}
									{modelGenerationStatus === "Fine-tuning complete!" && (
										<div style={{ textAlign: "center", marginTop: "20px" }}>
											<p>
												Fine-tuning complete. <br />
												Go to the{" "}
												<a href="/shopping?categories=painting" className="brand-text">
													Shop
												</a>{" "}
												page to see your recommendations.
											</p>
										</div>
									)}
								</>
							)}
							{/* {!user && (
              <>
                <div className="rating-buttons py-4 mobile-hidden">
                  <button
                    className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-6 py-3 rounded-sm shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowAuthModal(true)}
                  >
                    <i className="far fa-thumbs-down rating-icon"></i>
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-6 py-3 rounded-sm shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowAuthModal(true)}
                  >
                    <i className="far fa-thumbs-up rating-icon up pointer-events-none"></i>
                  </button>
                </div>
              </>
            )} */}
						</div>
						<div className="rating-buttons py-4 mobile-shown">
							<button
								className="thumbs-down-btn bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-6 py-3 rounded-sm shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
								type="button"
								onClick={() => {
									if (!user) {
										setShowAuthModal(true);
										return;
									} else {
										voteImage(imagesToRate[0], 0);
									}
								}}
							>
								<i className="far fa-thumbs-down rating-icon"></i>
							</button>
							<button
								className="thumbs-up-btn bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-6 py-3 rounded-sm shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
								type="button"
								onClick={() => {
									if (!user) {
										setShowAuthModal(true);
										return;
									} else {
										voteImage(imagesToRate[0], 1);
									}
								}}
							>
								<i className="far fa-thumbs-up rating-icon up pointer-events-none"></i>
							</button>
						</div>
					</div>
					{/* <p className="text-xs mobile-shown">
            *Swipe right to like, left to dislike
          </p> */}
				</div>
			</div>
			{showModal && (
				<>
					<div className="rating-modal-container justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto width-90">
							{/*content*/}
							<div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
									<h3 className="w-full text-3xl text-black text-center">
										Congrats! Milestone Reached
									</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => {
											setShowModal(false);
											dismissModal(1);
										}}
									>
										<span className="close-x bg-transparent text-grey opacity-5 h-6 w-6 text-3xl block outline-none focus:outline-none">
											×
										</span>
									</button>
								</div>
								{/*body*/}
								<div className="relative px-6 flex-auto">
									<div className="my-4 text-blueGray-500 text-lg leading-relaxed text-center">
										<div
											dangerouslySetInnerHTML={{
												__html: getBadgeInfo(percentComplete).message,
											}}
										></div>
										<div className="badge-card">
											<p className="badge-title text-2xl bold">
												{getBadgeInfo(percentComplete).name}
											</p>
											<img
												src={getBadgeInfo(percentComplete).image}
												alt={getBadgeInfo(percentComplete).name}
											/>
										</div>
									</div>
								</div>
								<div
									className="items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b width-90"
									style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
								>
									<button
										className="text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 brand-background"
										type="button"
										onClick={() => {
											setShowModal(false);
											dismissModal(1);
										}}
									>
										Keep Rating
									</button>
									<a href="/pinterest">
										<button
											className="hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 text-black"
											type="button"
											onClick={() => {
												setShowModal(false);
												dismissModal(1);
											}}
										>
											Connect Pinterest <i className="fab fa-pinterest text-red-600"></i>
										</button>
									</a>
									{(percentComplete >= 50 && percentComplete < 100) ||
										(percentComplete === 0 && (
											<>
												<a href="/art/portfolio">
													<button
														className="hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 text-black"
														type="button"
														onClick={() => {
															setShowModal(false);
															dismissModal(1);
														}}
													>
														Portfolio
													</button>
												</a>
												<a href="/shopping?categories=painting">
													<button
														className="hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-500 dark:text-purple-500 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-900 text-black"
														type="button"
														onClick={() => {
															setShowModal(false);
															dismissModal(1); // Adjust if needed for your logic
														}}
													>
														Shop
													</button>
												</a>
											</>
										))}
									{percentComplete >= 100 && (
										<a href="/art/portfolio">
											<button
												className="hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 text-black"
												type="button"
												onClick={() => {
													setShowModal(false);
													dismissModal(2);
												}}
											>
												Create Art
											</button>
										</a>
									)}
									{percentComplete >= 100 && (
										<a href="/shopping?categories=painting">
											<button
												className="hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 text-black"
												type="button"
												onClick={() => {
													setShowModal(false);
													dismissModal(2);
												}}
											>
												Shop
											</button>
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="modal-overlay opacity-50 fixed inset-0 z-40 bg-black"></div>
				</>
			)}
		</>
	);
}
