import { API } from "aws-amplify";

// Using a closure to maintain the state for throttling
const finetuneModel = (() => {
  let allowCall = true; // Tracks whether the function call is allowed

  return async (user) => {
    if (!allowCall) {
      console.log('Skipping finetune call due to throttling');
      return; // Exit the function early if a call is not allowed
    }

    allowCall = false; // Prevent subsequent calls

    // Reset the flag after 0.5 seconds, allowing another call
    setTimeout(() => {
      allowCall = true;
    }, 500);

    try {
      const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

      const response = await API.post("prntApi", "/finetune", {
        headers: { Authorization: `Bearer ${authToken}` },
        body: { userId: userId },
      });

      console.log("Finetune model response:", response);
    } catch (error) {
      console.error("Error finetuning model:", error);
    }
  };
})();

export default finetuneModel;