import React from "react";
import { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { API } from "aws-amplify";
import LibraryPagination  from "../Utils/libraryPagination";
import ClipLoader from "react-spinners/ClipLoader";

import libraryImages from "assets/data/library.json";

export default function Library() {
  const { user, signOut } = useAuthenticator((conp) => [conp.user]);
  const [likedImages, setLikedImages] = useState(null);
  const [loading, setLoading] = useState(false);  // Add loading state

  const getImage = async () => {
    setLoading(true); // Start loading
    console.log('getImage imageRatingWidget')
    try {
      const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      console.log(authToken, "<<< auth token");
      const response = await API.get("prntApi", `/getlikedImages?userId=${userId}`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      console.log(response, "<<< response from get image in home page");
      const responseAsArray = Object.values(response.filenames);
      setLikedImages(responseAsArray);
      setLoading(false); // Stop loading
    } catch (error) {
      console.error(error);
    }
  };

  const getLikedImages = async () => {
    setLoading(true); // Start loading
    console.log('getImage imageRatingWidget')
    try {
      const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;
      console.log(authToken, "<<< auth token");
      const response = await API.get("prntApi", `/getlikedImages?userId=${userId}`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      console.log(response, "<<< response from get image in home page");
      const responseAsArray = Object.values(response.filenames);
      setLikedImages(responseAsArray);
      setLoading(false); // Stop loading
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      getImage(user?.getSignInUserSession()?.getIdToken()?.getJwtToken());
    }
    else {
      setLikedImages(libraryImages);
    }
    
  }, [user])

  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 10;

  // Logic to calculate current images to display
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = likedImages === null ? [] : likedImages.slice(indexOfFirstImage, indexOfLastImage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <h2 className="text-blueGray-700 text-4xl font-bold mt-0">
        Library
      </h2>
      {loading ?
        <ClipLoader color="#000000" size={50} /> :
        <>
          {likedImages && likedImages.length === 0 && <p>You haven't liked any images</p> }
          {likedImages && likedImages.length > 0 && (
            <div className="content-area mx-auto min-height-600 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-5">
              <h2 className="text-xl font-bold text-center">Liked Images</h2>
              <LibraryPagination 
                likedImages={likedImages} 
                imagesPerPage={imagesPerPage}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
              <ul className="library-image-container">
                {currentImages.map((image, index) => (
                  <li key={index}>
                    <a href={image} target="_blank"><img key={index} src={image} /></a>
                  </li>
                ))}
              </ul>
              <LibraryPagination 
                likedImages={likedImages} 
                imagesPerPage={imagesPerPage}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            </div>
          )}
        </>
      }
    </>
  );
}