import { useState, useEffect } from "react";
import { ShopifyQuiz } from "../components/Shopify/ShopifyQuizModal.js";
import { ShopifyLoadingModel } from "../components/Shopify/ShopifyLoadingModel.js";
import checkModelGenerated from "../Utils/checkModel";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ProductCardGrid from "../components/Shopify/ShopifyDisplayCards.jsx";
import { API } from "aws-amplify";
import AuthModal from "../components/Auth/Auth";

export default function ShopifySurvey(props) {
	const [currentScreen, setCurrentScreen] = useState(1);
	const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
	const [showAuthModal, setShowAuthModal] = useState(false);
	const [matchedRecords, setMatchedRecords] = useState([]);
	// const currentPath = window.location.pathname;
	const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
	const userId = user?.getSignInUserSession()?.getIdToken()?.payload.sub;

	const handleScreenChange = () => {
		if (currentScreen === 1 && !user) {
			setShowAuthModal(true);
			setCurrentScreen(currentScreen + 1);
		} else {
			setCurrentScreen(currentScreen + 1);
		}
	};

	const handleSignOut = async () => {
		try {
			signOut();
			setTimeout(() => {
				window.location.href = "/shopify-survey?shop=shopify-surf";
			}, 500);
			// User has been signed out
		} catch (error) {
			console.log("Error signing out:", error);
		}
	};

	const checkForModel = async () => {
		const result = await checkModelGenerated(user);
		console.log("result check for model");
		console.log(result);
		console.log("props");
		console.log(props);
		if (result.message === "Model found") {
			try {
				const likedImagesResponse = await API.get("prntApi", `/getInferencedImages?userId=${userId}`, {
					headers: { Authorization: `Bearer ${authToken}` },
				});

				const modelResultImages = likedImagesResponse.topLikedRecords[0];
				// const category = modelResultImages.category;
				const matchedRecords = modelResultImages.records;
				setMatchedRecords(matchedRecords);
				setCurrentScreen(4);
			} catch (e) {
				console.log("error with getInferencedImages");
				console.log(e);
			}
		}
	};

	useEffect(() => {
		console.log("props!");
		console.log(props);
		if (props?.percentComplete === 100) {
			console.log("Finsihed quiz go to results");
			checkForModel();
		}
		if (currentScreen === 2 && !user) {
			setShowAuthModal(true);
		}
	}, [user, currentScreen, props]);

	return (
		<>
			{currentScreen === 1 ? (
				<div className="shopify-ad bg-white shadow-md p-6 mx-auto text-black">
					<div className="flex flex-col space-y-4">
						<h3 className="text-3xl text-black font-bold">Want Help Shopping?</h3>
						<p>
							Try our <span className="shopify-pink-text">(ALWAYS FREE)</span> AI powered shopping
							assistant
						</p>
						<ul className="list-disc list-inside space-y-2">
							<li>
								Get <span className="shopify-pink-text">personalized</span> product recommendation that
								you are guaranteed to like
							</li>
							<li>
								Get <span className="shopify-pink-text">unique</span> insight into your own personal
								style preferences
							</li>
							<li>
								A <span className="shopify-pink-text">fun</span> and
								<span className="shopify-pink-text"> quick</span> style quiz is all it takes
							</li>
							<li>
								Always <span className="shopify-pink-text">free</span>, no tricks, no hidden fees or
								charges ever courtesy of{" "}
								<a href="https://evoke-ai.com" target="_blank" rel="noreferrer">
									<span className="shopify-purple-text">Evoke-AI.com</span>
								</a>
							</li>
						</ul>
						<button
							className="bg-black text-white text-lg active:bg-indigo-600 text-xs font-bold px-3 py-3 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
							onClick={handleScreenChange}
							type="button"
						>
							Take the Style Quiz
						</button>
					</div>
				</div>
			) : currentScreen === 2 ? (
				<ShopifyQuiz handleScreenChange={handleScreenChange} percentComplete={props?.percentComplete} />
			) : currentScreen === 3 ? (
				<ShopifyLoadingModel nextScreen={handleScreenChange} />
			) : currentScreen === 4 ? (
				<>
					<p className="text-black text-lg text-center">
						Your <span className="shopify-pink-text">Personalized</span> Recommendations
					</p>
					<ProductCardGrid records={matchedRecords} />
				</>
			) : (
				<div>Quiz finished, still on original page?</div>
			)}
			{showAuthModal && <AuthModal onClick={() => setShowAuthModal(false)} />}
			<button onClick={handleSignOut} style={{ visibility: "hidden", cursor: "pointer" }}>
				sign out
			</button>
		</>
	);
}
