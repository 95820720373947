/*eslint-disable*/
import React, { useState } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import StyleFronts from "components/StyleFronts/StyleFronts";
import SlideCarousel from "components/SlideCarousel/SlideCarousel";
import FAQ from "components/FAQ/FAQ";

export default function Index() {

  const [showAuthModal, setShowAuthModal] = useState(false);

  const showAuthModalFromFooter = () => {
    setShowAuthModal(true);
  }

  return (
    <>
      <IndexNavbar fixed showAuthModalFromFooter={showAuthModal} />
      <section className="header relative items-center flex text-center">
        <div className="container mx-auto items-center flex flex-wrap justify-center">
          <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4 py-10">
            <div className="sm:pt-0">
              <div className="spacer-30 mobile-shown"></div>
              <h2 className="text-5xl main-blurb-text">
                Your <span className="font-bold text-blueGray-700">Style</span>,
                Digitally Defined
              </h2>
              <p className="">
              Take our visual quiz to create your digital style twin. <br />
                When finished create a space where every choice feels personally picked just for you. <br />
                Saving you time by bringing products you'll love straight to your screen.<br />
              </p>
              <div className="sm:block flex flex-col mt-10">
                <a
                  href="/art/survey"
                  className="get-started text-white px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Start Quiz <i className="fas fa-arrow-right"></i>
                </a>
                <a
                  href="/about"
                  className="github-star sm:ml-1 text-black px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 uppercase text-sm shadow hover:shadow-lg"
                >
                  <i className="fa-circle-question text-lg mr-1"></i>
                  <span>Learn More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video">
        <div className="container mx-auto items-center flex flex-wrap justify-center">
          <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4">
            <div className="iframe-container">
              <iframe
                className="mobile-shown responsive-iframe"
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/_7mxKyxh1Xk?si=JUmuwdTzY66W7KsR"
                title="YouTube Video Prnt by Evoke-AI"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <iframe
                className="mobile-hidden responsive-iframe"
                src="https://www.youtube.com/embed/_7mxKyxh1Xk?si=JUmuwdTzY66W7KsR"
                title="YouTube Video Prnt by Evoke-AI"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <SlideCarousel />
      <div className="spacer-30 mobile-hidden"></div>

      <section className="style-fronts">
        <div className="container mx-auto items-center flex flex-wrap justify-center">
          <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4">
            <h2 className="font-bold text-blueGray-700 text-5xl uppercase text-center">Style Fronts</h2>
            <h3 className="font-bold text-black text-3xl text-center">Personalized to Perfection</h3>
            <StyleFronts />
          </div>
        </div>
      </section>

      <section className="text-info-section mt-20 md:mt-20 pb-10 relative">
        <div className="container mx-auto items-center flex flex-wrap justify-center">
          <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4">
            <div className="flex flex-wrap">
              <div className="w-10/12 md:w-6/12 lg:w-6/12">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                  <blockquote className="relative p-8 mb-4">
                    <h2 className="text-3xl font-bold text-black">
                      Our Key Features
                    </h2>
                    <p className="text-sm font-light mt-2">
                      EvokeAI, the aesthetic intelligence platform,
                      revolutionizes the way individuals interact with art and
                      images by converting their visual preferences into
                      personalized digital style twins.
                    </p>
                    <div className="spacer-15 mobile-hidden"></div>
                    <a
                      href="/art/survey"
                      className="get-started-blue text-black px-4 py-3 rounded outline-none focus:outline-none mr-1 mb-2 active:bg-lightBlue-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    >
                      Start Quiz <i className="fas fa-arrow-right"></i>
                    </a>
                  </blockquote>
                </div>
              </div>

              <div className="w-full md:w-6/12 px-4">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-6/12 px-4">
                    <div className="relative flex flex-col mt-4">
                      <div className="px-2 py-5 flex-auto rounded-sm shadow mb-5 text-blurb-box">
                        <img
                          src={require("assets/img/AestheticPersonalityIcon.png")}
                          alt="aesthetics-icon"
                          className="header-text-icon"
                        />
                        <h6 className="mb-1 font-semibold text-black">
                          Aesthetic and Personality traits
                        </h6>
                        <p className="text-xs mb-4">
                          We reveal your aesthetic and personality traits for
                          self discovery
                        </p>
                      </div>
                    </div>
                    <div className="relative flex flex-col min-w-0">
                      <div className="px-2 py-5 flex-auto rounded-sm shadow mb-5 text-blurb-box">
                        <img
                          src={require("assets/img/personalizedRecommendationsIcon.png")}
                          alt="personalized-icon"
                          className="header-text-icon"
                        />
                        <h6 className="mb-1 font-semibold text-black">
                          Personalized Recommendations
                        </h6>
                        <p className="text-xs mb-4">
                          Offer personalized product recommendations through its
                          digital style twin technology.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-6/12 px-4">
                    <div className="relative flex flex-col mt-4">
                      <div className="px-2 py-5 flex-auto rounded-sm shadow mb-5 text-blurb-box">
                        <img
                          src={require("assets/img/AIPoweredIcon.png")}
                          alt="AI-powered-icon"
                          className="header-text-icon"
                        />
                        <h6 className="mb-1 font-semibold text-black">
                          AI-Powered Product Curation
                        </h6>
                        <p className="text-xs mb-4">
                          EvokeAI’s AI filters curate products that match each
                          user’s unique aesthetics.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex flex-col min-w-0">
                      <div className="px-2 py-5 flex-auto rounded-sm shadow mb-5 text-blurb-box">
                        <img
                          src={require("assets/img/digitalStyleTwinIcon.png")}
                          alt="Digital-twin-icon"
                          className="header-text-icon"
                        />
                        <h6 className="mb-1 font-semibold text-black">
                          Digital Style Twin Creation
                        </h6>
                        <p className="text-xs mb-4">
                          The platform generates digital style twins based on
                          user-centric aesthetic preferences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="large-ad-frame">
        <div className="container mx-auto items-center flex flex-wrap justify-center">
          <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4 text-center">
            <a href="/art/survey">
              <img
                src={require("assets/img/AdFrame.png")}
                alt="Create your digital style twin"
                className="large-ad-frame"
              />
            </a>
          </div>
        </div>
      </section>

      <section className="large-ad-frame">
        <div className="container mx-auto items-center flex flex-wrap justify-center">
          <div className="w-full md:w-8/12 lg:w-10/12 xl:w-10/12 px-4">
            <h1 className="text-2xl font-bold text-black">
              Frequently Asked Questions
            </h1>
            <FAQ full={false}/>
          </div>
        </div>
      </section>
      <Footer showAuthModal={showAuthModalFromFooter} />
    </>
  );
}
