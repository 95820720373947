import { API } from "aws-amplify";

const sendContactForm = async (user, input) => {
  try {
    const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
    const response = await API.post("prntApi", "/contactForm", {
      headers: { Authorization: `Bearer ${authToken}` },
      body: { name: input.name, email: input.email, company: input.company, message: input.message },
    });

    console.log("Sent contact form:", response);
    return response;
  } catch (error) {
    console.error("Error sending contact form:", error);
    return false;
  }
};

export default sendContactForm;