import React from "react";

export default function StyleFronts(props) {
  const sampleUser1 = [{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/6c67bea8-4a66-41b2-9d6a-990e0fcafdca.jpg', // Pillow sets
    href:'https://www.amazon.com/dp/B07VYPJ63V?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/380f2753-dd8c-479c-ab82-ed0438cd6825.jpg', // Purses
    href:'https://www.amazon.com/dp/B0C2JJQ1HF?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/fbcf16b0-e918-436c-a709-7b03adc0189f.jpg', // painting
    href:'https://www.amazon.com/dp/B07SGGW2VB?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/297b8bd1-df21-47d8-958f-9bf29427bc59.jpg', // Men’s watches
    href:'https://www.amazon.com/dp/B0CJHXJBH9?tag=evokeai-20&linkCode=osi&th=1&psc=1'
}];
const sampleUser2 = [{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/e943d80d-36ab-447f-9633-35abc86f25eb.jpg', // Pillow sets
    href:'https://www.amazon.com/dp/B09P56RNL7?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/c63f1eee-1dbb-443b-a7b7-d6515f1b890a.jpg', // Purses
    href:'https://www.amazon.com/dp/B0CD71M33N?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/171cbd98-d29b-4200-b790-bd039cba5d25.jpg', // painting
    href:'https://www.amazon.com/dp/B07YNFHH4X?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/676808cd-d17e-4074-b85b-44cf3db25e98.jpg', // Men’s watches
    href:'https://www.amazon.com/dp/B0BZHSCVLS?tag=evokeai-20&linkCode=osi&th=1&psc=1'
}];
const sampleUser3 = [{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/46798538-9be4-411c-b1db-ed5f10d7483e.jpg', // Pillow sets
    href:'https://www.amazon.com/dp/B08KXX454Q?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/567f6299-71df-441c-a9ec-41b2823016c8.jpg', // Purses
    href:'https://www.amazon.com/dp/B09PV2ZGXM?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/32f4682c-f57a-4b64-ba23-925c5146ab6f.jpg', // painting
    href:'https://www.amazon.com/dp/B0C7VFPM25?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/40e4943f-22eb-4916-bfbd-5170bf0035ce.jpg', // Men’s watches
    href:'https://www.amazon.com/dp/B09Z7Z7FG9?tag=evokeai-20&linkCode=osi&th=1&psc=1'
}];
const sampleUser4 = [{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/1fc69ecc-a311-454e-af80-786d8d243dd2.jpg', // Pillow sets
    href:'https://www.amazon.com/dp/B0C2PVM7Z5?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/4dcb138a-a24f-4325-a0f5-87c13d45145a.jpg', // Purses
    href:'https://www.amazon.com/dp/B07YW9P43P?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/c5a5b676-f3c8-44e4-aaaf-c535e5e63a8f.jpg', // painting
    href:'https://www.amazon.com/dp/B0CRQK6D5K?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  },{
    img:'https://d1cb3aqlw33ymd.cloudfront.net/Ecomm/prnt-assets-ecomm/c894ecce-a087-470a-8106-06a99417f956.jpg', // Men’s watches
    href:'https://www.amazon.com/dp/B09B2YNGSN?tag=evokeai-20&linkCode=osi&th=1&psc=1'
  }];
  const categoryTitles = ["Pillow sets", "Purses", "Paintings", "Men's Watches"];
  
  const getCategoryStyle = (category) => {
    console.log(category)
    if(category === "Men's Watches" || category === "Purses") return "object-contain";
    if(category === "Pillow sets") return "object-fill";
    return '';
  }
 
  const handleNavClick = (navName) => {
    window.gtag('event', 'navbar_click', {
      'event_category': 'engagement',
      'event_label': navName
    });
  };

  return (
    <>
      <div className="flex justify-between py-4">
        <div className="flex items-center">
          <img src={require("assets/img/user1name.png")} alt="Profile icon" className="style-front-profile-image" />
        </div>

        <div className="flex items-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-sm hidden">
            User style traits <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">
        {sampleUser1.map((profile, index) => (
          <div key={index} className={`w-1/2 ${index % 2 === 0 ? 'pr-5' : 'pl-2.5'}`}>
            <p>{categoryTitles[index]}</p>
            <a href={profile.href} target="_blank">
              <img src={profile.img} alt={`Image ${index + 1}`} className={"w-full h-[50vw] object-cover style-front-image rounded "+ getCategoryStyle(categoryTitles[index])} />
            </a>
          </div>
        ))}
      </div>

      <div className="flex justify-between py-4">
        <div className="flex items-center">
        <img src={require("assets/img/user2name.png")} alt="Profile icon" className="style-front-profile-image" />
          {/* <div>
            <h4 className="text-black">Kia Bloom</h4>
            <h4 className="text-left text-sm">Lighting</h4>
          </div> */}
        </div>
        <div className="flex items-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-sm hidden">
            User style traits <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">
        {sampleUser2.map((profile, index) => (
          <div key={index} className={`w-1/2 ${index % 2 === 0 ? 'pr-5' : 'pl-2.5'}`}>
            <p>{categoryTitles[index]}</p>
            <a href={profile.href} target="_blank">
              <img src={profile.img} alt={`Image ${index + 1}`} className={"w-full h-[50vw] object-cover style-front-image rounded "+ getCategoryStyle(categoryTitles[index])} />
            </a>
          </div>
        ))}
      </div>

      <div className="flex justify-between py-4">
        <div className="flex items-center">
        <img src={require("assets/img/user3name.png")} alt="Profile icon" className="style-front-profile-image" />
        </div>
        <div className="flex items-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-sm hidden">
            User style traits <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">
      {sampleUser3.map((profile, index) => (
          <div key={index} className={`w-1/2 ${index % 2 === 0 ? 'pr-5' : 'pl-2.5'}`}>
            <p>{categoryTitles[index]}</p>
            <a href={profile.href} target="_blank">
              <img src={profile.img} alt={`Image ${index + 1}`} className={"w-full h-[50vw] object-cover style-front-image rounded "+ getCategoryStyle(categoryTitles[index])} />
            </a>
          </div>
        ))}
      </div>

      <div className="flex justify-between py-4">
        <div className="flex items-center">
        <img src={require("assets/img/user4name.png")} alt="Profile icon" className="style-front-profile-image" />
        </div>
        <div className="flex items-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-sm hidden">
            User style traits <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">
        {sampleUser4.map((profile, index) => (
          <div key={index} className={`w-1/2 ${index % 2 === 0 ? 'pr-5' : 'pl-2.5'}`}>
            <p>{categoryTitles[index]}</p>
            <a href={profile.href} target="_blank">
              <img src={profile.img} alt={`Image ${index + 1}`} className={"w-full h-[50vw] object-cover style-front-image rounded "+ getCategoryStyle(categoryTitles[index])} />
            </a>
          </div>
        ))}
      </div>
    </>
  );
}